import { DefaultFilterObject } from "@/defaults"
import { DefaultFilter, Suite, SuitesContext, Msg, Result, RoleType } from "@/types"
import { Fetcher } from "@/utils"
import { v4 } from "uuid"

const api = new Fetcher()

export default {
  init: async (context: SuitesContext) => {
    await context.dispatch('fetchSuites')
  },
  fetchSuites: async (context: SuitesContext, filter: DefaultFilter = DefaultFilterObject): Promise<void> => {
    try {
      context.state.suites.list = []
      context.state.suites.total = 0
      const { data } = await api.post(`api/suites/search`, filter)
      const { suites, total } = data
      context.state.suites.list = suites
      context.state.suites.total = total
    } catch (error) {
      console.error(error);
    }
    // check extension existence
    context.commit('setExtension', undefined, { root: true })
  },
  run: async (context: SuitesContext, { id, cancelToken = undefined }: { id: string, debug: boolean, cancelToken?: any }): Promise<Result[] | undefined> => {
    try {
      context.commit('changeSuiteProgress', { id, live: true })
      const { data } = await api.post(`api/suites/run/${id}`, { id }, null, cancelToken)
      await context.dispatch('fetchSuites')
      return data
    } catch (error) {
      console.error(error);
    }
  },
  fetchCurrentSuite: async (context: SuitesContext, id: string): Promise<void> => {
    try {
      const suite = await api.get(`api/suites/${id}`)
      suite.plans = suite?.plans?.map((p: any) => {
        if (p) {
          return { text: p.name, value: p._id, icon: 'flask' }
        }
      }) || []
      suite.suites = suite?.suites?.map((s: any) => {
        if (s) {
          return { text: s.name, value: s._id, icon: 'package-variant' }
        }
      }) || []
      context.commit("setCurrentSuite", suite)
    } catch (error) {
      console.error(error);
    }
  },
  saveSuite: async (context: SuitesContext, suite: Suite): Promise<any> => {
    try {
      const { _id } = suite
      const { role } = await api.get(`api/users/role`).then(r => r).catch(e => e)
      if (role > RoleType.ADMIN) {
        const msg = { id: v4(), type: 'error', content: '<strong>Permission issues</strong>', dismissible: true, timeout: 3000 }
        context.dispatch('popMsg', msg, { root: true })
        return
      }
      const { data, error } = (await api.post(`api/suites/${_id || ''}`, suite)).data
      await context.dispatch("fetchSuites")

      let msg: Msg
      if (error) {
        msg = { id: v4(), type: 'error', content: error.msg, dismissible: true, timeout: 3000 }
        return { error }
      } else {
        msg = { id: v4(), type: 'success', color: "orange", ts: new Date().getTime(), icon: "package-variant", content: `Suite <strong>"${data.name}"</strong> was saved`, dismissible: true, timeout: 3000, notify: true }
        context.commit("setCurrentSuite");
      }
      context.dispatch('popMsg', msg, { root: true })
      return { data }
    } catch (error) {
      console.error(error);
    }
  },
  deleteById: async (context: SuitesContext, id: string): Promise<any> => {
    try {
      const { data } = await api.delete(`api/suites/${id}`)
      await context.dispatch("fetchSuites")
      const msg: Msg = { id: v4(), type: 'info', color: "orange", ts: new Date().getTime(), icon: "package-variant", content: `Suite was successfully deleted`, dismissible: true, timeout: 3000, notify: true }
      context.dispatch('popMsg', msg, { root: true })
      return data
    } catch (error) {
      console.error(error);
    }
  },
  duplicateById: async (context: SuitesContext, id: string): Promise<any> => {

    try {
      const current = await api.get(`api/suites/${id}`)
      const suite: Suite = { ...current }
      const oldName = suite.name
      delete suite._id
      delete suite.created
      delete suite.updated
      delete suite._id
      delete suite._id
      suite.name += ' - COPY'
      const data = await api.post(`api/suites/`, suite)
      await context.dispatch('fetchSuites')
      const msg: Msg = { id: v4(), type: 'info', content: `Test Suites: <strong>"${oldName}"</strong> was duplicated successfully`, dismissible: true, timeout: 5000 }
      context.dispatch('popMsg', msg, { root: true })
      return data
    } catch (error) {
      console.error(error);

    }
  },
}
