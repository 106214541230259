import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        mainBlue: '#2196F3',
        mainGreen: '#4caf50',
        mainPurple: '#9c27b0',
        mainRed: '#ff5252',
        mainCyan: '#00BCD4',
        mainGrey: '#434348',
        mainLightBlue: '#7cb5ec'
      },
    },
  }
})
