
import Vue from "vue";
import Header from "@/components/header/Header.vue";
import SideBar from "@/components/SideBar.vue";

export default Vue.extend({
  name: "ContentView",
  data() {
    return {};
  },
  components: {
    Header,
    SideBar,
  },
  computed: {
    expand() {
      return this.$store.getters.getDisplayExpand;
    },
  },
  created() {
    this.$store.dispatch("Users/refreshToken");
    this.$store.dispatch("Plans/fetchDeviceList");
    this.$store.dispatch("Plans/fetchCountryList");
  },
});
