import { VuetifyThemeItem } from "vuetify/types/services/theme";
import { ActionContext } from "vuex";

export interface Sort {
  date?: number,
  name?: number,
  role?: number,
}
export interface ResultsStats {
  avgDuration: number,
  duration: number,
  failed: number,
  passed: number,
  planStatus: number,
}
export interface SelectItem {
  text?: string,
  value?: string | number,
  icon?: string,
  color?: string,
  header?: string,
  divider?: boolean,
  dark?: boolean,
  disabled?: boolean
}
export interface TreeItem {
  id?: number | string,
  name: string,
  children?: TreeItem[],
  locked?: boolean
}
export interface ContactItem {
  name: string;
  val: string;
  type: string;
}
export interface TopicItem extends TreeItem {
  video?: string,
  icon?: string,
  content?: { type: string, data: string | string[], title?: string }[],
}

export type TimeFrameType =
  'days' |
  'years' |
  'quarters' |
  'months' |
  'weeks' |
  'days' |
  'hours' |
  'minutes' |
  'seconds' |
  'milliseconds';

// export interface DateOptions extends SelectItem {
//   type: any
// }
export interface KeyValObj {
  [key: string]: string | number
}

export interface Msg {
  id: string,
  type: string,
  content: string,
  unread?: boolean,
  color?: string,
  ts?: number,
  icon?: string,
  dismissible?: boolean,
  timeout?: number,
  notify?: boolean,
}

export interface DefaultFilter {
  limit?: number,
  type?: number,
  sort?: Sort,
  skip?: number,
  search?: any,
  date?: any
}
export interface DefaultTableAction {
  type: string,
  icon: string,
  color: string,
  name?: string,
  tooltip?: string | ((item: DataObj) => string),
  medium?: boolean,
  text?: boolean,
  left?: boolean,
  dark?: boolean,
  disabled?: boolean | ((item: DataObj) => boolean),
  method?: any | ((item: any) => any),
}
export interface Pagination {
  total: number,
  current: number,
  visible: number,
  count: number,
  limit: number
}

// ++++++ Entities
export interface StepAction {
  method: string,
  url?: string,
  selector?: string,
  type?: string,
  text?: string,
  posX?: number,
  posY?: number,
  fullPage?: boolean,
}

export enum InitiatorType {
  USER,
  SCHEDULER,
  CALLBACK,
  API
}

export enum StepType {
  API,
  UI,
  ROUTINE
}

export enum PlanType {
  PLAN,
  ROUTINE,
  TEMPLATE,
}

export enum RoleType {
  ALIEN,
  SUPER_ADMIN,
  ADMIN,
  USER
}
export interface Initiator {
  date: number,
  type: InitiatorType,
  name: string,
  id: string
}

export interface Callback {
  type: 'plan' | 'mail',
  data?: any[],
}
export interface Country {
  code: string,
  lat: string,
  long: string,
  text?: string,
  value: string,
}
export interface ListObj {
  name: string,
  val: string | number,
  type: string,
}
export interface FileObj {
  name: string,
  source: string,
  type?: string,
}
export interface WaitFor {
  method?: string,
  selector?: string,
  time?: string,
  state?: string,
  timeout?: string,
  mandatory?: boolean,
}
export interface Device {
  text?: string,
  value?: string,
  userAgent?: string,
  screen?: {
    width: number,
    height: number
  },
  viewport?: {
    width: number,
    height: number
  },
  deviceScaleFactor?: number,
  isMobile?: boolean,
  hasTouch?: boolean,
  defaultBrowserType?: string
}
export interface Step {
  id: string,
  ref?: string,
  name: string,
  action: StepAction,
  children: Step[],
  description?: string,
  env: ListObj[],
  headers?: KeyValObj,
  type: StepType,
  waitFor?: WaitFor,
  waitAfter?: WaitFor,
  inactive: boolean,
  created?: string,
  loop?: number,
  baseParent?: string,
  resultsId?: string,
}
export interface DataObj {
  _id?: string,
  active?: boolean,
  name: string,
  group?: string,
}
export interface Plan extends DataObj {
  id?: string,
  test?: Step[],
  steps?: Step[],
  type?: PlanType,
  version: string,
  device?: Device,
  permissions?: string[],
  geo?: Country | unknown,
  env?: ListObj[],
  resources?: FileObj[],
  headers?: KeyValObj,
  desc: string,
  contact: ContactItem[],
  status?: number,
  stats?: ResultsStats,
  label?: string,
  locale?: string,
  progress?: number,
  live?: boolean,
  authMethodId?: string,
  isAuthMethod?: boolean,
  skipAuthMethod?: boolean,
  lastDuration?: number,
  lastInitiator?: Initiator,
  avgDuration?: number,
  callback?: Callback[],
  lastRun?: string,
  updated?: string,
  created?: string,
}
export interface ResultData {
  action: string,
  children: ResultData[],
  device: number,
  duration: number,
  id: string,
  name: string,
  result: string,
  description: string,
  env: any[],
  logs: any[],
  loop?: number,
  image: string,
  success: boolean,
  inactive: boolean,
  type: number,
}

export interface Result extends DataObj {
  id?: string,
  results: ResultData[],
  date: number,
  duration: number,
  initiator?: Initiator,
  initiatorName?: string,
  planId: string,
  success: boolean,
  userId: string,
}

export interface Suite extends DataObj {
  id?: string,
  created?: number,
  updated?: number,
  plans: string[],
  suites?: string[],
  permissions?: string[],
  geo?: Country | unknown,
  env?: ListObj[],
  locale?: string,
  headers?: KeyValObj,
  progress?: number,
  live?: boolean,
}

export interface Template extends DataObj {
  id?: string,
  steps?: Step[],
  type?: PlanType,
  version: string,
  device?: Device,
  permissions?: string[],
  geo?: Country | unknown,
  env?: ListObj[],
  headers?: KeyValObj,
  desc: string,
  label?: string,
  locale?: string,
  updated?: string,
  created?: string,
  active: boolean
}

export interface Scheduler extends DataObj {
  id?: string,
  cron: string,
  lastRun?: number,
  nextRun?: number,
  plans?: string[],
  suites?: string[],
  timePhrase: string,
  created?: string,
  progress?: number,
  live?: boolean,
}

export interface Group extends DataObj {
  id?: string,
  avatar?: string,
  date?: string,
  package: number,
  locale: string,
  contacts: ContactItem[],
  labels: string[],
  created?: number
}
export interface Key extends DataObj {
  id?: string,
  description?: string,
  source: string[],
  hash?: string,
  actions: TreeItem[],
  lastRun?: number,
  created?: number
}

export interface User extends DataObj {
  id?: string,
  avatar: string,
  email: string,
  password?: string,
  groupName?: string,
  groups?: [{ _id: string, name: string }],
  role: number,
  created?: number
}

// General
export interface ChartSeries {
  name: string,
  colorByPoint?: boolean,
  data: any[],
  tooltip?: any,
  lineWidth?: number,
  dataLabels?: any,
  type?: string,
  innerSize?: string,
}

export interface ChartObj {
  title?: {
    style?: {
      color?: string,
      align: string,
    },
    align?: string,
    text?: string,
  },
  type: string,
  height?: string,
  xAxis?: {
    categories: string[],
  },
  pane?,
  yAxis?: any,
  series: ChartSeries[],
  colors: VuetifyThemeItem[],

  tooltip?: {
    pointFormat?: string,
    valueSuffix?: string,
  },
  plotOptions?: any,
}

// ++++++ State
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface State { }

export type GeneralContext = ActionContext<GeneralState, State>

export interface GeneralState {
  pingr: {
    currentPingr: any,
    list: any[],
    total: number
  },
  stats: {
    loading: boolean,
    plans: number,
    keys: number,
    results: number,
    schedule: number,
    pingr: number,
    suites: number,
    users: number,
    usage: ChartObj,
    testsRatio: ChartObj,
    testsExecution: ChartObj,
    testsOverTime: ChartObj,
    initiators: ChartObj,
  },
  display: {
    expand: boolean,
    pop: boolean,
    loader: boolean
  },
  title: string,
  lastRoute: string,
  slides: {
    list: string[],
    options: any
  }
  response: {
    data: any,
    options: any
  },
  exitDialog: { active: boolean, to: string },
  importDialog: { active: boolean, items: SelectItem[], title: string, action: any, args?: any[] },
  deleteDialog: { active: boolean, content: string, title: string, action: any },
  packages: SelectItem[],
  roles: SelectItem[],
  extension: boolean,
  messages: Msg[],
  notifications: Msg[]
}

// plans
export type PlansContext = ActionContext<PlansState, State>

export interface PlansState {
  plan: {
    displayStep: boolean,
    displayDebugger: boolean,
    currentDebugger: Result | any,
    currentDebuggerView: any,
    currentStep: string,
    currentPlan: Plan | any,
    currentPlanSnapshot: string,
    list: Plan[],
    steps: (Step | any)[],
    total: number,
    currentDragStep: any | null,
    currentDragOverStep: any,
    changed: boolean,
  },
  routines: SelectItem[],
  authMethods: SelectItem[],
  devices: Device[],
  countries: Country[],
  filter: DefaultFilter
}

// Groups
export type GroupsContext = ActionContext<GroupsState, State>

export interface GroupsState {
  groups: {
    list: Group[],
    currentGroup: Group | any,
    total: number,
  },
  contacts: ContactItem[]
}

// Keys
export type KeysContext = ActionContext<KeysState, State>

export interface KeysState {
  keys: {
    list: Key[],
    currentKey: Key | any,
    total: number,
  },
  actions: TreeItem[],
}

// Suites
export type SuitesContext = ActionContext<SuitesState, State>

export interface SuitesState {
  suites: {
    list: Suite[],
    currentSuite: Suite | any,
    total: number,
  }
}

// Templates
export type TemplatesContext = ActionContext<TemplatesState, State>

export interface TemplatesState {
  templates: {
    list: Template[],
    current: Template | any,
    total: number,
  }
}

// Users
export type UsersContext = ActionContext<UsersState, State>

export interface UsersState {
  role: number | null,
  user: User,
  users: {
    currentUser: User | any,
    list: User[],
    total: number
  },
  groups: Group[],
  labels: string[],
}

// Results
export type ResultsContext = ActionContext<ResultsState, State>

export interface ResultsState {
  result: {
    currentResult: Result | any,
    currentResultView: any,
    list: Result[],
    total: number
  },
  filter: DefaultFilter
}

// Scheduler
export type SchedulerContext = ActionContext<SchedulerState, State>

export interface SchedulerState {
  schedule: {
    currentSchedule: Scheduler | any,
    list: Scheduler[],
    total: number
  },
}
