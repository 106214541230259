import { DefaultFilterObject } from "@/defaults"
import { DefaultFilter, Group, GroupsContext, Msg, RoleType } from "@/types"
import { Fetcher } from "@/utils"
import { v4 } from "uuid"

const api = new Fetcher()

export default {
  init: async (context: GroupsContext): Promise<void> => {
    await context.dispatch('fetchGroups')
    // check extension existence
    context.commit('setExtension', undefined, { root: true })
  },
  fetchGroups: async (context: GroupsContext, filter: DefaultFilter = DefaultFilterObject): Promise<void> => {
    try {
      context.state.groups.list = []
      context.state.groups.total = 0
      const { data } = await api.post(`api/groups/search`, filter)
      const { groups, total } = data
      context.state.groups.list = groups
      context.state.groups.total = total
    } catch (error) {
      console.error(error);
    }
  },
  fetchCurrentGroup: async (context: GroupsContext, id: string): Promise<void> => {
    try {
      if (id) {
        const group = await api.get(`api/groups/${id}`)
        context.commit("setCurrentGroup", group)
      }
    } catch (error) {
      console.error(error);
    }
  },
  fetchGroupContacts: async (context: GroupsContext, id: string): Promise<void> => {
    try {
      const data = await api.get(`api/groups/${id}/contacts`)
      const contacts = data
      context.commit("setGroupContacts", contacts)
    } catch (error) {
      console.error(error);
    }
  },
  saveGroup: async (context: GroupsContext, groupObj: Group): Promise<any> => {
    try {
      const { _id } = groupObj
      const { role } = await api.get(`api/users/role`).then(r => r).catch(e => e)
      let data
      if (role === RoleType.ALIEN) {
        data = (await api.post(`api/groups/${_id || ''}`, groupObj)).data
      } else {
        data = (await api.put(`api/groups/${_id}`, groupObj)).data
      }
      const { group, error } = data
      await context.dispatch("fetchGroups")
      let msg: Msg = {
        id: v4(), type: 'error', icon: "account-group", content: '', dismissible: true, timeout: 3000
      }
      if (error) {
        context.dispatch('popMsg', msg, { root: true })
        return { error }
      } else {
        msg = {
          id: v4(), icon: "account-group", type: 'success', color: "cyan", ts: new Date().getTime(), content: `Group <strong>"${group.name}"</strong> was saved`, dismissible: true, timeout: 3000, notify: true
        }
        context.commit("setCurrentGroup");
      }
      context.dispatch('popMsg', msg, { root: true })
      return { data }
    } catch (error) {
      console.error(error);
    }
  },
  deleteById: async (context: GroupsContext, id: string): Promise<any> => {
    try {
      const { data } = await api.delete(`api/groups/${id}`)
      await context.dispatch("fetchGroups")
      const msg: Msg = { id: v4(), type: 'info', color: "cyan", ts: new Date().getTime(), icon: "account-group", content: `Group was successfully deleted`, dismissible: true, timeout: 3000, notify: true }
      context.dispatch('popMsg', msg, { root: true })
      return data
    } catch (error) {
      console.error(error);
    }
  },
}
