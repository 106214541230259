
import { DefaultDevice } from "@/defaults";
import { Device } from "@/types";
import Vue from "vue";
import VueTypes from "vue-types";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  name: "DeviceComp",

  data() {
    return {
      manual: false,
    };
  },
  components: {
    Tooltip,
  },
  props: {
    value: VueTypes.any.def(DefaultDevice),
  },
  methods: {
    changed() {
      // this.$emit("changed", this.device);
      this.$emit("input", this.device);
    },
  },
  computed: {
    deviceList() {
      const devices = this.$store.getters["Plans/getDeviceList"];
      return Object.keys(devices).map((device: string) => {
        return { text: device, value: device, ...devices[device] };
      });
    },
    device: {
      get(): Device {
        return this.value;
      },
      set(device: Device): void {
        this.$emit("input", { device });
      },
    },
  },
});
