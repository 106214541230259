
import { Msg } from "@/types";
import { v4 } from "uuid";
import Vue from "vue";
import { validations } from "../utils";
const email = new RegExp(
  "^[a-z0-9][a-z0-9-_.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9]).[a-z0-9]{2,10}(?:.[a-z]{2,10})?$"
);
const password = {
  strong: new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  ),
  medium: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"),
  easy: new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})"),
};

export default Vue.extend({
  name: "LoginComp",
  data() {
    return {
      rules: {
        email: [validations.email],
        password: [validations.password.len, validations.password.strength],
      },
      email: "",
      password: "",
      passShow: false,
      loading: false,
      error: { msg: "", type: "" },
    };
  },
  methods: {
    validate(field: string, type: string): boolean {
      let valid = false;
      switch (type) {
        case "email":
          valid = email.test(field);
          break;
        case "password":
          valid = password.medium.test(field);
          break;

        default:
          break;
      }
      return valid;
    },
    async login() {
      this.error = {};
      this.loading = true;
      const result = await this.$store.dispatch("Users/login", {
        email: this.email,
        password: this.password,
      });
      const { id, name } = result;
      if (id) {
        this.$router.push("/");
        const msg: Msg = {
          id: v4(),
          icon: "account",
          type: "success",
          content: `Welcome Back <strong>${name}</strong> :)`,
          dismissible: true,
          timeout: 2000,
        };
        this.$store.dispatch("popMsg", msg);
      } else {
        // console.log(result);

        this.error = result;
        setTimeout(() => {
          this.error = {};
        }, 3000);
      }
      this.loading = false;
    },
  },
  computed: {
    valid(): boolean {
      return (
        this.validate(this.email, "email") &&
        this.validate(this.password, "password")
      );
    },
  },
});
