import { render, staticRenderFns } from "./Auth.vue?vue&type=template&id=5d303ae0&scoped=true&"
import script from "./Auth.vue?vue&type=script&lang=ts&"
export * from "./Auth.vue?vue&type=script&lang=ts&"
import style0 from "./Auth.vue?vue&type=style&index=0&id=5d303ae0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d303ae0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCol,VContainer,VMain,VRow,VTab,VTabItem,VTabs})
