
import { RoleType, User } from "@/types";
import { viewIcon } from "@/utils";
import Vue from "vue";

export default Vue.extend({
  name: "SideBar",
  data() {
    return {};
  },
  methods: {
    toggleExpand() {
      this.$store.commit("toggleExpand");
    },
    getIcon(icon: string) {
      return viewIcon[icon];
    },
  },
  computed: {
    role() {
      return this.$store.getters["Users/getRole"];
    },
    user(): User {
      return this.$store.getters["Users/getUser"];
    },
    expand() {
      return this.$store.getters.getDisplayExpand;
    },
    items() {
      const items = [
        {
          name: "",
          menu: [
            {
              text: "Dashboard",
              link: "/",
              icon: "dashboard",
              role: RoleType.USER,
              badge: "",
            },
            {
              text: "Test Cases",
              link: "/plans",
              icon: "plans",
              role: RoleType.USER,
              badge: "",
            },
            {
              text: "Routines",
              link: "/routines",
              icon: "routines",
              role: RoleType.USER,
            },
            {
              text: "Templates",
              link: "/templates",
              icon: "templates",
              role: RoleType.SUPER_ADMIN,
              badge: "new",
            },
            {
              text: "Results",
              link: "/results",
              icon: "results",
              role: RoleType.USER,
              badge: "",
            },
            {
              text: "Test Suites",
              link: "/suites",
              icon: "suites",
              role: RoleType.USER,
              badge: "",
            },
            {
              text: "Scheduler",
              link: "/scheduler",
              icon: "scheduler",
              role: RoleType.USER,
              badge: "",
            },
            // {
            {
              text: "API Integrations",
              link: "/keys",
              icon: "keys",
              role: RoleType.ADMIN,
              badge: "",
            },
            {
              text: "Knowledge Center",
              link: "/learn",
              icon: "learn",
              role: RoleType.USER,
              badge: "",
            },
          ],
          role: RoleType.USER,
        },
        {
          name: "settings",
          menu: [
            {
              text: "Groups",
              link: "/groups",
              icon: "group",
              role: RoleType.SUPER_ADMIN,
              badge: "",
            },
            {
              text: "Users",
              link: "/users",
              icon: "users",
              role: RoleType.ADMIN,
              badge: "",
              exact: true,
            },
            // {
            //   text: "Group Settings",
            //   //   link: `/group/settings/${this.user.group}`,
            //   icon   link: `/group/settings/${this.user.group}`,
            //   role: RoleType.ADMIN,
            //   badge: "",
            // },
            {
              text: "User Preferences",
              link: "/preferences",
              icon: "preferences",
              role: RoleType.USER,
              badge: "",
            },
          ],
          role: RoleType.USER,
          icon: "settings",
        },
      ];
      return items;
    },
  },
  created() {
    this.$store.commit("setExpand");
    this.$store.dispatch("Users/getRole");
  },
});
