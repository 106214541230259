
import { KeyValObj, ListObj } from "@/types";
import { cloneObj, headers, validations } from "@/utils";
import Vue from "vue";
import VueTypes from "vue-types";
import ScrollWrap from "./ScrollWrap.vue";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  name: "RequestHeaders",

  data() {
    return {
      // headersList: [] as ListObj[],
      rules: {
        text: [validations.empty],
      },
    };
  },
  components: {
    ScrollWrap,
    Tooltip,
  },
  props: {
    value: VueTypes.any.def({}),
  },
  methods: {
    parseObj(headersObj: KeyValObj) {
      const headers: ListObj[] = [];
      for (const name in headersObj) {
        headers.push({ name, val: headersObj[name], type: "text" });
      }
      return headers;
    },
    parseArr(list: ListObj[]) {
      const headers: KeyValObj = {};
      list.map((h: ListObj) => {
        headers[h.name] = h.val;
      });
      return cloneObj(headers);
    },
    methodChanged() {
      const headers = this.parseArr(this.headersList);
      console.log(headers);
      this.$emit("input", headers);
    },
    removeFromList(index: number) {
      const list = [...this.headersList];
      list.splice(index, 1);
      this.headersList = list;
      // this.methodChanged();
    },
    addToList() {
      const list = [...this.headersList];
      list.push({
        name: "",
        val: "",
        type: "text",
      });
      this.headersList = list;
      // this.methodChanged();
      // activate scrolling
      const scroller = this.$refs.scroller as Element;
      if (scroller) {
        scroller.scroll();
      }
    },
  },
  computed: {
    headerList(): string[] {
      return headers;
    },
    headersList: {
      get(): ListObj[] {
        const headers = this.parseObj(this.value);
        console.log(headers);
        return headers;
      },
      set(newValue: ListObj[]): void {
        // const headers: any = {};
        // newValue.map((h: ListObj) => {
        //   headers[h.name] = h.val;
        // });
        // this.$emit("changed", { headers });
        console.log(newValue);
        const headers = this.parseArr(newValue);
        console.log(headers);
        this.$emit("input", headers);
      },
    },
  },
  created() {
    // this.headersList = this.parseObj(this.costumeHeaders);
  },
});
