import Vue from 'vue'
import Vuex from 'vuex'
import Users from './modules/Users'
import Groups from './modules/Groups'
import Plans from './modules/Plans'
import Results from './modules/Results'
import Scheduler from './modules/Scheduler'
import General from './modules/General'
import Keys from './modules/Keys'
import Suites from './modules/Suites'

import { State } from '@/types'

Vue.use(Vuex)

export default new Vuex.Store<State>({
  modules: {
    Users,
    Groups,
    Plans,
    Results,
    Scheduler,
    Keys,
    Suites,
    General
  }
})
