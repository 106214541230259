
import Vue from "vue";

import BackBtn from "../components/buttons/BackBtn.vue";
import Cron from "../components/Cron.vue";
import Title from "../components/Title.vue";
import DragList from "../components/DragList.vue";
import { Scheduler, SelectItem } from "@/types";

export default Vue.extend({
  name: "SchedulerView",
  components: {
    BackBtn,
    Title,
    Cron,
    DragList,
  },
  data() {
    return {
      rules: {
        text: [
          (val: string) => (val && val.length) || "This field is required",
        ],
      },
      suitesToggle: false,
    };
  },
  computed: {
    valid(): boolean {
      return (
        this.scheduler?.name?.length > 0 &&
        this.scheduler?.cron?.length > 0 &&
        this.scheduler?.plans?.length > 0
      );
    },
    scheduler: {
      get(): Scheduler {
        return this.$store.getters["Scheduler/getCurrentSchedule"];
      },
      set(scheduler: Scheduler) {
        this.$store.commit("Scheduler/setCurrentSchedule", scheduler);
      },
    },
    items(): SelectItem[] {
      // TODO Suites support
      const funcText = this.suitesToggle
        ? "Suites/getSuites"
        : "Plans/getPlans";
      const items = this.$store.getters[funcText];

      return items.map((item: { name: string; _id: string }) => {
        return {
          text: item.name,
          value: item._id,
          icon: this.suitesToggle ? "package-variant" : "flask",
        };
      });
    },
  },
  methods: {
    removePlan(index: number) {
      this.scheduler.plans.splice(index, 1);
    },
    updateList(list: SelectItem) {
      this.scheduler.plans = list;
    },
    updateCron(obj: { cron: string; timePhrase: string }) {
      const { cron, timePhrase } = obj;
      this.scheduler.cron = cron;
      this.scheduler.timePhrase = timePhrase;
    },
    schedulerChanged() {
      this.$store.commit("Scheduler/setCurrentSchedule", this.scheduler);
    },
    save() {
      // modify plans list to string[]
      const plans = this.scheduler.plans.map((s: SelectItem) => s.value);
      this.scheduler.plans = plans;
      this.$store.dispatch("Scheduler/saveSchedule", this.scheduler);
      this.$store.commit("Scheduler/setCurrentSchedule");
      this.$router.push("/scheduler");
    },
  },

  created() {
    this.$store.commit("setGlobalLoader", true);
    // TODO filter
    // const filter = {};
    this.$store.commit("Scheduler/setCurrentSchedule");
    this.$store.dispatch("Plans/fetchAllPlans");
    this.$store.dispatch("Suites/fetchSuites");
    const { id } = this.$route.params;
    if (id) {
      this.$store.dispatch("Scheduler/fetchCurrentSchedule", id);
    }
  },
  destroyed() {
    this.$store.commit("Scheduler/setCurrentSchedule");
  },
});
