import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import shared from "./utils/shared";
import filters from "./utils/filters";

import Highcharts from 'highcharts'
import SolidGauge from 'highcharts/modules/solid-gauge'
import HighchartsMore from 'highcharts/highcharts-more'
import HighchartsVue from 'highcharts-vue'

HighchartsMore(Highcharts)
SolidGauge(Highcharts);

// import config from './config'
// import directives from "./directives";

Vue.config.productionTip = false
// Adding custom features
Vue.use(shared)
Vue.use(filters)

Vue.use(HighchartsVue, { Highcharts, SolidGauge })

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
