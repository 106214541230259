import config from "@/config"
import router from "@/router"
import store from "@/store"
import { Plan, User, Key, Scheduler, Suite, Group, Step, Device, DataObj, DefaultTableAction, DefaultFilter, RoleType, PlanType, Msg, StepType } from "@/types"
import { capitalize, timeFormatter, viewIcon } from "@/utils"
import { v4 } from "uuid"

// +++ Filters
export const DefaultFilterObject: DefaultFilter = {
  limit: 20,
  sort: { date: -1 },
  skip: 0,
  search: { name: "" }
}

// +++ List Actions
export const DefaultAction: DefaultTableAction = {
  type: "icon",
  icon: viewIcon.plans,
  color: "info",
}

export const DefaultActionIcon: DefaultTableAction = {
  type: "icon",
  icon: viewIcon.plans,
  color: "info",
  left: true,
}
export const DefaultActionRun: DefaultTableAction = {
  type: "run",
  icon: "motion-play-outline",
  name: "",
  tooltip: (item: DataObj): string => {
    return `Run '${capitalize(item.name)}'`
  },
  color: "primary",
  medium: true,
  text: true,
  left: true,
  dark: false,
  disabled: (item: DataObj): boolean => {
    return !item.active
  },
  method: async (item: DataObj) => {
    const data = await store.dispatch(`Plans/run`, {
      id: item._id,
    });
    const msg: Msg = {
      id: v4(),
      icon: "flask",
      type: "success",
      color: "light-blue",
      ts: new Date().getTime(),
      content: `Test Case: <strong>"${data.name
        }"</strong> was running for <strong>${timeFormatter(
          data.duration
        )}</strong> was successfully completed.`,
      dismissible: true,
      timeout: 5000,
      notify: true,
    };
    if (!data.success) {
      msg.type = "error";
      msg.content = `Test Case: "${data.name
        }" was running for ${timeFormatter(
          data.duration
        )} and failed to be completed.`;
    }
    msg.content += `<strong> <a class="link" href="${config.DOMAIN
      }/results/view/${data._id || data._id}"> View results</a></strong>`;
    store.dispatch("popMsg", msg);
    store.dispatch(
      "Results/fetchAllResults",
      { ...DefaultFilterObject, date: {} }
    );
  },
}

export const DefaultActionEdit: DefaultTableAction = {
  type: "edit",
  icon: "pencil",
  name: "Edit",
  color: "teal",
  text: true,
  method: (item: DataObj) => {
    const { path } = router.currentRoute
    router.push({ path: `${path}/edit/${item._id}` });
  },
}
export const DefaultActionTemplate: DefaultTableAction = {
  icon: "application-export",
  name: "Export",
  color: "orange",
  tooltip: (item: DataObj): string => {
    return `Export '${capitalize(item.name)}' into Test Case`
  },
  text: true,
  method: async (item: DataObj) => {
    store.commit("setGlobalLoader", true)
    const name = `Plans/generateFromTemplateByID`
    await store.dispatch(name, item._id)
    store.commit("setGlobalLoader")
  },
  type: ""
}
export const DefaultProgressEdit: DefaultTableAction = {
  type: "progress",
  icon: "",
  name: "Progress",
  color: "amber",
  text: true,
}

export const DefaultActionDuplicate: DefaultTableAction = {
  type: "duplicate",
  icon: "content-duplicate",
  name: "Duplicate",
  color: "cyan",
  text: true,
  method: async (item: DataObj, scopeName: string) => {
    store.commit("setGlobalLoader", true);
    const name = `${capitalize(scopeName)}/duplicateById`;
    await store.dispatch(name, item._id);
    store.commit("setGlobalLoader");
  },
}

export const DefaultActionDelete: DefaultTableAction = {
  type: "delete",
  icon: "delete",
  name: "Delete",
  color: "error",
  text: true,
}

export const DefaultActionView: DefaultTableAction = {
  type: "view",
  icon: "clipboard-text-search-outline",
  tooltip: (item: DataObj): string => {
    return `Inspect '${capitalize(item.name)}'`
  },
  name: "",
  color: "primary",
  dark: true,
  left: true,
  text: true,
  method: (item: DataObj) => {
    const { path } = router.currentRoute
    router.push({ path: `${path}/view/${item._id}` });
  },
}

export const DefaultActionPlayVideo: DefaultTableAction = {
  type: "video",
  icon: "movie-open-play",
  name: "Video",
  color: "indigo",
  tooltip: (item: DataObj): string => {
    return `Watch '${capitalize(item.name)}' video`
  },
  dark: true,
  text: true,
  method: (item: any): void => {
    store.commit("setSlidesOptions", {
      height: "auto",
      //   width: "auto",
    });
    store.commit("setSlides", item.videos);
  },
}

// Data types
export const DefaultStep: Step = {
  id: v4(),
  type: StepType.UI,
  name: "New Step",
  waitFor: {},
  waitAfter: {},
  description: '',
  env: [],
  loop: 1,
  action: { method: "url", url: "", type: "misc" },
  children: [],
  inactive: false,
}

export const DefaultDevice: Device = {
  text: "Desktop Chrome HiDPI",
  value: "Desktop Chrome HiDPI",
  userAgent:
    "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4863.0 Safari/537.36",
  screen: {
    width: 1792,
    height: 1120
  },
  viewport: {
    width: 1280,
    height: 720
  },
  deviceScaleFactor: 2,
  isMobile: false,
  hasTouch: false,
  defaultBrowserType: "chromium"
};

export const DefaultPlan: Plan = {
  id: v4(),
  type: PlanType.PLAN,
  steps: [],
  name: "",
  version: "-",
  device: DefaultDevice,
  contact: [],
  geo: {},
  headers: {},
  locale: 'en-GB',
  permissions: [],
  callback: [],
  env: [],
  desc: "",
  progress: 0,
  live: false,
  authMethodId: '',
  isAuthMethod: false,
  skipAuthMethod: false,
  active: true,
}
export const DefaultUser: User = {
  id: v4(),
  avatar: '',
  name: '',
  email: '',
  active: true,
  password: '',
  role: RoleType.USER
}
export const DefaultGroup: Group = {
  id: v4(),
  avatar: '',
  name: '',
  locale: 'en-GB',
  package: 1,
  active: true,
  labels: [],
  contacts: [],
}

export const DefaultKey: Key = {
  id: v4(),
  name: '',
  actions: [],
  source: [],
  description: '',
  active: true,
}
export const DefaultScheduler: Scheduler = {
  id: v4(),
  cron: "0 */1 * * *",
  name: "",
  timePhrase: '',
  lastRun: undefined,
  active: true,
  plans: [],
  suites: [],
  progress: 0,
  live: false,
}

export const DefaultSuite: Suite = {
  id: v4(),
  name: '',
  created: (new Date().getTime()),
  updated: (new Date().getTime()),
  plans: [],
  suites: [],
  geo: {},
  headers: {},
  locale: '',
  progress: 0,
  live: false,
  active: true,
}
