import { render, staticRenderFns } from "./ResultLayout.vue?vue&type=template&id=200e17df&"
import script from "./ResultLayout.vue?vue&type=script&lang=ts&"
export * from "./ResultLayout.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VCol,VProgressLinear,VRow,VSheet,VSlideXTransition,VTimeline})
