
import Vue from "vue";
import Tooltip from "@/components/Tooltip.vue";
import JsonPreview from "@/components/JsonPreview.vue";
import { logsIcon, truncate } from "@/utils";

export default Vue.extend({
  name: "ResponseDialog",
  data() {
    return {};
  },
  methods: {
    parseLog(item: string): { icon: string; text: string } {
      const parsed: string[] = item.split(" -> ");
      const itemObj = { icon: logsIcon[parsed[0]], text: parsed[1] };
      return itemObj;
    },
    truncate,
  },
  computed: {
    result(): any {
      return this.$store.getters.getResponse;
    },
    active: {
      get(): boolean {
        const res = this.result;
        return typeof res === "object"
          ? Object.keys(res).length > 0
          : res.length > 0;
      },
      set(val: boolean) {
        if (!val) {
          this.$store.commit("setResponse");
        }
      },
    },
    options(): any {
      return this.$store.getters.getResponseOptions;
    },
    isLog(): boolean {
      return this.options.type === "log";
    },
    isHTML(): boolean {
      return this.options.type === "html" && typeof this.result == "string";
    },
    isJSON(): boolean {
      return this.options.type === "html" && typeof this.result == "object";
    },
  },
  components: { Tooltip, JsonPreview },
});
