
import { SelectItem, Country } from "@/types";
import { capitalize } from "@/utils";
import Vue from "vue";
import VueTypes from "vue-types";
import Tooltip from "@/components/Tooltip.vue";
export default Vue.extend({
  name: "CountriesComp",

  data() {
    return {
      manual: false,
    };
  },
  components: {
    Tooltip,
  },
  props: {
    active: VueTypes.bool.def(true),
    geo: VueTypes.any.def({}),
  },
  computed: {
    countryList(): SelectItem[] {
      const countries: Country[] = this.$store.getters["Plans/getCountryList"];

      return countries.map((country: Country) => {
        return { text: capitalize(country.value), ...country };
      });
    },
    obj: {
      get(): Country {
        return this.geo && Object.keys(this.geo).length ? this.geo : undefined;
      },
      set(newValue: Country | undefined) {
        this.$emit("changed", { geo: newValue || {} });
      },
    },
  },
});
