import { DefaultKey } from "@/defaults"
import { Key, KeysState } from "@/types"
import { filterRecursive } from "@/utils"
import { v4 } from "uuid"

export default {
  setCurrentKey: (state: KeysState, key: Key = DefaultKey): void => {
    if (!key._id) {
      key.id = v4()
    }
    state.keys.currentKey = { ...key }
  },
  deleteKey: (state: KeysState, id: string): void => {
    state.keys.list = filterRecursive(state.keys.list, id)
  },
}
