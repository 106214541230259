
import Vue from "vue";
import VueTypes from "vue-types";
import cronstrue from "cronstrue";

export default Vue.extend({
  name: "CronComp",

  data() {
    return {
      types: [
        { text: "Minutes", value: "minute" },
        { text: "Hourly", value: "hour" },
        { text: "Daily", value: "day" },
        { text: "Weekly", value: "week" },
        { text: "Monthly", value: "month" },
        // { text: "Advanced", value: "custom" },
      ],
      weekDays: [
        { text: "Sunday", value: 0 },
        { text: "Monday", value: 1 },
        { text: "Tuesday", value: 2 },
        { text: "Wednesday", value: 3 },
        { text: "Thursday", value: 4 },
        { text: "Friday", value: 5 },
        { text: "Saturday", value: 6 },
      ],
    };
  },
  props: {
    cron: VueTypes.string, // month view default
  },
  computed: {
    timePhrase(): string {
      return cronstrue.toString(this.cronSet.join(" "));
    },
    view: {
      get(): string {
        let view = "month";
        for (const i in this.cronSet) {
          if (this.cronSet[i].includes("/") || this.cronSet[i].includes(",")) {
            switch (i) {
              case "0":
                view = "minute";
                break;
              case "1":
                view = "hour";
                break;
              case "2":
                view = "day";
                break;
              case "3":
                view = "month";
                break;
              case "4":
                view = "week";
                break;
            }
          }
        }
        return view;
      },
      set(view: string) {
        return view;
      },
    },
    minute: {
      get(): number {
        return this.parseDigit(this.cronSet[0], "minute");
      },
      set(val: number) {
        this.setDigit(0, this.parseDigit(String(val || 0), "minute"), false);
      },
    },
    hour: {
      get(): number {
        return this.parseDigit(this.cronSet[1], "hour");
      },
      set(val: number) {
        this.setDigit(1, this.parseDigit(String(val || 0), "hour"), false);
      },
    },
    day: {
      get(): number {
        return this.parseDigit(this.cronSet[2], "day");
      },
      set(val: number) {
        this.setDigit(2, this.parseDigit(String(val || 0), "day"), false);
      },
    },
    month: {
      get(): number {
        return this.parseDigit(this.cronSet[3], "month");
      },
      set(val: number) {
        this.setDigit(3, this.parseDigit(String(val || 0), "month"), false);
      },
    },
    week: {
      get(): number | number[] {
        return this.parseDigit(this.cronSet[4], "week", true);
      },
      set(val: number) {
        this.setDigit(4, this.parseDigit(String(val || 0), "week"), false);
      },
    },
    cronSet(): string[] {
      return this.cron.split(" ");
    },
  },
  methods: {
    updateCron(cronSet: string[]): void {
      const cron = cronSet.join(" ");
      this.$emit("changed", { cron, timePhrase: cronstrue.toString(cron) });
    },
    resetCron(view: string): void {
      let cron = ["*", "*", "*", "*", "*"];
      switch (view) {
        case "minute":
          cron = ["*/1", "*", "*", "*", "*"];
          break;
        case "hour":
          cron = ["0", "*/1", "*", "*", "*"];
          break;
        case "day":
          cron = ["0", "0", "*/1", "*", "*"];
          break;
        case "week":
          cron = ["0", "0", "*", "*", "0"];
          break;
        case "month":
          cron = ["0", "0", "1", "*/1", "*"];
          break;

        default:
          cron = ["*", "*", "*", "*", "*"];
          break;
      }

      this.updateCron(cron);
    },
    parseDigit(digit: string, view: string, list = false): number | number[] {
      let val: number | number[] = +digit || 0;

      if (digit.includes("/")) {
        this.view = view;
        val = +digit.split("/")[1];
      }
      if (digit.includes(",")) {
        this.view = view;
        val = digit.split(",").map((s: string | number) => +s);
      }
      if (list && !Array.isArray(val)) {
        val = [val];
      }
      return val;
    },
    setDigit(pos: number, value: string | string[], every = true) {
      const cron = [...this.cronSet];
      let val: string | string[] | number | number[] = value;
      if (Array.isArray(val)) {
        if (val.length) {
          val = val.join();
        } else {
          val = "*";
        }
      } else {
        val = +val;
        if (!val || val <= 0) {
          val = 0;
        }
        if (every) {
          val = val > 0 ? `*/${val}` : "*";
        }
      }
      cron[pos] = val;
      this.updateCron(cron);
    },
  },
});
