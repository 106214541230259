import { PlansContext, Msg, Plan, Result, Step, DefaultFilter, SelectItem } from "@/types"
import { Fetcher, reId } from "@/utils"
import { v4 } from "uuid"

const api = new Fetcher()

export default {
  savePlan: async (_: PlansContext, plan: Plan): Promise<Result> => {
    try {
      const data = await api.save('plans', plan)
      return data
    } catch (error) {
      return error
    }
  },

  fetchAllPlans: async (context: PlansContext, filter: DefaultFilter = context.getters.getFilter, reset = false): Promise<void> => {
    try {
      if (reset) {
        context.state.plan.list = []
        context.state.plan.total = 0
      }
      const data = await api.fetchAll('plans', filter)
      const { plans, total } = data
      context.state.plan.list = plans
      context.state.plan.total = total
    } catch (error) {
      console.error(error);
    }
    // check extension existence
    context.commit('setExtension', undefined, { root: true })
  },

  fetchAllRoutines: async (context: PlansContext, filter: DefaultFilter = { limit: 5, sort: { name: 1 }, type: 1 }, reset = true): Promise<void> => {
    try {
      if (reset) {
        context.state.routines = []
      }
      const data = await api.fetchAll('plans', filter)
      const { plans } = data
      context.state.routines = plans.map((p: Plan): SelectItem => {
        return {
          text: p.name,
          value: p._id
        }
      })
    } catch (error) {
      console.error(error);
    }
    // check extension existence
    context.commit('setExtension', undefined, { root: true })
  },

  fetchAllAuthMethods: async (context: PlansContext, filter: any = { limit: 5, sort: { name: 1 }, type: 1, isAuthMethod: true }, reset = true): Promise<void> => {
    try {
      if (reset) {
        context.state.authMethods = []
      }
      const data = await api.fetchAll('plans', filter)
      const { plans } = data
      context.state.authMethods = plans.map((p: Plan): SelectItem => {
        return {
          text: p.name,
          value: p._id
        }
      })
    } catch (error) {
      console.error(error);
    }
    // check extension existence
    context.commit('setExtension', undefined, { root: true })
  },

  fetchPlan: async (context: PlansContext, id: string): Promise<void> => {
    try {
      const data = await api.fetchByID('plans', id)
      if (!data) {
        console.error('no such plan ID...');
        return
      }
      return data
    } catch (error) {
      console.error(error);
      return
    }
  },

  fetchSteps: async (context: PlansContext, name = undefined): Promise<void> => {
    try {
      const data = await api.get(`api/plans/steps/${name}`)
      if (!data) {
        console.error('no such plan ID...');
        return
      }
      context.state.plan.steps = data.map((s: { name: string, _id: string }) => {
        s['text'] = s.name
        s['value'] = s._id
        return s
      })
      return data
    } catch (error) {
      console.error(error);
    }
  },

  deleteById: async (context: PlansContext, id: string): Promise<any> => {
    try {
      const { data } = await api.delete(`api/plans/${id}`)
      context.dispatch('fetchAllPlans')
      return data
    } catch (error) {
      console.error(error);
    }
  },
  duplicateById: async (context: PlansContext, id: string): Promise<any> => {
    try {
      const current = await api.get(`api/plans/${id}`)
      const plan: Plan = { ...current }
      const oldName = plan.name
      delete plan._id
      delete plan.lastDuration
      delete plan.lastRun
      delete plan.updated
      delete plan.avgDuration
      delete plan.stats
      delete plan.created
      plan.name += ' - COPY'

      // restructure step id's for de-duplications
      plan.steps.forEach((step: Step, i: number) => {
        const newTest = { ...step }
        plan.steps[i] = reId(newTest)
      });
      plan.status = 0
      const data = await api.post(`api/plans/`, plan)
      context.dispatch('fetchAllPlans')
      const msg: Msg = { id: v4(), type: 'info', content: `Test Case: <strong>"${oldName}"</strong> was duplicated successfully`, dismissible: true, timeout: 5000 }
      context.dispatch('popMsg', msg, { root: true })
      return data
    } catch (error) {
      console.error(error);
    }
  },
  generateTemplate: async (context: PlansContext, plan: Plan): Promise<any> => {
    try {
      const newTemplate: Plan = { ...plan }
      const oldName = newTemplate.name
      delete newTemplate._id
      delete newTemplate.lastDuration
      delete newTemplate.lastRun
      delete newTemplate.updated
      delete newTemplate.avgDuration
      delete newTemplate.stats
      delete newTemplate.created
      plan.callback = []
      newTemplate.name += ' - TEMPLATE'

      // restructure step id's for de-duplications
      newTemplate.steps.forEach((step: Step, i: number) => {
        const newTest = { ...step }
        newTemplate.steps[i] = reId(newTest)
      });
      newTemplate.status = 0
      const data = await api.post(`api/plans/`, newTemplate)
      context.dispatch('fetchAllPlans')
      const msg: Msg = { id: v4(), type: 'info', color: 'teal', content: `Template from: <strong>"${oldName}"</strong> was successfully generated`, dismissible: true, timeout: 5000 }
      context.dispatch('popMsg', msg, { root: true })
      return data
    } catch (error) {
      console.error(error);
    }
  },
  run: async (context: PlansContext, { id, debug = false, cancelToken = undefined }: { id: string, debug: boolean, cancelToken?: any }): Promise<Result | undefined> => {
    try {
      context.commit('changePlanProgress', { id, live: true })
      const { data } = await api.post(`api/plans/run/${id}?debug=${debug}`, { id }, null, cancelToken)
      context.dispatch('fetchAllPlans')
      return data
    } catch (error) {
      console.error(error);
    }
  },
  // deleteStep: (context: PlansContext, id: string): void => {
  //   context.commit('deleteStep', id)
  // },
  fetchDeviceList: async (context: PlansContext): Promise<void> => {
    try {
      const data = await api.get(`api/plans/deviceList`)
      const { devices } = data
      context.commit("setDeviceList", devices)
    } catch (error) {
      console.error(error);
    }
  },
  fetchCountryList: async (context: PlansContext): Promise<void> => {
    try {
      const data = await api.get(`api/plans/countries`)
      const { countries } = data
      context.commit("setCountryList", countries)
    } catch (error) {
      console.error(error);
    }
  },
}
