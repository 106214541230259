
import Vue from "vue";
import { validations } from "../utils";
const email = new RegExp(
  "^[a-z0-9][a-z0-9-_.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9]).[a-z0-9]{2,10}(?:.[a-z]{2,10})?$"
);
const password = {
  strong: new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  ),
  medium: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"),
  easy: new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})"),
};

export default Vue.extend({
  name: "RegisterComp",
  data() {
    return {
      rules: {
        email: [validations.email],
        password: [validations.password.len, validations.password.strength],
        text: [validations.empty],
      },
      name: "",
      email: "",
      // password: "",
      // passShow: false,
      message: "",
      loading: false,
      // error: {}
    };
  },
  methods: {
    validate(field: string, type: string): boolean {
      let valid = false;
      switch (type) {
        case "email":
          valid = email.test(field);
          break;
        case "name":
          valid = field.length >= 3;
          break;
        case "password":
          valid = password.medium.test(field);
          break;

        default:
          break;
      }
      return valid;
    },
    async contact() {
      this.loading = true;
      this.message = await this.$store.dispatch("Users/contactPartner", {
        email: this.email,
        name: this.name,
      });
      this.loading = false;
    },
    // async login() {
    //   this.error = {};
    //   this.loading = true;
    //   const result = await this.$store.dispatch("Users/login", {
    //     email: this.email,
    //     password: this.password
    //   });

    //   if (result.id) {
    //     this.$store.dispatch("popMsg", {
    //       type: "success",
    //       content: `Welcome Back ${result.name} :)`,
    //       dismissible: true,
    //       timeout: 2000
    //     });
    //     setTimeout(() => {
    //       // redirect
    //       this.$router.push("/");
    //     }, 2000);
    //   } else {
    //     console.log(result);

    //     this.error = result;
    //     setTimeout(() => {
    //       this.error = {};
    //     }, 3000);
    //   }
    //   this.loading = false;
    // }
  },
  computed: {
    valid(): boolean {
      return (
        this.validate(this.email, "email") && this.validate(this.name, "name")
      );
    },
  },
});
