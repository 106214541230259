import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { KeysState } from '@/types'

export default {
  namespaced: true,
  state: (): KeysState => {
    return {
      keys: {
        list: [],
        currentKey: {},
        total: 0,
      },
      actions: [
        {
          id: 'suites',
          name: "Test Suites", children: [
            {
              name: 'Read',
              id: 10
            }, {
              name: 'Execute',
              id: 13
            }
          ]
        },
        {
          id: 'plans',
          name: "Test Cases", children: [
            {
              name: 'Read',
              id: 0
            }, {
              name: 'Execute',
              id: 3
            }
          ]
        },
        {
          id: 'scheduler',
          name: "Scheduler", children: [
            {
              name: 'Read',
              id: 4
            },
          ]
        },
        {
          id: 'users',
          name: "Users", children: [
            {
              name: 'Read',
              id: 7
            },
          ]
        },
        // { text: "Test Suites -> Read", value: 10 },
        // { text: "Test Suites -> Write", value: 11 },
        // { text: "Test Suites -> Delete", value: 12 },
        // { text: "Test Suites -> Execute", value: 13 },
        // { text: "Test Cases -> Read", value: 0 },
        // { text: "Test Cases -> Write", value: 1 },
        // { text: "Test Cases -> Delete", value: 2 },
        // { text: "Test Cases -> Execute", value: 3 },
        // { text: "Scheduler -> Read", value: 4 },
        // { text: "Scheduler -> Write", value: 5 },
        // { text: "Scheduler -> Delete", value: 6 },
        // { text: "Users -> Read", value: 7 },
        // { text: "Users -> Write", value: 8 },
        // { text: "Users -> Delete", value: 9 },
      ]
    }
  },
  actions,
  mutations,
  getters
}
