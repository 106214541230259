
import Vue from "vue";
import Slogen from "@/components/Slogen.vue";

export default Vue.extend({
  name: "NotFound",
  mounted() {
    setTimeout(() => {
      this.$router.push("/");
    }, 3000);
  },
  components: { Slogen },
});
