
import Vue from "vue";
import ListTable from "../components/listTable/ListTable.vue";
import AddBtn from "../components/buttons/AddBtn.vue";
import FilterBar from "../components/Filter/FilterBar.vue";
import {
  DefaultActionRun,
  DefaultActionDelete,
  DefaultActionDuplicate,
  DefaultActionEdit,
  DefaultFilterObject,
DefaultProgressEdit,
} from "../defaults";
import { DataObj, Msg, Pagination, Suite } from "../types";
import { timeFormatter, updateObj } from "@/utils";
import { v4 } from "uuid";
import config from "@/config";

export default Vue.extend({
  name: "SuitesView",
  components: {
    ListTable,
    AddBtn,
    FilterBar,
  },
  data() {
    return {
      headers: [
        {
          text: "Test Suite Name",
          align: "start",
          filterable: false,
          value: "name",
          type: "text",
          to: (item: Suite): string => {
            return `${this.$route.path}/edit/${item._id}`;
          },
          tooltip: (item: Suite): string => {
            return `Edit '${item}'`;
          },
        },
        { text: "Suite Identifier", value: "_id", type: "text", copy: true },
        { text: "Updated", value: "updated", type: "date" },
        { text: "Created", value: "created", type: "date" },
        { text: "Active", value: "active", type: "active" },
      ],
      actions: [
        {
          ...DefaultActionRun,
          method: async (item: DataObj) => {
            const data = await this.$store.dispatch(`Suites/run`, {
              id: item._id,
            });
            const msg: Msg = {
              id: v4(),
              icon: "flask",
              type: "success",
              color: "orange",
              ts: new Date().getTime(),
              content: `Test Suite: <strong>"${
                data.name
              }"</strong> was running for <strong>${timeFormatter(
                data.duration
              )}</strong> was successfully completed.`,
              dismissible: true,
              timeout: 5000,
              notify: true,
            };
            msg.content += `<strong> <a class="link" href="${config.DOMAIN}/results"> View results</a></strong>`;
            this.$store.dispatch("popMsg", msg, { root: true });
            this.$store.dispatch(
              "Results/fetchAllResults",
              { ...DefaultFilterObject, date: {} },
              { root: true }
            );
          },
        },
        DefaultProgressEdit,
        DefaultActionEdit,
        DefaultActionDuplicate,
        DefaultActionDelete,
      ],
      filter: DefaultFilterObject,
      sortOptions: [
        { text: "Updated", value: "updated" },
        { text: "Name", value: "name" },
        { text: "Active", value: "active" },
      ],
      searchOptions: [{ text: "Name", value: "name" }],
      options: {},
      loading: false,
    };
  },
  methods: {
    async fetchList() {
      this.loading = true;
      await this.$store.dispatch("Suites/fetchSuites", this.filter);
      this.loading = false;
    },
    pageChange(val: number): void {
      const { limit } = this.filter;
      const skip = (val - 1) * limit;
      this.filter.skip = skip;
      this.changed();
    },
    changed(data: undefined | null) {
      if (data) {
        this.filter = updateObj(this.filter, data);
      }
      this.fetchList();
    },
  },
  computed: {
    items() {
      return this.$store.getters["Suites/getSuites"];
    },
    total() {
      return this.$store.getters["Suites/getSuitesTotal"];
    },
    pagination(): Pagination {
      const { limit } = this.filter;
      const count = this.total;
      const total = Math.ceil(count / limit);
      return { total, current: 1, visible: 7, count, limit };
    },
  },
  created() {
    this.changed();
  },
  // mounted() {
  //   console.log(this.items);
  // },
});
