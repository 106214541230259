
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "BackBtn",

  data() {
    return {};
  },
  props: {
    to: VueTypes.string,
  },
  computed: {
    lastRoute() {
      return this.$store.getters.getLastRoute;
    },
  },
});
