import { Suite, SuitesState } from "@/types"

export default {
  getSuiteById: (state: SuitesState) => (id: string) => {
    return state.suites.list.find((suite: Suite) => suite.id === id)
  },
  getSuites: (state: SuitesState): Suite[] => {
    return state.suites.list
  },
  getSuitesTotal: (state: SuitesState): number => {
    return state.suites.total
  },
  getCurrentSuite: (state: SuitesState): Suite => {
    return state.suites.currentSuite
  },
  getCurrentSuiteId(state: SuitesState) {
    return state.suites.currentSuite._id
  },
}
