
import Vue from "vue";
import { any, number } from "vue-types";
import Tooltip from "@/components/Tooltip.vue";
import HealthIcon from "./listTable/HealthIcon.vue";
import { logsIcon, timeFormatter, truncate } from "@/utils/index";
import Theme from "../plugins/vuetify";
import ChartWrap from "./ChartWrap.vue";
import VideoPlayer from "./VideoPlayer.vue";
import moment from "moment";
import { SelectItem } from "@/types";
import ImageSlot from "./ImageSlot.vue";

const { mainGreen, mainRed } = Theme?.userPreset?.theme?.themes?.light || {};

export default Vue.extend({
  components: { Tooltip, HealthIcon, ChartWrap, VideoPlayer, ImageSlot },
  name: "ResultStats",

  data() {
    return {
      statsOpen: true,
      panel: [0],
      colors: {
        info: "light-blue",
        error: "red",
        warning: "orange",
      },
    };
  },
  props: {
    result: any().def({}),
    height: number().def(70),
  },
  computed: {
    stats() {
      // console.log(this.result);
      return this.result?.stats
        ? { ...this.result.stats, version: this.result.version }
        : {};
    },
    videos() {
      return this.result?.videos || [];
    },
    pages() {
      const pages = [];
      for (const page in this.result?.pages) {
        const { diff, reference, current } = this.result?.pages[page];
        pages.push({
          url: page,
          diff,
          reference,
          current,
        });
      }
      console.log(pages);
      return pages;
    },
    logs(): SelectItem[] {
      const logs: SelectItem[] =
        this.result?.logs?.map((l) => this.parseLog(l)) || [];
      return logs;
    },
    rate() {
      const { passed, failed } = this.stats;
      return (+passed / (+passed + failed)) * 100;
    },
    ratio() {
      const { passed, failed } = this.stats;
      const all = passed + failed;
      const precent = (+passed / all) * 100;
      const obj = {
        // title: {
        //   style: {
        //     // color: primaryText,
        //     // align: "bottom",
        //   },
        //   align: "center",
        //   text: "Steps",
        // },
        type: "pie",
        tooltip: {
          pointFormat: "<b>{point.num}/{point.all}</b> {series.name}",
        },
        height: "100%",
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.y:.1f} %",
            },
          },
        },
        series: [
          {
            name: "Steps",
            colorByPoint: true,
            size: "60%",
            innerSize: "70%",
            data: [
              {
                name: "Passed",
                y: precent,
                num: passed,
                all,
              },
              {
                name: "Failed",
                y: 100 - precent,
                num: failed,
                all,
              },
            ],
          },
        ],
        colors: [mainGreen, mainRed],
      };

      return obj;
    },
  },
  methods: {
    timeSymbol: timeFormatter,
    truncate,
    parseLog(item: string): {
      icon: string;
      text: string;
      time: string;
      color: string;
    } {
      const parsed: string[] = item.split(" -> ");
      const time = moment(+parsed[1]).format("HH:mm DD/MM/YY");
      const color = this.colors[parsed[3]] || "green";
      const itemObj = {
        icon: logsIcon[parsed[0]],
        text: parsed[2],
        time,
        color,
      };
      return itemObj;
    },
    openVideos() {
      this.$store.commit("setSlidesOptions", {
        height: "auto",
        //   width: "auto",
      });
      this.$store.commit("setSlides", this.videos);
    },
  },
});
