import { DefaultFilterObject } from "@/defaults"
import { DefaultFilter, GeneralContext, Msg } from "@/types"
import { Fetcher, genTimeFrame, sumArrayOfNumbers } from "@/utils"
import moment from "moment"

const api = new Fetcher()

export default {
  // Stats

  fetchStats: async (context: GeneralContext, filter: DefaultFilter = { ...DefaultFilterObject }): Promise<void> => {
    context.commit('resetStats')
    try {
      const { data } = await api.post(`api/stats/search`, filter)
      const { plans, keys, results, schedule, pingr, suites, users, chart } = data
      const { failed, passed, categories, total, initiators, usage } = chart

      context.state.stats.plans = plans
      context.state.stats.keys = keys
      context.state.stats.results = results
      context.state.stats.schedule = schedule
      context.state.stats.pingr = pingr
      context.state.stats.suites = suites
      context.state.stats.users = users

      // tests ratio chart
      const totalTests: number = sumArrayOfNumbers(Object.values(total))
      const totalPassed: number = +(sumArrayOfNumbers(passed) / totalTests * 100).toFixed(2)
      const totalFailed: number = +(100 - totalPassed).toFixed(2)
      context.state.stats.testsRatio.series[0].data[0].y = totalPassed
      context.state.stats.testsRatio.series[0].data[1].y = totalFailed

      // tests initiators chart
      let initiatorsTotal = 0;
      Object.keys(initiators).forEach((key: string) => {
        initiatorsTotal += initiators[key]
      })

      for (const initiator in initiators) {
        context.state.stats.initiators.series[0].data.push({
          name: initiator,
          y: initiators[initiator] / initiatorsTotal * 100
        })
      }
      const frame = 'months'
      const end = +moment()
      const start = +moment().subtract(1, frame).valueOf()
      const linePassed: number[] = []
      const lineFailed: number[] = []
      const lineTotal: number[] = []
      const timeframe = genTimeFrame(start, end).map((f) => moment(f).format('D/M'))
      timeframe.forEach((tf: string) => {
        const idx = categories.indexOf(tf)
        const totalList: number[] = Object.values(total)
        if (idx >= 0) {
          lineFailed.push(failed[idx])
          linePassed.push(passed[idx])
          lineTotal.push(totalList[idx])
        } else {
          lineFailed.push(0)
          linePassed.push(0)
          lineTotal.push(0)
        }
      });

      // tests overtime chart
      context.state.stats.testsOverTime.xAxis = { categories: timeframe }
      context.state.stats.testsOverTime.series[0].data = lineTotal
      context.state.stats.testsOverTime.series[1].data = linePassed
      context.state.stats.testsOverTime.series[2].data = lineFailed

      // tests execution chart
      context.state.stats.testsExecution.xAxis = { categories: timeframe }
      context.state.stats.testsExecution.series[0].data = lineTotal


      // plan usage
      context.state.stats.usage.yAxis.max = usage.plan
      context.state.stats.usage.series[0].data = [usage.total]

    } catch (error) {
      console.error(error);
    }
    // check extension existence
    context.commit('setExtension')
  },
  // GENERAL
  popMsg: (context: GeneralContext, msg: Msg): void => {
    context.commit('setMsg', msg)
    if (msg.notify) {
      context.commit('setNotification', msg)
    }
    if (msg.timeout) {
      setTimeout(() => {
        context.commit('rmMsg', msg.id)
      }, msg.timeout);
    }
  },
}
