
import Vue from "vue";
import VueTypes from "vue-types";
import Loader from "./Loader.vue";

export default Vue.extend({
  components: { Loader },
  name: "ImageSlot",

  data() {
    return {
      loading: true,
    };
  },
  props: {
    source: VueTypes.string.def(""),
    width: VueTypes.string.def("100%"),
  },
});
