
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "VideoPlayer",

  data() {
    return {};
  },
  props: {
    src: VueTypes.string,
    autoplay: VueTypes.bool.def(false),
  },
});
