
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "LimitComp",

  data() {
    return {
      items: [10, 20, 30, 50, 100],
    };
  },
  props: {
    value: VueTypes.number,
  },
  methods: {
    changed() {
      this.$emit("changed", { key: "limit", val: this.value });
      this.$emit("input", this.limit);
    },
  },
  computed: {
    limit: {
      get(): number {
        return this.value;
      },
      set(val: number) {
        this.$emit("input", val);
      },
    },
  },
});
