
import Vue from "vue";
import VueTypes from "vue-types";
import { numFormatter } from "@/utils";
import DrillLink from "./DrillLink.vue";

export default Vue.extend({
  components: { DrillLink },
  name: "StatsCard",

  data() {
    return {};
  },
  props: {
    color: VueTypes.string.def("light-blue"),
    icon: VueTypes.string.def(""),
    label: VueTypes.string.def(""),
    link: VueTypes.string.def(""),
    data: VueTypes.number.def(0),
    active: VueTypes.bool.def(true),
  },
  methods: { numFormatter },
});
