import actions from './actions'
import mutations from './mutations'
import getters from './getters'

import Theme from "../../../plugins/vuetify";
import { GeneralState } from '@/types';

const { mainGreen, mainBlue, mainRed, mainPurple, mainLightBlue } =
  Theme?.userPreset?.theme?.themes?.light || {};

export default {
  state: (): GeneralState => {
    return {
      pingr: {
        currentPingr: {},
        list: [],
        total: 0
      },
      stats: {
        loading: false,
        plans: 0,
        keys: 0,
        results: 0,
        schedule: 0,
        pingr: 0,
        suites: 0,
        users: 0,
        usage: {
          title: {
            style: {
              // color: primaryText,
              align: "left",
            },
            align: "left",
            text: "Plan Monthly Usage",
          },
          type: "solidgauge",
          pane: {
            center: ['50%', '60%'],
            size: '100%',
            startAngle: -90,
            endAngle: 90,
            background: {
              backgroundColor: '#EEE',
              innerRadius: '60%',
              outerRadius: '100%',
              shape: 'arc'
            }
          },

          yAxis: {
            min: 0,
            max: 0,
            stops: [
              [0.5, '#55BF3B'], // green
              [0.7, '#DDDF0D'], // yellow
              [0.9, '#DF5353'] // red
            ],
            // lineWidth: 0,
            // tickWidth: 0,
            // minorTickInterval: null,
            tickAmount: 5,
            title: {
              y: -90,
              text: 'Usage / Hours'
            },
            labels: {
              y: 16
            }
          },

          series: [{
            name: 'Hours',
            data: [],
            dataLabels: {
              format:
                '<div style="text-align:center">' +
                '<span style="font-size:25px">{y}</span><br/>' +
                '<span style="font-size:12px;opacity:0.4">Total Hours</span>' +
                '</div>'
            },
            tooltip: {
              valueSuffix: ''
            }
          }],
          colors: [],
          plotOptions: {
            solidgauge: {
              dataLabels: {
                y: 5,
                borderWidth: 0,
                useHTML: true
              }
            }
          }
        },
        testsRatio: {
          title: {
            style: {
              // color: primaryText,
              align: "left",
            },
            align: "left",
            text: "Passed/Failed Ratio",
          },
          type: "pie",
          height: "73%",
          tooltip: {
            pointFormat: '{series.name}: <b>{point.y:.1f}%</b>'
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b>: {point.y:.1f} %",
              },
            },
          },
          series: [
            {
              name: "Test Cases",
              colorByPoint: true,
              data: [
                {
                  name: "Passed",
                  y: 0,
                },
                {
                  name: "Failed",
                  y: 0,
                },
              ],
            },
          ],
          colors: [mainBlue, mainRed],
        },
        testsExecution: {
          title: {
            style: {
              // color: primaryText,
              align: "left",
            },
            align: "left",
            text: "Daily Test Cases Execution",
          },
          type: "column",
          xAxis: {
            categories: [],
          },
          yAxis: {
            title: {
              text: 'Test Cases Execution'
            },
          },
          series: [{
            data: [],
            lineWidth: 3,
            name: "Total Tests Executions"
          }],
          colors: [mainLightBlue],
        },
        testsOverTime: {
          title: {
            style: {
              // color: primaryText,
              align: "left",
            },
            align: "left",
            text: "Test Cases Over Time",
          },
          yAxis: {
            title: {
              text: 'Test Cases Execution'
            },
          },
          type: "spline",
          height: "40%",
          series: [
            {
              name: "Total",
              data: [],
              lineWidth: 3,
            },
            {
              name: "Passed",
              data: [],
              lineWidth: 3
            },
            {
              name: "Failed",
              data: [],
              lineWidth: 3
            },
          ],
          plotOptions: {
            spline: {
              marker: {
                enabled: false,
                symbol: 'circle',
                radius: 2,
                states: {
                  hover: {
                    enabled: true
                  }
                }
              }
            }
          },
          xAxis: { categories: [] },
          colors: [mainBlue, mainGreen, mainRed],
        },
        initiators: {
          title: {
            style: {
              // color: primaryText,
              align: "left",
            },
            align: "left",
            text: "Initiators",
          },
          type: "pie",
          tooltip: {
            pointFormat: '{series.name}: <b>{point.y:.1f}%</b>'
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                enabled: true,
                format: "<b>{point.name}</b>: {point.y:.1f} %",
              },
            },
          },
          series: [
            {
              innerSize: '50%',
              name: "Initiators",
              colorByPoint: true,
              data: []
            },
          ],
          colors: [mainBlue, mainRed, mainPurple, mainGreen],
        },
      },
      lastRoute: '/',
      display: {
        expand: false,
        pop: false,
        loader: false
      },
      title: "",
      extension: false,
      messages: [],
      notifications: [],
      slides: {
        list: [],
        options: {
          height: '80vh',
          delimiters: false,
          continuous: true,
          cycle: false
        }
      },
      response: {
        data: '',
        options: {
          height: '80vh',
          type: 'html'
        }
      },
      exitDialog: { active: false, to: '/' },
      importDialog: { active: false, items: [], title: '', action: null },
      deleteDialog: { active: false, content: '', title: '', action: null },
      packages: [
        { text: "Unlimited (10K Hour/Month)", value: 0 },
        { text: "Enterprize (1440 Hour/Month)", value: 1 },
        { text: "Pro (720 Hour/Month)", value: 2 },
        { text: "Basic (360 Hour/Month)", value: 3 },
      ],
      roles: [
        { text: "Alien", value: 0 },
        { text: "Super Admin", value: 1 },
        { text: "Admin", value: 2 },
        { text: "User", value: 3 },
      ]
    }
  },
  actions,
  mutations,
  getters
}
