
import Vue from "vue";
import ListTable from "../components/listTable/ListTable.vue";
import AddBtn from "../components/buttons/AddBtn.vue";
import FilterBar from "../components/Filter/FilterBar.vue";
import {
  DefaultActionDelete,
  DefaultActionDuplicate,
  DefaultActionEdit,
  DefaultFilterObject,
} from "../defaults";
import { Pagination, Scheduler } from "../types";
import { updateObj } from "@/utils";

export default Vue.extend({
  name: "SchedulersView",
  components: {
    ListTable,
    AddBtn,
    FilterBar,
  },
  data() {
    return {
      headers: [
        {
          text: "Scheduler Name",
          align: "start",
          filterable: false,
          value: "name",
          type: "text",
          to: (item: Scheduler): string => {
            return `${this.$route.path}/edit/${item._id}`;
          },
        },
        { text: "Last Run", value: "lastRun", type: "date" },
        { text: "Next Run", value: "nextRun", type: "date" },
        { text: "In Progress", value: "progress", type: "progress" },
        { text: "Scheduled Time", value: "timePhrase", type: "text" },
        { text: "Active", value: "active", type: "active" },
      ],
      actions: [DefaultActionEdit, DefaultActionDuplicate, DefaultActionDelete],
      filter: DefaultFilterObject,
      sortOptions: [
        { text: "Last Run", value: "lastRun" },
        { text: "Name", value: "name" },
        { text: "Active", value: "active" },
      ],
      searchOptions: [{ text: "Name", value: "name" }],
      options: {},
      loading: false,
    };
  },
  methods: {
    async fetchList() {
      this.loading = true;
      await this.$store.dispatch("Scheduler/fetchAllSchedules", this.filter);
      this.loading = false;
    },
    pageChange(val: number): void {
      const { limit } = this.filter;
      const skip = (val - 1) * limit;
      this.filter.skip = skip;
      this.changed();
    },
    changed(data: unknown | null) {
      if (data) {
        this.filter = updateObj(this.filter, data);
      }
      this.fetchList();
    },
  },
  computed: {
    items() {
      return this.$store.getters["Scheduler/getSchedules"];
    },
    total() {
      return this.$store.getters["Scheduler/getSchedulesTotal"];
    },
    pagination(): Pagination {
      const { limit } = this.filter;
      const count = this.total;
      const total = Math.ceil(count / limit);
      return { total, current: 1, visible: 7, count, limit };
    },
  },
  created() {
    this.changed();
  },
  // mounted() {
  //   console.log(this.items);
  // },
});
