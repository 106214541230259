
import { SelectItem } from "@/types";
import Vue from "vue";
import VueTypes from "vue-types";
import CopyToClip from "../CopyToClip.vue";
import Title from "../Title.vue";

export default Vue.extend({
  name: "KeysGeneratorDialog",

  data() {
    return {
      requestsTypes: [
        { text: "Fetch API", value: "fetch" },
        { text: "XHR", value: "xhr" },
        { text: "cURL", value: "curl" },
        { text: "Wget", value: "wget" },
      ],
      methods: [
        { text: "Test Cases", value: 0 },
        { text: "Test Suites", value: 10 },
        { text: "Scheduler", value: 4 },
        { text: "Users", value: 7 },
      ],
      keyActions: [
        { text: "Read", value: 0 },
        { text: "Write", value: 1 },
        { text: "Delete", value: 2 },
        { text: "Execute", value: 3 },
      ],
      requests: {
        fetch: `const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
const requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch("$URL$", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`,
        curl: `curl  --location --request GET '$URL$'
  --header 'Content-Type: application/json'`,
        xhr: `const xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function() {
  if(this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open("GET", "$URL$");
xhr.setRequestHeader("Content-Type", "application/json");

xhr.send();`,
        wget: `wget --no-check-certificate --quiet \
--method GET \
--timeout=0 \
--header 'Content-Type: application/json' \
'$URL$'`,
      },
      selecteAction: 0,
      selecteMethod: 10,
      selecteRequest: "fetch",
      actionTarget: "",
      skip: false,
    };
  },
  components: { CopyToClip, Title },
  props: {
    active: VueTypes.bool.def(true),
    actions: VueTypes.any.def([]),
    apiKey: VueTypes.string.def(""),
  },
  methods: {
    processCode(code: string): string {
      let parsed = code;
      // replace < and > with &lt; and &gt;
      // parsed = parsed.replaceAll("<", "&lt;");
      // parsed = parsed.replaceAll(">", "&gt;");
      parsed = parsed.replaceAll("const", "<span class='keyword'>const</span>");
      return parsed;
    },
    close() {
      this.$emit("close");
    },
  },
  computed: {
    url(): string {
      const url = `https://api.pine3.io/api/keys/run/${this.apiKey}/${
        this.selecteAction + this.selecteMethod
      }/${this.actionTarget || "[ACTION_TARGET_ID]"}${
        this.skip ? "/?skip=true" : "/?skip=false"
      }`;
      return url;
    },
    content(): SelectItem {
      const content: string = this.requests[this.selecteRequest];
      return this.processCode(content.replace("$URL$", this.url));
    },
    currentMethod(): string {
      let method = "Suites/getSuites";
      switch (this.selecteMethod) {
        case 0:
          method = "Plans/getPlans";
          this.$store.dispatch("Plans/fetchAllPlans");
          break;
        case 10:
          method = "Suites/getSuites";
          this.$store.dispatch("Suites/fetchSuites");
          break;
        case 4:
          method = "Scheduler/getSchedules";
          this.$store.dispatch("Scheduler/fetchAllSchedules");
          break;
        case 7:
          method = "Users/getUsers";
          this.$store.dispatch("Users/fetchAllUsers");
          break;

        default:
          method = "Suites/getSuites";
          this.$store.dispatch("Suites/fetchSuites");
          break;
      }
      return method;
    },
    targets(): string {
      const data = this.$store.getters[this.currentMethod];
      return data.map((d: any) => {
        return {
          text: `${d.name} (${d._id})`,
          value: d._id,
        };
      });
    },
    // keyActions(): SelectItem {
    //   return this.actions.length
    //     ? this.actions
    //     : this.$store.getters["Keys/getActions"];
    // },
  },
  // updated() {
  //   // activate scrolling
  //   const scroller = this.$refs.scroller as Element;
  //   scroller.scroll();
  // },
});
