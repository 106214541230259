
import Vue from "vue";
import VueTypes from "vue-types";
import { scrollToEnd } from "../utils";

export default Vue.extend({
  name: "ScrollWrap",

  data() {
    return {};
  },
  props: {
    outlined: VueTypes.bool.def(false),
  },
  methods: {
    scroll() {
      // call this by:
      // this.$refs.scroller.scroll()
      this.$nextTick(() => scrollToEnd(this.$parent.$el, "#scroller"));
    },
  },
});
