
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "AddBtn",

  data() {
    return {};
  },
  props: {
    size: VueTypes.string.def("small"),
    fab: VueTypes.bool.def(false),
    dark: VueTypes.bool.def(false),
  },
});
