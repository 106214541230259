
import { SelectItem } from "@/types";
import Vue from "vue";

export default Vue.extend({
  name: "ImportModal",

  data() {
    return {
      selected: undefined,
    };
  },
  computed: {
    modal(): any {
      return this.$store.getters.getImportDialog;
    },
    active: {
      get(): boolean {
        return this.modal.active;
      },
      set(val: boolean) {
        this.$store.commit("setImportDialogActive", val);
      },
    },
    items(): SelectItem[] {
      return this.modal.items;
    },
    action(): any {
      return this.modal.action;
    },
    args(): any {
      return this.modal.args;
    },
    title(): string {
      return this.modal.title;
    },
  },
  methods: {
    runAction() {
      this.action(this.selected, this.args);
      this.close();
    },
    close() {
      this.active = false;
      this.clear();
    },
    clear() {
      this.selected = null;
    },
  },
});
