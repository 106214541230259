
import { locales } from "@/utils";
import Vue from "vue";
import VueTypes from "vue-types";
import Tooltip from "@/components/Tooltip.vue";
export default Vue.extend({
  name: "LocaleComp",

  data() {
    return {
      manual: false,
    };
  },
  components: {
    Tooltip,
  },
  props: {
    active: VueTypes.bool.def(true),
    locale: VueTypes.string.def(""),
  },
  methods: {},
  computed: {
    locales(): string[] {
      return locales || [];
    },
    obj: {
      get(): string | null {
        return this.locale;
      },
      set(locale: string | null) {
        // console.log(locale);
        this.$emit("changed", { locale: locale || "" });
      },
    },
  },
});
