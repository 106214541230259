
import Vue from "vue";
import VueTypes from "vue-types";

import {
  actionIcon,
  actionColor,
  stepIcon,
  statusFailColor,
  stepColor,
  timeFormatter,
  scrollToID,
} from "../utils";
import Tooltip from "@/components/Tooltip.vue";
// import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  name: "time-line-result",
  data() {
    return {
      prev: false,
    };
  },
  props: {
    result: VueTypes.any,
    pos: VueTypes.any,
    routineParent: VueTypes.bool.def(false),
  },
  methods: {
    timeFormatter,
    view(): void {
      if (this.isRoutine) {
        return;
      }
      if (this.active) {
        this.$store.commit("Results/setCurrentResultView");
        this.$store.commit("Plans/setPlanCurrentStep");
      } else {
        this.$store.commit("Plans/setPlanCurrentStep");
        this.$store.commit("Results/setCurrentResultView", this.result);
        // if (this.result.testId) {
        //   this.$store.commit("Plans/setPlanCurrentStep", this.result.testId);
        // }
      }
    },
    openImage(src: string) {
      const { innerHeight, innerWidth } = window;
      const width = Math.min(this.imgMeta.width, innerWidth * 0.8);
      const res = width / this.imgMeta.width;
      const height = Math.min(this.imgMeta.height * res, innerHeight * 0.9);
      this.$store.commit("setSlidesOptions", {
        height,
        width,
      });
      this.$store.commit("setSlides", [src]);
    },
    openResponse(resp: any, type = "html") {
      this.$store.commit("setResponse", resp);
      this.$store.commit("setResponseOptions", { type });
    },
  },
  computed: {
    active(): boolean {
      const activeRes = this.$store.getters["Results/getActiveResult"](
        this.result.id
      );
      const activeTest = this.$store.getters["Plans/getActiveStep"](
        this.result.testId
      );
      return activeRes || activeTest;
    },
    stepWidth():string{
      return this.active ? '600px' : '500px'
    },
    last(): string | undefined {
      return this.$store.getters["Plans/getLastResult"];
    },
    isRoutine(): boolean {
      return this.result.ref ? true : false;
    },
    imgMeta(): any {
      const meta: any = {};
      if (this.result?.image) {
        var img = new Image();
        img.src = this.result?.image;
        const { height, width } = img;
        meta.height = height;
        meta.width = width;
      }
      return meta;
    },
    actionType(): string {
      return this.result?.actionType;
    },
    actionMethod(): string {
      return this.result?.action;
    },
    actionColor(): string {
      return actionColor[this.actionType];
    },
    actionIcon(): string {
      return actionIcon[this.actionMethod];
    },
    stepType(): number {
      return this.result?.type;
    },
    stepColor(): string {
      return stepColor[this.stepType];
    },
    stepBorderColor(): string {
      return this.resultStatus ? stepColor[this.stepType] : statusFailColor;
    },
    resultStatus(): boolean {
      return this.result?.success;
    },
    stepIcon(): string {
      return stepIcon[this.stepType];
    },
  },
  mounted() {
    // console.log(this.result.id, this.last);
    setTimeout(() => {
      if (!this.result.children?.length) {
        scrollToID(this.result.id);
      }
    }, 500);
  },
  components: { Tooltip },
});
