
import Vue from "vue";
import ListTable from "../components/listTable/ListTable.vue";
import AddBtn from "../components/buttons/AddBtn.vue";
import FilterBar from "../components/Filter/FilterBar.vue";
import {
  DefaultActionRun,
  DefaultActionEdit,
  DefaultActionDuplicate,
  DefaultActionDelete,
  DefaultFilterObject,
} from "../defaults";
import { DataObj, Msg, Pagination, Plan, PlanType } from "../types";
import { timeFormatter, updateObj } from "@/utils";
import config from "@/config";
import { v4 } from "uuid";

export default Vue.extend({
  name: "RoutinesView",
  components: {
    ListTable,
    AddBtn,
    FilterBar,
  },
  data() {
    return {
      headers: [
        {
          text: "Routine Name",
          align: "start",
          filterable: false,
          value: "name",
          type: "text",
          copy: true,
          to: (item: Plan): string => {
            return `${this.$route.path}/edit/${item._id}`;
          },
          tooltip: (item: Plan): string => {
            return `Edit '${item}'`;
          },
        },
        // { text: "Version", value: "version", type: "text" },
        // { text: "Description", value: "desc", type: "text" },
        { text: "Health", value: "status", type: "status" },
        { text: "Last Initiator", value: "lastInitiator", type: "initiator" },
        // { text: "Last Duration", value: "lastDuration", type: "duration" },
        { text: "Avg. Duration", value: "avgDuration", type: "duration" },
        // { text: "Label", value: "label", type: "text" },
        { text: "Last Update", value: "updated", type: "date" },
        // { text: "Created", value: "created", type: "date" },
        { text: "Active", value: "active", type: "active" },
      ],
      actions: [
        {
          ...DefaultActionRun,
          method: async (item: DataObj) => {
            const data = await this.$store.dispatch(`Plans/run`, {
              id: item._id,
            });
            const msg: Msg = {
              id: v4(),
              icon: "flask",
              type: "success",
              color: "indigo",
              ts: new Date().getTime(),
              content: `Test Routine: <strong>"${
                data.name
              }"</strong> was running for <strong>${timeFormatter(
                data.duration
              )}</strong> was successfully completed.`,
              dismissible: true,
              timeout: 5000,
              notify: true,
            };
            msg.content += `<strong> <a class="link" href="${
              config.DOMAIN
            }/results/view/${data._id || data._id}"> View results</a></strong>`;
            this.$store.dispatch("popMsg", msg, { root: true });
            this.$store.dispatch(
              "Results/fetchAllResults",
              { ...DefaultFilterObject, date: {} },
              { root: true }
            );
          },
        },
        DefaultActionEdit,
        DefaultActionDuplicate,
        DefaultActionDelete,
      ],
      filter: {
        ...DefaultFilterObject,
        sort: { name: 1 },
      },
      sortOptions: [
        { text: "Created", value: "created" },
        { text: "Name", value: "name" },
        { text: "Last Update", value: "updated" },
        { text: "Initiator", value: "lastInitiator" },
        { text: "Status", value: "status" },
        // { text: "Label", value: "label" },
      ],
      searchOptions: [{ text: "Name", value: "name" }],
      options: {},
      loading: true,
    };
  },
  methods: {
    async fetchList() {
      this.loading = true;
      this.$store.commit("Plans/setFilter", this.filter);
      await this.$store.dispatch("Plans/fetchAllPlans");
      this.loading = false;
    },
    pageChange(val: number): void {
      const { limit } = this.filter;
      const skip = (val - 1) * limit;
      this.filter.skip = skip;
      this.changed();
    },
    changed(data: unknown | null) {
      if (data) {
        this.filter = updateObj(this.filter, data);
      }
      this.fetchList();
    },
  },
  computed: {
    items() {
      return this.$store.getters["Plans/getPlans"];
    },
    total() {
      return this.$store.getters["Plans/getPlansTotal"];
    },
    pagination(): Pagination {
      const { limit } = this.filter;
      const count = this.total;
      const total = Math.ceil(count / limit);
      return { total, current: 1, visible: 7, count, limit };
    },
  },
  created() {
    this.filter = { ...this.$store.getters["Plans/getFilter"], type: PlanType.ROUTINE };
    this.changed();
  },
});
