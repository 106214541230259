import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { SchedulerState } from '@/types'

export default {
  namespaced: true,
  state: (): SchedulerState => {
    return {
      schedule: {
        currentSchedule: {},
        list: [],
        total: 0
      },
    }
  },
  actions,
  mutations,
  getters
}
