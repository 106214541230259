
import { Msg } from "@/types";
import Vue from "vue";

export default Vue.extend({
  name: "MessageComp",

  data() {
    return {};
  },
  methods: {
    dismiss(id: string) {
      return this.$store.commit("rmMsg", id);
    },
  },
  computed: {
    active(): boolean {
      return this.$store.getters.getMessagesActive;
    },
    messages(): Msg[] {
      return this.$store.getters.getMessages;
    },
  },
});
