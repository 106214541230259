
import Vue from "vue";
import VueTypes from "vue-types";

import {
  actionIcon,
  actionColor,
  statusFailColor,
  statusSuccessColor,
  timeFormatter,
  stepColor,
  stepIcon,
  cloneObj,
  reId,
  scrollToID,
} from "../utils";
import Tooltip from "@/components/Tooltip.vue";
import { Plan, StepType, SelectItem, Step, ResultData } from "../types";
import { DefaultStep } from "../defaults";
import StepView from "./Step.vue";
import { v4 } from "uuid";
import ActionsLoader from "./listTable/actions/ActionsLoader.vue";

export default Vue.extend({
  components: { Tooltip, StepView, ActionsLoader },
  name: "time-line-test",

  data() {
    return {
      position: [],
      expand: true,
      prev: false,
      overPos: "",
      timer: 0,
      // deactivated: false
    };
  },
  props: {
    test: VueTypes.any,
    pos: VueTypes.any,
    draggable: VueTypes.bool.def(true),
    last: VueTypes.bool.def(false),
    inactive: VueTypes.bool.def(false),
    routineParent: VueTypes.bool.def(false),
  },
  methods: {
    timeFormatter,
    addStep(type = StepType.UI) {
      this.resetDebugger();
      const step: Step = cloneObj(DefaultStep);
      step.id = v4();
      step.type = type;
      step.action = {
        method: type ? "click" : "get",
        selector: "",
        type: type ? "user" : "api",
      };
      this.addChild(step);
    },
    addChild(step: Step, selectStep = true) {
      this.step.children.push(step);
      if (selectStep) {
        this.$store.commit("Plans/setPlanCurrentStep", step.id);
      }
      setTimeout(() => {
        scrollToID(step.id);
      }, 100);
    },
    openImportDialog() {
      this.$store.commit("setImportDialog", {
        active: true,
        items: this.routines,
        title: "Select Test Routine to import",
        action: this.addRoutine,
      });
    },
    async addRoutine(item: SelectItem) {
      this.resetDebugger();
      const routine: Plan = await this.$store.dispatch(
        "Plans/fetchPlan",
        item.value
      );
      const step: Step = cloneObj(DefaultStep);
      step.id = v4();
      step.type = StepType.ROUTINE;
      step.name = routine.name;
      step.ref = routine._id;
      step.children = routine.steps;
      this.addChild(step, false);
    },
    editTest() {
      if (this.isRoutine) {
        return;
      }
      // this.expand = true;
      if (this.active) {
        this.$store.commit("Plans/setPlanCurrentStep");
        this.$store.commit("Results/setCurrentResultView");
      } else {
        this.$store.commit("Results/setCurrentResultView");
        this.$store.commit("Plans/setPlanCurrentStep", this.step.id);
      }
      // this.$store.commit("Plans/setPlanDisplayDebugger", false);
      // this.$store.commit("Plans/setPlanCurrentDebugger");
    },
    duplicate() {
      this.$store.commit("Plans/setPlanDisplayDebugger", false);
      this.$store.commit("Plans/setPlanCurrentDebugger");
      this.$store.commit("Plans/setPlanCurrentStep");
      const step = reId(cloneObj(this.step));

      this.$emit("duplicate", step);
    },
    deleteStep() {
      this.$store.commit("Plans/setPlanDisplayDebugger", false);
      this.$store.commit("Plans/setPlanCurrentDebugger");
      const title = `Delete "${this.step.name}" Step`;
      const content = `Are you sure you with to delete <strong>"${
        this.step.name
      }"</strong>
      ${
        this.step?.children && this.step.children.length
          ? " and it's <strong>children</strong>"
          : ""
      }?`;
      const dialog = {
        active: true,
        content,
        action: this.deleteSelectedStep,
        title,
      };
      this.$store.commit("setDeleteDialog", dialog);
    },
    deleteSelectedStep() {
      this.$store.commit("Plans/deleteStep", this.step.id);
    },
    deleteChildren() {
      this.$store.commit("Plans/setPlanDisplayDebugger", false);
      this.$store.commit("Plans/setPlanCurrentDebugger");
      const title = `Delete "${this.step.name}" Children`;
      const content = `Are you sure you with to delete <strong>"${this.step.name}'s" children</strong>?`;
      const dialog = {
        active: true,
        content,
        action: this.deleteSelectedChildren,
        title,
      };
      this.$store.commit("setDeleteDialog", dialog);
    },
    deleteSelectedChildren() {
      this.step.children = [];
    },
    isLast(i: number) {
      return i == this.step.children.length - 1;
    },
    fetchSteps(e) {
      const val = e.target.value;
      this.step.name = val;
      // console.log("input", val);
      // this.$store.commit("Plans/checkCurrentPlanChange");
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = 0;
      }
      this.timer = setTimeout(() => {
        this.$store.dispatch("Plans/fetchSteps", val);
      }, 500);
    },
    loadStep(step: any) {
      // console.log(step);
      if (step?.name) {
        const { action, group, hash, name, type } = step;
        this.step.action = action;
        this.step.group = group;
        this.step.hash = hash;
        this.step.name = name;
        this.step.type = type;
      }
    },
    // activateRecursive(step: Step, status: boolean) {
    //   if (status !== step.inactive) {
    //     step.inactive = status;
    //     if (step.children.length) {
    //       for (const child of step.children) {
    //         this.activateRecursive(child, status);
    //       }
    //     }
    //   }
    // },
    activateToggle() {
      // this.deactivated = !this.deactivated;
      this.step.inactive = !this.step.inactive;
      // this.activateRecursive(this.step, this.deactivated);
      if (this.active) {
        this.$store.commit("Plans/setPlanCurrentStep");
      }
    },
    expandToggle() {
      this.expand = !this.expand;
    },
    drag() {
      if (this.active) {
        return;
      }
      this.expand = false;
      this.$store.commit("Plans/setCurrentDragStep", {
        step: { ...this.step },
        pos: this.pos,
      });
      // console.log("drag", this.currentDragStep?.step.id, this.step.id);
    },
    over(before = true, child = false) {
      if (!this.dragged && this.currentDragStep && !this.deactivated) {
        if (before) {
          this.overPos = "back";
        } else {
          this.overPos = "end";
        }
        if (child) {
          this.overPos = "child";
        }
        this.expand = true;
        // console.log("over", this.step.id, before);
        this.$store.commit("Plans/setCurrentDragOverStep", {
          id: this.step.id,
          pos: this.pos,
          before,
          child,
        });
      }
    },
    out() {
      this.$store.commit("Plans/setCurrentDragOverStep");
    },
    drop() {
      if (
        this.currentDragStep &&
        this.$store.getters["Plans/getCurrentDragOverStep"]
      ) {
        // console.log(
        //   "drop",
        //   this.$store.getters["Plans/getCurrentDragOverStep"]?.id,
        //   this.step.id
        // );
        this.resetDebugger();
        this.$store.commit("Plans/setDragAndDrop");
      }
      this.$store.commit("Plans/setCurrentDragStep");
      this.expand = true;
    },
    resetDebugger() {
      this.$store.commit("Plans/setPlanDisplayDebugger", false);
      this.$store.commit("Plans/setPlanCurrentStep");
      this.$store.commit("Plans/setPlanCurrentDebugger");
      this.$store.commit("Results/setCurrentResultView");
    },
    routeToRoutine() {
      this.$router.replace(this.routineLink);
      this.$router.go();
    },
    openImage(src: string) {
      const { innerHeight, innerWidth } = window;
      const width = Math.min(this.imgMeta.width, innerWidth * 0.8);
      const res = width / this.imgMeta.width;
      const height = Math.min(this.imgMeta.height * res, innerHeight * 0.9);
      this.$store.commit("setSlidesOptions", {
        height,
        width,
      });
      this.$store.commit("setSlides", [src]);
    },
    openResponse(resp: any, type = "html") {
      this.$store.commit("setResponse", resp);
      this.$store.commit("setResponseOptions", { type });
    },
  },
  computed: {
    step: {
      get() {
        return this.test;
      },
      set(val: unknown) {
        // const step = updateObj()
        console.log(val);

        // this.$emit("input", val);
      },
    },
    debuggerRunning(): boolean {
      return this.$store.getters["Plans/getDebuggerRunning"];
    },
    result(): ResultData {
      const result = this.$store.getters["Plans/getPlanCurrentDebuggerResult"](
        this.step.id
      );
      return result;
    },
    stepBorderColor(): string {
      return this.resultStatus ? statusSuccessColor : statusFailColor;
    },
    stepWidth(): string {
      return this.active ? "600px" : "500px";
    },
    resultStatus(): boolean {
      return this.result?.success;
    },
    imgMeta(): any {
      const meta: any = {};
      if (this.result?.image) {
        var img = new Image();
        img.src = this.result?.image;
        const { height, width } = img;
        meta.height = height;
        meta.width = width;
      }
      return meta;
    },
    steps(): Step[] {
      return this.$store.getters["Plans/getSteps"];
    },
    active(): boolean {
      const activeStep = this.$store.getters["Plans/getActiveStep"](
        this.step.id
      );
      const activeRes = this.$store.getters["Results/getActiveResultByTestID"](
        this.step.id
      );
      return activeStep || activeRes;
    },
    moveable() {
      return this.draggable && !this.active;
    },
    dragged(): boolean {
      return this.step.id === this.currentDragStep?.step?.id;
    },
    currentDragStep(): any | null {
      return this.$store.getters["Plans/getCurrentDragStep"];
    },
    currentDragStepOver(): any | null {
      return this.$store.getters["Plans/getCurrentDragOverStep"];
    },
    draggedOver(): boolean {
      return this.currentDragStepOver?.id === this.step.id;
    },
    deactivated(): boolean {
      return this.inactive || this.step.inactive;
    },
    actionType(): string {
      return this.step?.action?.type;
    },
    actionMethod(): string {
      return this.step?.action?.method;
    },
    actionColor(): string {
      return actionColor[this.actionType];
    },
    actionIcon(): string {
      return actionIcon[this.actionMethod];
    },
    stepType(): number {
      return this.step?.type;
    },
    stepColor(): string {
      return stepColor[this.stepType];
    },
    stepIcon(): string {
      return stepIcon[this.stepType];
    },
    lastStep(): boolean {
      return this.last;
    },
    allowChildren(): boolean {
      return this.pos?.length < 4 && !this.isRoutine;
    },
    routines(): SelectItem[] {
      return this.$store.getters["Plans/getRoutines"];
    },
    isRoutine(): boolean {
      return this.stepType === StepType.ROUTINE;
    },
    routineLink(): string {
      return `/routines/edit/${this.step.ref}#routine`;
    },
    importDialog: {
      get(): boolean {
        return this.$store.getters.getImportDialogActive;
      },
      set(val: boolean) {
        this.$store.commit("setImportDialogActive", val);
      },
    },
    lastResult(): string | undefined {
      return this.$store.getters["Plans/getLastResult"];
    },
  },
  watch: {
    debuggerRunning(running: boolean) {
      if (running) {
        this.expand = true;
      }
    },
    lastResult(lastId: string | undefined) {
      setTimeout(() => {
        if (lastId) {
          scrollToID(lastId);
        }
      }, 50);
    },
  },
  mounted() {
    if (this.isRoutine) {
      this.expand = false;
    }
  },
  updated() {
    this.$store.commit("Plans/checkCurrentPlanChange");
  },
});
