
import config from "@/config";
import { v4 } from "uuid";
import Vue from "vue";
import VueTypes from "vue-types";
import { Msg, SelectItem, User } from "../../types";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  name: "UserBtn",
  data() {
    return {
      active: false,
    };
  },
  props: {
    size: VueTypes.string.def("small"),
    round: VueTypes.bool.def(false),
  },
  methods: {
    logout() {
      this.$store.dispatch("Users/logout");
      this.$router.push("/auth");
      const msg: Msg = {
        id: v4(),
        icon: "account",
        type: "info",
        content: `See you soon :)`,
        dismissible: true,
        timeout: 2000,
      };
      this.$store.dispatch("popMsg", msg);
    },
    async switchAccount(id: string) {
      this.active = false;
      this.$store.commit("setGlobalLoader", true);
      await this.$store.dispatch("Users/refreshUser", id);
      this.$router.go();
      // console.log(req, id);
    },
  },
  computed: {
    user(): User {
      return this.$store.getters["Users/getUser"];
    },
    role(): number {
      return this.$store.getters["Users/getRole"];
    },
    version(): string {
      return config.VERSION;
    },
    groups(): SelectItem[] {
      const groups = this.user.groups?.map(
        (group: { _id: string; name: string }) => {
          return {
            text: group.name,
            value: group._id,
          };
        }
      );
      return groups || [];
    },
    initials(): string {
      let init = "";
      if (this.user.name) {
        const split = this.user.name.split(" ");
        const first = split[0];
        const last = split[1];
        init = first.split("")[0] + last.split("")[0];
      }
      return init;
    },
  },
  components: { Tooltip },
});
