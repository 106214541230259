import { DefaultScheduler } from "@/defaults"
import { Scheduler, SchedulerState } from "@/types"
import { filterRecursive } from "@/utils"
import { v4 } from "uuid"

export default {
  // Scheduler
  setCurrentSchedule: (state: SchedulerState, schedule: Scheduler = DefaultScheduler): void => {
    if (!schedule._id) {
      schedule.id = v4()
    }
    state.schedule.currentSchedule = schedule
  },
  deleteSchedule: (state: SchedulerState, id: string): void => {
    state.schedule.list = filterRecursive(state.schedule.list, id)
  },
}
