
import Vue from "vue";

import WaitFor from "./WaitFor.vue";
import ScrollWrap from "./ScrollWrap.vue";
import Tooltip from "@/components/Tooltip.vue";
import Env from "./Env.vue";
import { ListObj, SelectItem, Step, StepType } from "@/types";
import VueTypes from "vue-types";
import {
  objNotEmpty,
  parseEnv,
  actionColor,
  actionIcon,
  actionTabList,
  cloneObj,
  actionTypes,
  actionsList,
  keyboardKeys,
  numberSigns,
  payloadTypes,
  fields,
} from "@/utils";
import RequestHeaders from "./RequestHeaders.vue";
import AddList from "./AddList.vue";

export default Vue.extend({
  name: "StepComp",

  components: {
    WaitFor,
    ScrollWrap,
    Tooltip,
    Env,
    RequestHeaders,
    AddList,
  },
  data() {
    return {
      sheet: false,
      actionTypes,
      actionsList,
      keyboardKeys,
      numberSigns,
      payloadTypes,
      actionTabList,
      fields,
      tab: "action",
      StepType,
    };
  },
  props: {
    value: VueTypes.any.def({}),
    disabled: VueTypes.bool.def(false),
  },
  methods: {
    scroll() {
      // activate scrolling
      const scroller = this.$refs.scroller as Element;
      scroller.scroll();
    },
    switchDataType() {
      if (!Array.isArray(this.step.action.data)) {
        this.step.action.data = JSON.parse(this.step.action.data);
      } else {
        this.step.action.data = JSON.stringify(this.step.action.data);
      }
    },
    addToForm() {
      if (!this.step.action.data || !Array.isArray(this.step.action.data)) {
        this.step.action.data = [];
      }
      this.step.action.data.push({
        name: ["new_key_" + (this.step.action.data.length + 1)],
        value: "",
      });
    },
    fixObject() {
      if (this.actions?.length) {
        for (const action of this.actions) {
          const { value } = action;
          switch (value) {
            case "url":
              action.disabled = false;
              break;

            default:
              action.disabled = this.root;
              break;
          }
        }
      }
      if (this.actionTypes?.length) {
        for (const at of this.actionTypes) {
          const { value } = at;
          switch (value) {
            case "misc":
              at.disabled = false;
              break;

            default:
              at.disabled = this.root;
              break;
          }
        }
      }
      // if (!this.step.action?.type) {
      //   this.step.action.type = this.step.action?.method
      //     ? this.getActionType(this.step.action?.method)
      //     : "misc";
      //   // console.log(this.step.action?.type);
      // }
      if (!this.step.waitFor) {
        this.step.waitFor = {};
      }
      // if (!this.step.waitAfter) {
      //   this.step.waitAfter = {};
      // }
      if (!this.step.env) {
        this.step.env = [];
      }
      // if (!this.step.device) {
      //   this.step.device = {};
      // }
    },
    dataChanged(newData: any | undefined = undefined) {
      if (newData) {
        const step: any = this.step;
        for (const key in newData) {
          step[key] = cloneObj(newData[key]);
        }
        // console.log(step);
      }
      this.$store.commit("Plans/checkCurrentPlanChange");
      // this.scroll();
    },
    getActionType(method: string): string | undefined {
      for (const actionType in this.actionsList) {
        const exists: SelectItem[] = this.actionsList[actionType].filter(
          (a: SelectItem) => method === a.value
        );
        if (exists.length) {
          return actionType;
        }
      }
      return undefined;
    },
    setAction() {
      this.step.action.method = this.actions.filter(
        (a: SelectItem) => a.text
      )[0].value;
    },
    parseEnv,
    getIcon(method: string): string {
      return actionIcon[method];
    },
    // TODO
    // fetchSelector() {
    //   const data = {
    //     stepId: this.step.id
    //   };
    //   const event = new CustomEvent("fetchSelector", { detail: data });
    //   document.dispatchEvent(event);
    // }
  },
  computed: {
    prettyFormat(): string | boolean {
      if (this.step.action.data?.length) {
        try {
          // try to parse
          JSON.parse(this.step.action.data);
        } catch (e) {
          return String(e.message);
        }
      }
      return false;
    },
    actions(): SelectItem[] {
      return this.actionsList[this.step.type][this.actionType];
    },
    actionTypesList(): SelectItem[] {
      const actionTypeFields = {
        [StepType.UI]: ["misc", "user", "assert"],
        [StepType.API]: ["request"], // TODO , "assert"],
      };

      return actionTypes.filter((a: SelectItem) =>
        actionTypeFields[this.step.type].includes(a.value)
      );
    },
    tabs(): any[] {
      const actionTabs = {
        [StepType.UI]: ["action", "wait", "general"],
        [StepType.API]: ["action", "headers"],
      };
      return actionTabList.filter((a: SelectItem) =>
        actionTabs[this.step.type].includes(a.value)
      );
    },
    actionType: {
      get(): string {
        return this.step?.action?.type;
      },
      set(type: string) {
        this.step.action.type = type;
      },
    },
    actionMethod(): string {
      return this.step?.action?.method || "screenshot";
    },
    actionColor(): string {
      return actionColor[this.actionType];
    },
    actionIcon(): string {
      return actionIcon[this.actionMethod];
    },
    extension(): boolean {
      return this.$store.getters.getExtension;
    },
    deactivated(): boolean {
      return this.disabled;
    },
    activeWait: {
      get(): boolean {
        return objNotEmpty(this.step.waitFor);
      },
      set(val: boolean) {
        let obj = {};
        if (val) {
          if (this.step.action?.selector) {
            obj = {
              method: "element",
              selector: this.step.action?.selector,
              mandatory: true,
              state: "visible",
              timeout: 30,
            };
          } else if (this.step.action.method == "url") {
            obj = { method: "content", time: 1, mandatory: false, timeout: 30 };
          } else {
            obj = { method: "sleep", time: 1, mandatory: false, timeout: 30 };
          }
        }
        this.step.waitFor = obj;
      },
    },
    // activeAfterWait: {
    //   get(): boolean {
    //     return objNotEmpty(this.step.waitAfter);
    //   },
    //   set(val: boolean) {
    //     let obj = {};
    //     if (val) {
    //       if (this.step.action.method == "url") {
    //         obj = { method: "content", time: 1, mandatory: false };
    //       } else {
    //         obj = { method: "sleep", time: 1, mandatory: false };
    //       }
    //     }
    //     this.step.waitAfter = obj;
    //   },
    // },
    activeEnv: {
      get(): boolean {
        return this.step.env?.length > 0;
      },
      set(val: boolean) {
        const env = [];
        if (val) {
          env.push({ name: "", type: "text", val: "" });
        }
        this.step.env = env;
      },
    },
    activeHeaders: {
      get(): boolean {
        return this.step?.headers && objNotEmpty(this.step.headers);
      },
      set(val: boolean) {
        let obj = {};
        if (val) {
          obj = this.step.headers || {};
        }
        this.step.headers = obj;
      },
    },
    step: {
      get() {
        return this.value;
      },
      set(val: Step) {
        // const step = updateObj()
        console.log(val);

        this.$emit("input", val);
      },
    },
    globalEnv(): ListObj[] {
      return this.$store.getters["Plans/getCurrentPlanGlobalEnv"];
    },
  },
  created() {
    // this.step = this.test;
    // this.fixObject();
    // if (!this.step.action.type) {
    //   this.step.action.type = this.getActionType(this.step.action.method);
    // }
  },
  updated() {
    // this.$emit("changed", this.step);
    this.$store.commit("Plans/checkCurrentPlanChange");
  },
  // destroyed() {
  //   this.closeDisplay();
  // }
});
