import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { ResultsState } from '@/types'
import { DefaultFilterObject } from '@/defaults'

export default {
  namespaced: true,
  state: (): ResultsState => {
    return {
      result: {
        currentResult: {},
        currentResultView: {},
        list: [],
        total: 0
      },
      filter: {
        ...DefaultFilterObject,
        date: {}
      }
    }
  },
  actions,
  mutations,
  getters
}
