import { DefaultUser } from "@/defaults"
import { User, UsersState } from "@/types"
import { filterRecursive } from "@/utils"
import { v4 } from "uuid"

export default {
  setUser: (state: UsersState, user: User = DefaultUser): void => {
    if (!user._id && !user.id) {
      user.id = v4()
    }
    state.user = { ...user }
  },
  setUserLabels: (state: UsersState, labels: string[]): void => {
    state.labels = labels
  },
  // clearGroups: (state: UsersState): void => {
  //   state.groups = []
  // },
  setCurrentUser: (state: UsersState, user: User = DefaultUser): void => {
    user.groupName = state.user.groupName
    user.group = state.user.group
    state.users.currentUser = { ...user }
  },
  // setCurrentUserGroup: (state: UsersState, option: { groupName: string, group: string }): void => {
  //   const { groupName, group } = option
  //   state.users.currentUser.groupName = groupName
  //   state.users.currentUser.group = group
  // },
  deleteUser: (state: UsersState, id: string): void => {
    state.users.list = filterRecursive(state.users.list, id)
  },
}
