
import Vue from "vue";
import VueTypes from "vue-types";
import { highlightJSON } from "@/utils";

export default Vue.extend({
  name: "JsonPreview",

  data() {
    return {};
  },
  props: {
    obj: VueTypes.any,
  },
  methods: {
    highlightJSON,
  },
  computed: {
    result(): string {
      return highlightJSON(this.obj);
    },
  },
});
