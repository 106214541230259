import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { PlansState } from '@/types'
import { DefaultFilterObject } from '@/defaults'

export default {
  namespaced: true,
  state: (): PlansState => {
    return {
      plan: {
        displayStep: false,
        currentStep: '',
        currentPlan: {},
        currentPlanSnapshot: '',
        displayDebugger: false,
        currentDebugger: {},
        currentDebuggerView: {},
        list: [],
        steps: [],
        total: 0,
        currentDragStep: null,
        currentDragOverStep: null,
        changed: false,
      },
      routines: [],
      authMethods: [],
      devices: [],
      countries: [],
      filter: {
        ...DefaultFilterObject,
        sort: { name: 1 },
        type: 0
      }
    }
  },
  actions,
  mutations,
  getters
}
