
import Vue from "vue";

import BackBtn from "../components/buttons/BackBtn.vue";
import Cron from "../components/Cron.vue";
// import Title from "../components/Title.vue";
import DragList from "../components/DragList.vue";
import { Plan, Scheduler, SelectItem, Suite } from "@/types";

export default Vue.extend({
  name: "SchedulerView",
  components: {
    BackBtn,
    Cron,
    DragList,
  },
  data() {
    return {
      rules: {
        text: [
          (val: string) => (val && val.length) || "This field is required",
        ],
      },
      selected: "plans",
    };
  },
  computed: {
    valid(): boolean {
      return (
        this.scheduler?.name?.length > 0 &&
        this.scheduler?.cron?.length > 0 &&
        (this.scheduler?.plans?.length > 0 ||
          this.scheduler?.suites?.length > 0)
      );
    },
    // selected() {
    //   return this.suitesToggle ? "suites" : "plans";
    // },
    scheduler: {
      get(): Scheduler {
        const scheduler = this.$store.getters["Scheduler/getCurrentSchedule"];
        if (scheduler?.suites?.length) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.selected = "suites";
        }
        this.$store.commit(
          "setTitle",
          scheduler._id ? `Edit '${scheduler.name}'` : "Add New Scheduler"
        );
        return this.$store.getters["Scheduler/getCurrentSchedule"];
      },
      set(scheduler: Scheduler) {
        this.$store.commit("Scheduler/setCurrentSchedule", scheduler);
      },
    },
    selectedList: {
      get(): SelectItem[] {
        return this.scheduler[this.selected];
      },
      set(items: SelectItem[]) {
        this.scheduler[this.selected] = items;
      },
    },
    selectedIcon(): string {
      let icon = "package-variant";
      switch (this.selected) {
        case "plans":
          icon = "flask";
          break;
        case "suites":
          icon = "package-variant";
          break;

        default:
          break;
      }
      return icon;
    },

    items(): SelectItem {
      // TODO Suites support
      const funcText = { suites: "Suites/getSuites", plans: "Plans/getPlans" };
      const items = this.$store.getters[funcText[this.selected]];

      return items.map((item: Plan | Suite) => {
        return {
          text: item.name,
          value: item._id,
          icon: this.selectedIcon,
        };
      });
    },
  },
  methods: {
    removePlan(index: number) {
      this.scheduler.plans.splice(index, 1);
    },
    updateList(list: SelectItem) {
      console.log(list, this.selected);

      this.scheduler[this.selected] = list;
    },
    updateCron(obj: { cron: string; timePhrase: string }) {
      const { cron, timePhrase } = obj;
      this.scheduler.cron = cron;
      this.scheduler.timePhrase = timePhrase;
    },
    schedulerChanged() {
      this.$store.commit("Scheduler/setCurrentSchedule", this.scheduler);
    },
    save() {
      // modify plans list to string[]
      const items = [
        ...this.scheduler[this.selected].map((s: SelectItem) => s.value),
      ];
      this.scheduler.plans = [];
      this.scheduler.suites = [];
      this.scheduler[this.selected] = items;
      this.$store.dispatch("Scheduler/saveSchedule", this.scheduler);
      this.$store.commit("Scheduler/setCurrentSchedule");
      this.$router.push("/scheduler");
    },
  },
  // watch: {
  //   suitesToggle(val: boolean) {
  //     console.log(val);
  //     if (val) {
  //       this.scheduler.plans = [];
  //     } else {
  //       this.scheduler.suites = [];
  //     }
  //   },
  // },
  async mounted() {
    this.$store.commit("setGlobalLoader", true);
    // TODO filter
    // const filter = {};
    this.$store.commit("Scheduler/setCurrentSchedule");
    this.$store.dispatch("Plans/fetchAllPlans");
    this.$store.dispatch("Suites/fetchSuites");
    const { id } = this.$route.params;
    if (id) {
      await this.$store.dispatch("Scheduler/fetchCurrentSchedule", id);
    }
    this.$store.commit("setGlobalLoader");
  },
  destroyed() {
    this.$store.commit("Scheduler/setCurrentSchedule");
  },
});
