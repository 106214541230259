
import Vue from "vue";
import Loader from "./components/Loader.vue";
import Message from "./components/Message.vue";
import SliderDialog from "./components/dialogs/SliderDialog.vue";
import ResponseDialog from "./components/dialogs/ResponseDialog.vue";
import DeleteDialog from "./components/dialogs/DeleteDialog.vue";

export default Vue.extend({
  name: "App",

  components: {
    Loader,
    Message,
    SliderDialog,
    ResponseDialog,
    DeleteDialog
},
  computed: {
    loader() {
      return this.$store.getters.getGlobalLoader;
    },
  },
});
