
import Vue from "vue";
import VueTypes from "vue-types";
import TimeLineResult from "./TimeLineResult.vue";
import ResultStats from "./ResultStats.vue";
import { ResultData } from "@/types";

export default Vue.extend({
  components: { TimeLineResult, ResultStats },
  name: "ResultLayout",

  data() {
    return {};
  },
  props: {
    height: VueTypes.number.def(75),
    result: VueTypes.any.def({}),
    debugging: VueTypes.bool.def(false),
    loading: VueTypes.bool.def(false),
  },
  methods: {
    parseError(err: string) {
      if (!err) {
        return { msg: "General error", log: undefined };
      }
      const split = err.split(
        "=========================== logs ==========================="
      );
      return split.length
        ? { msg: split[0], log: split[1] }
        : { msg: err, log: undefined };
    },
    close() {
      this.$emit("close");
    },
  },
  computed: {
    viewCols() {
      return this.debugging ? null : 8;
    },
    results():ResultData[] {
      return this.result.results;
    },
  },
});
