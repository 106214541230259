
import Vue from "vue";
import Login from "../components/Login.vue";
import Register from "@/components/Register.vue";
import Slogen from "@/components/Slogen.vue";

export default Vue.extend({
  components: { Login, Register, Slogen },
  name: "AuthView",
});
