import { Result, ResultsState } from "@/types"

export default {
  // Results
  getResults: (state: ResultsState): Result[] => {
    return state.result.list
  },
  getResultsTotal: (state: ResultsState): number => {
    return state.result.total
  },
  getCurrentResult: (state: ResultsState): Result => {
    return state.result.currentResult
  },
  getCurrentResultView: (state: ResultsState): Result => {
    return state.result.currentResultView
  },
  getActiveResult: (_: ResultsState, getters: any): any => (id: string): boolean => {
    const current = getters.getCurrentResultView
    return current.id === id
  },
  getActiveResultByTestID: (_: ResultsState, getters: any): any => (testId: string): boolean => {
    const current = getters.getCurrentResultView
    return current.testId === testId
  },
  getFilter: (state: ResultsState): any => {
    return state.filter
  },
}
