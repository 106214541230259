import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '../store'

import Dev from '../views/Dev.vue'
import Dashboard from '../views/Dashboard.vue'
import Plans from '../views/Plans.vue'
import Results from '../views/Results.vue'
import Result from '../views/Result.vue'
import Plan from '../views/Plan.vue'
import Content from '../views/Content.vue'
import Scheduler from '../views/Scheduler.vue'
import Schedulers from '../views/Schedulers.vue'
import Users from '../views/Users.vue'
import User from '../views/User.vue'
import Groups from '../views/Groups.vue'
import Group from '../views/Group.vue'
import Keys from '../views/Keys.vue'
import Key from '../views/Key.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Pingr from '../views/Pingr.vue'
import Auth from '../views/Auth.vue'
// import Pingrs from '@/views/Pingrs.vue'
import Suites from '@/views/Suites.vue'
import Suite from '@/views/Suite.vue'
import KnowledgeCenter from '@/views/KnowledgeCenter.vue'
import Preferences from '@/views/Preferences.vue'
import InviteView from '@/views/Invite.vue'
import Routines from '@/views/Routines.vue'
import Templates from '@/views/Templates.vue'
import { RoleType, PlanType } from '@/types'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/auth',
    name: 'Auth',
    component: Auth,
    meta: {
    }
  },
  {
    path: '/invite/:token',
    name: 'Invite',
    component: InviteView,
    meta: {
    }
  },
  {
    path: '',
    name: 'Content',
    component: Content,
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          role: RoleType.USER,
          icon: 'dashboard'
        }
      },
      {
        path: '/plans',
        name: 'Test Cases',
        component: Plans,
        meta: {
          role: RoleType.USER,
          icon: 'plans'
        }
      },
      {
        path: '/plans/add',
        name: 'Add New Test Case',
        component: Plan,
        meta: {
          role: RoleType.USER,
          icon: 'plans',
          routeRoot: '/plans',
          type: PlanType.PLAN
        }
      },
      {
        path: '/plans/edit/:id',
        name: 'Edit Test Case',
        component: Plan,
        meta: {
          role: RoleType.USER,
          icon: 'plans',
          routeRoot: '/plans',
          type: PlanType.PLAN
        }
      },
      {
        path: '/routines',
        name: 'Test Routines',
        component: Routines,
        meta: {
          role: RoleType.USER,
          icon: 'routines'
        }
      },
      {
        path: '/routines/add',
        name: 'Add Test Routine',
        component: Plan,
        meta: {
          role: RoleType.USER,
          icon: 'routines',
          routeRoot: '/routines',
          type: PlanType.ROUTINE
        }
      },
      {
        path: '/routines/edit/:id',
        name: 'Edit Test Routine',
        component: Plan,
        meta: {
          role: RoleType.USER,
          icon: 'routines',
          routeRoot: '/routines',
          type: PlanType.ROUTINE
        }
      },
      {
        path: '/templates',
        name: 'Test Templates',
        component: Templates,
        meta: {
          role: RoleType.SUPER_ADMIN,
          icon: 'templates'
        }
      },
      {
        path: '/templates/add',
        name: 'Add New Template',
        component: Plan,
        meta: {
          role: RoleType.SUPER_ADMIN,
          icon: 'templates',
          routeRoot: '/templates',
          type: PlanType.TEMPLATE
        }
      },
      {
        path: '/templates/edit/:id',
        name: 'Edit Template',
        component: Plan,
        meta: {
          role: RoleType.SUPER_ADMIN,
          routeRoot: '/templates',
          icon: 'templates',
          type: PlanType.TEMPLATE
        }
      },
      {
        path: '/results',
        name: 'Test Results',
        component: Results,
        meta: {
          role: RoleType.USER,
          icon: 'results'
        }
      },
      {
        path: '/results/view/:id',
        name: 'View Result',
        component: Result,
        meta: {
          role: RoleType.USER,
          icon: 'results'
        }
      },
      {
        path: '/pingr',
        name: 'Pingr Service',
        component: Dev,
        meta: {
          role: RoleType.USER,
          icon: 'pingr'
        }
      },
      {
        path: '/pingr/add',
        name: 'Add Pingr',
        component: Pingr,
        meta: {
          role: RoleType.USER,
          icon: 'pingr'
        }
      },
      {
        path: '/pingr/edit/:id',
        name: 'Edit Pingr',
        component: Pingr,
        meta: {
          role: RoleType.USER,
          icon: 'pingr'
        }
      },
      {
        path: '/suites',
        name: 'Test Suites',
        component: Suites,
        meta: {
          role: RoleType.USER,
          icon: 'suites'
        }
      },
      {
        path: '/suites/add',
        name: 'Add Test Suite',
        component: Suite,
        meta: {
          role: RoleType.USER,
          icon: 'suites'
        }
      },
      {
        path: '/suites/edit/:id',
        name: 'Edit Test Suite',
        component: Suite,
        meta: {
          role: RoleType.USER,
          icon: 'suites'
        }
      },
      {
        path: '/scheduler',
        name: 'Scheduler',
        component: Schedulers,
        meta: {
          role: RoleType.USER,
          icon: 'scheduler'
        }
      },
      {
        path: '/scheduler/add',
        name: 'Add Scheduled Plan',
        component: Scheduler,
        meta: {
          role: RoleType.USER,
          icon: 'scheduler'
        }
      },
      {
        path: '/scheduler/edit/:id',
        name: 'Edit Scheduled Plan',
        component: Scheduler,
        meta: {
          role: RoleType.USER,
          icon: 'scheduler'
        }
      },
      {
        path: '/keys',
        name: 'API Keys',
        component: Keys,
        meta: {
          role: RoleType.USER,
          icon: 'keys'
        }
      },
      {
        path: '/keys/add',
        name: 'Add New API Key',
        component: Key,
        meta: {
          role: RoleType.ADMIN,
          icon: 'keys'
        }
      },
      {
        path: '/keys/edit/:id',
        name: 'Edit API Key',
        component: Key,
        meta: {
          role: RoleType.ADMIN,
          icon: 'keys'
        }
      },
      {
        path: '/groups',
        name: 'Groups',
        component: Groups,
        meta: {
          role: RoleType.SUPER_ADMIN,
          icon: 'group'
        }
      },
      {
        path: '/groups/add',
        name: 'Add New Group',
        component: Group,
        meta: {
          role: RoleType.ADMIN,
          icon: 'group'
        }
      },
      {
        path: '/groups/edit/:id',
        name: 'Edit Group',
        component: Group,
        meta: {
          role: RoleType.ADMIN,
          icon: 'group'
        }
      },
      {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: {
          role: RoleType.ADMIN,
          icon: 'users'
        }
      },
      {
        path: '/users/add',
        name: 'Add New User',
        component: User,
        meta: {
          role: RoleType.ADMIN,
          icon: 'user'
        }
      },
      {
        path: '/users/edit/:id',
        name: 'Edit User',
        component: User,
        meta: {
          role: RoleType.ADMIN,
          icon: 'user'
        }
      },
      {
        path: '/preferences',
        name: 'User Preferences',
        component: Preferences,
        meta: {
          role: RoleType.USER,
          icon: 'preferences'
        }
      },
      {
        path: '/learn',
        name: 'Knowledge Center',
        component: KnowledgeCenter,
        meta: {
          role: RoleType.USER,
          icon: 'learn'
        }
      },
    ]
  },
  { path: "*", component: PageNotFound }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const role = to.meta?.role
  if (!role && typeof role !== 'number') {
    return next()
  }
  // set the last valid route
  store.commit('setLastRoute', from.fullPath)
  store.commit('setTitle')
  let uRole = store.getters['Users/getRole']
  if (typeof uRole !== 'number') {
    // console.log(uRole,'fetching uRole');
    uRole = await store.dispatch('Users/getRole')
    if (isNaN(uRole)) {
      return next({ name: 'Auth' })
    }
  }
  if (uRole <= role) {
    return next()
  } else {
    return next({ name: 'Dashboard' })
  }
})
export default router
