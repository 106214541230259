
import Vue from "vue";
import VueTypes from "vue-types";
import { ListObj, SelectItem } from "../types";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  components: { Tooltip },
  name: "EnvComp",

  data() {
    return {
      // variable: { value: "", text: "" },
      selectedEnv: "local",
      options: [
        { text: "New Variable", value: "local" },
        { text: "Existing Variable", value: "global" },
      ],
      local: [],
    };
  },
  props: {
    value: VueTypes.any.def([]),
  },
  methods: {
    changed(val: SelectItem | undefined) {
      const env = [
        {
          name: val || "",
          type: "text",
          val,
        },
      ];
      this.$emit("input", { env });
    },
  },
  computed: {
    globalEnv(): ListObj {
      const globalEnv = this.$store.getters["Plans/getCurrentPlanGlobalEnv"];
      return globalEnv
        .filter((e: ListObj) => e.type === "text")
        .map((e: ListObj) => {
          const { name } = e;
          return "${{ " + name + " }}";
        });
    },
    env: {
      get(): string {
        return this.value.map((e: ListObj) => e.name)[0];
      },
      set(val: SelectItem | undefined) {
        this.changed(val);
      },
    },
  },
});
