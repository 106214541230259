
import Vue from "vue";
import VueTypes from "vue-types";
import {
  capitalize,
  format,
  roleIcon,
  roleColor,
  initiatorIcon,
} from "../../utils";
import { Initiator } from "../../types";
import CopyToClip from "../CopyToClip.vue";
import ActionsColumn from "./ActionsColumn.vue";
import Loader from "../Loader.vue";
import HealthIcon from "./HealthIcon.vue";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  components: { CopyToClip, ActionsColumn, Loader, HealthIcon, Tooltip },
  name: "ListTable",

  data() {
    return {
      page: this.pagination.current,
    };
  },
  props: {
    items: VueTypes.any,
    headers: VueTypes.any,
    actions: VueTypes.any.def([]),
    options: VueTypes.any.def({}),
    loading: VueTypes.bool.def(false),
    pagination: VueTypes.any.def({
      current: 1,
      total: 1,
      visible: 7,
      limit: 10,
      count: 0,
    }),
    search: VueTypes.string.def(""),
    scopeName: VueTypes.string,
  },
  methods: {
    capitalize,
    getInitiatorIcon(initiator: Initiator | undefined): string {
      const type = initiator?.type || 0;
      return initiatorIcon[type];
    },
    initiatorName(initiator: Initiator | undefined): string {
      return initiator?.name || "- -";
    },
    format,
    getRoleIcon(role: number) {
      return roleIcon[role];
    },
    getRoleColor(role: number) {
      return roleColor[role];
    },
    tooltipContent(head: any, item: any): string {
      return head?.tooltip
        ? (typeof head?.tooltip == "function"
            ? head.tooltip(item)
            : item.tooltip) || item.name
        : "";
    },
  },
  watch: {
    page(val) {
      this.$emit("page-change", val);
    },
  },
  computed: {
    tableHeight() {
      return window.innerHeight;
    },
    labels(): string[] {
      const labels = this.$store.getters["Users/getUserLabels"];
      console.log(labels);

      return labels;
    },
    rightActions() {
      return this.actions.filter((action: any) => !action.left);
    },
    leftActions() {
      return this.actions.filter((action: any) => action.left);
    },
  },
});
