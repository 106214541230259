
import Vue from "vue";
import VueTypes from "vue-types";
import { viewIcon } from "@/utils";
import UserBtn from "@/components/buttons/UserBtn.vue";
import Notifications from "./Notifications.vue";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  components: { UserBtn, Notifications, Tooltip },
  name: "HeaderComp",

  data() {
    return {};
  },
  props: {
    height: VueTypes.number.def(5),
  },
  computed: {
    title(): string {
      return this.$store.getters.getTitle || this.$route.name || "";
    },
    icon(): string {
      const { icon } = this.$route.meta;
      return viewIcon[icon] || "help-circle-outline";
    },
    barHeight() {
      const full = window.innerHeight;
      return full * 0.05;
    },
    extension(): boolean {
      return this.$store.getters.getExtension;
    },
  },
});
