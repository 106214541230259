
import Vue from "vue";
import VueTypes from "vue-types";

import BackBtn from "../components/buttons/BackBtn.vue";
// import Title from "../components/Title.vue";
import { Group, RoleType, SelectItem, User } from "../types";
import { validations } from "../utils";

export default Vue.extend({
  name: "UserView",
  components: {
    BackBtn,
  },
  data() {
    return {
      rules: {
        text: [validations.empty],
        email: [validations.email],
        pass: [validations.password.len, validations.password.strength],
      },
      roleType: RoleType,
      changes: false,
      fresh: true,
      password2: "",
      passShow: false,
      passwordToggle: true,
      error: { type: "", msg: "" },
      groupsFilter: { limit: 1000, search: { name: "" } },
    };
  },
  props: {
    outerRoute: VueTypes.string,
  },
  computed: {
    valid(): boolean {
      const valid: boolean =
        (this.user?.name?.length > 0 &&
          this.user?.email?.length > 0 &&
          this.changes &&
          (this.fresh
            ? this.user.password &&
              this.user.password.length > 6 &&
              this.user.password === this.password2
            : true)) ||
        false;

      return valid;
    },
    self(): boolean {
      return this.$store.getters["Users/isSelf"];
    },
    role(): number {
      return this.$store.getters["Users/getRole"];
    },
    roles(): SelectItem[] {
      const roles = this.$store.getters.getRoles;
      return roles.filter((role: SelectItem) => role.value >= this.role);
    },
    user(): User {
      const user: User = this.$store.getters["Users/getCurrentUser"];
      this.$store.commit(
        "setTitle",
        user._id ? `Edit '${user.name}'` : "Add New User"
      );
      return user;
    },
    selectedGroup: {
      get() {
        // let group = { text: this.user.groupName || "", value: this.user.group };
        // if (!this.changes && this.user.group) {
        //   group = ;
        // }
        return (
          this.user.groups?.map((group: { name: string; _id: string }) => {
            if (group._id)
              return {
                text: group.name || "",
                value: group._id,
              };
          }) || []
        );
      },
      set(val: [{ text: string; value: string }]) {
        this.user.groups = val.map((group: SelectItem) => {
          return {
            name: group.text,
            _id: group.value,
          };
        });
        this.changes = true;
      },
    },
    groups() {
      if (this.role > RoleType.SUPER_ADMIN) {
        return [];
      }
      const groups = this.$store.getters["Users/getGroups"];
      return groups.map((group: Group) => {
        return { text: group.name, value: group._id || group.id };
      });
    },
    allGroups() {
      return this.selectedGroup.length >= this.groups.length;
    },
    someGroups() {
      return this.selectedGroup.length > 0 && !this.allGroups;
    },
    selectAllIcon() {
      if (this.allGroups) return "mdi-close-box";
      if (this.someGroups) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    userChanged() {
      this.user.group = this.selectedGroup.value;
      this.user.groupName = this.selectedGroup.text;
      this.$store.commit("Users/setCurrentUser", this.user);
    },
    async save() {
      // this.user.group = this.selectedGroup.value;
      // const user = { ...this.user };
      // user.group = this.selectedGroup.value;
      // this.user.groups = this.selectedGroup.map((group: SelectItem) => {
      //   return {
      //     name: group.text,
      //     _id: group.value,
      //   };
      // });
      // user.groupName = this.selectedGroup.value;
      // this.user.groupName = this.selectedGroup.text;
      console.log(this.selectedGroup, this.user);

      const req = await this.$store.dispatch("Users/saveUser", this.user);
      if (req.error) {
        this.error = req.error;
      }
      this.$router.push(this.outerRoute || "/users");
    },
    async checkEmail() {
      this.changes = true;
      const { email } = this.user;
      if (validations.email(email) === true) {
        const resp = await this.$store.dispatch("Users/checkEmail", email);
        if (!resp?.success) {
          this.error = {
            type: "email",
            msg: "Username/e-mail is already in use",
          };
        } else {
          if (this.error.type == "email") {
            this.error = {
              type: "",
              msg: "",
            };
          }
        }
      } else {
        this.error = {
          type: "email",
          msg: "E-Mail Adrress not valid.",
        };
      }
      console.log(this.error);
    },
    fetchGroups() {
      this.$store.dispatch("Users/fetchGroups", this.groupsFilter);
    },
    updatePass() {
      this.$store.dispatch("Users/updatePassword", {
        id: this.user._id,
        password: this.user.password,
      });
      this.password2 = "";
      this.user.password = "";
    },
    toggleSelectAll() {
      this.$nextTick(() => {
        if (this.allGroups) {
          this.selectedGroup = [];
        } else {
          this.selectedGroup = this.groups.slice();
        }
      });
    },
    // changeGroup(option: { text: string; value: string }) {
    //   if (option) {
    //     const { text, value } = option;
    //     this.user.groupName = text;
    //     this.user.group = value;
    //     this.changes = true;
    //     // this.$store.commit("Users/setCurrentUserGroup", {
    //     //   groupName: text,
    //     //   group: value,
    //     // });
    //   }
    // },
    search(e: Event) {
      const target = e.target as HTMLTextAreaElement;
      const text = target.value;
      // if (text) {
      this.groupsFilter.search.name = text;
      this.fetchGroups();
      // }
    },
  },
  watch: {
    passwordToggle(on: boolean) {
      if (on) {
        this.user.password = "";
      } else {
        delete this.user.password;
      }
    },
  },
  async mounted() {
    this.$store.commit("setGlobalLoader", true);
    this.fetchGroups();
    const { id } = this.$route.params;
    this.$store.commit("Users/setCurrentUser");
    if (id) {
      this.fresh = false;
      this.passwordToggle = false;
      await this.$store.dispatch("Users/fetchCurrentUser", id);
    }
    this.$store.commit("setGlobalLoader");
    // if (this.user.groups?.length) {
    //   this.selectedGroup = this.user.groups.map(
    //     (group: { name: string; _id: string }) => {
    //       return {
    //         text: group.name || "",
    //         value: group._id,
    //       };
    //     }
    //   );
    // }
  },
});
