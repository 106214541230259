import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { GroupsState } from '@/types'

export default {
  namespaced: true,
  state: (): GroupsState => {
    return {
      groups: {
        list: [],
        currentGroup: {},
        total: 0,
      },
      contacts: []
    }
  },
  actions,
  mutations,
  getters
}
