import { Scheduler, SchedulerState } from "@/types"

export default {
  // Scheduler
  getSchedules: (state: SchedulerState): Scheduler[] => {
    return state.schedule.list
  },
  getSchedulesTotal: (state: SchedulerState): number => {
    return state.schedule.total
  },
  getCurrentSchedule: (state: SchedulerState): Scheduler => {
    return state.schedule.currentSchedule
  },
}
