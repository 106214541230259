
import { Result } from "@/types";
import Vue from "vue";

import BackBtn from "../components/buttons/BackBtn.vue";
import ResultLayout from "../components/ResultLayout.vue";
// import Title from "../components/Title.vue";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  name: "ResultView",
  components: {
    BackBtn,
    ResultLayout,
    Tooltip,
  },
  data() {
    return {};
  },
  computed: {
    result(): Result {
      const result = this.$store.getters["Results/getCurrentResult"];
      this.$store.commit("setTitle", `View '${result.name}'`);
      return result;
    },
    planLink(): string {
      const link = `/plans/${
        this.result?.planId ? `edit/${this.result?.planId}#plan` : null
      }`;
      return link;
    },
  },
  methods: {
    closeView() {
      this.$store.commit("Results/setCurrentResultView");
    },
  },
  async mounted() {
    this.$store.commit("setGlobalLoader", true);
    this.$store.commit("Results/setCurrentResult");
    const { id } = this.$route.params;
    if (id) {
      await this.$store.dispatch("Results/fetchCurrentResult", id);
    }
    this.$store.commit("setGlobalLoader");
  },
  destroyed() {
    this.$store.commit("Results/setCurrentResult");
    this.closeView();
  },
});
