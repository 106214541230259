
import { Group, RoleType, SelectItem } from "@/types";
import Vue from "vue";
import VueTypes from "vue-types";

import BackBtn from "../components/buttons/BackBtn.vue";
import AddList from "../components/AddList.vue";
// import Title from "../components/Title.vue";
import { validations, updateObj, cloneObj, locales } from "../utils";

export default Vue.extend({
  name: "GroupView",
  components: {
    BackBtn,
    AddList,
  },
  data() {
    return {
      rules: {
        text: [validations.empty],
      },
      roleType: RoleType,
      changes: false,
      fresh: true,
      error: {},
      searchLabel: "",
    };
  },
  props: {
    outerRoute: VueTypes.string,
  },
  computed: {
    valid(): boolean {
      const valid = this.group?.name?.length > 0 && this.changes;
      // console.log(valid, this.group);
      return valid;
    },
    role(): number {
      return this.$store.getters["Users/getRole"];
    },
    packages(): SelectItem[] {
      return this.$store.getters.getPackages;
    },
    labels(): SelectItem[] {
      const labels = this.group?.labels;
      return labels;
    },
    locales() {
      return locales || [];
    },
    group: {
      get(): Group {
        const group = this.$store.getters["Groups/getCurrentGroup"];
        this.$store.commit(
          "setTitle",
          group._id ? `Edit '${group.name}'` : "Add New Group"
        );
        return group;
      },
      set(group: Group) {
        this.$store.commit("Groups/setCurrentGroup", group);
      },
    },
  },
  methods: {
    async save() {
      await this.$store.dispatch("Groups/saveGroup", this.group);
      this.$store.commit("Groups/setCurrentGroup", {});
      this.$router.push("/groups");
      // this.$router.push(this.outerRoute || "/groups");
    },
    changed(data: unknown) {
      if (data) {
        this.group = cloneObj(updateObj(this.group, data));
      }
      this.changes = true;
    },
  },
  async mounted() {
    const { id } = this.$route.params;
    this.$store.commit("Groups/setCurrentGroup");
    if (id) {
      this.$store.commit("setGlobalLoader", true);
      this.fresh = false;
      await this.$store.dispatch("Groups/fetchCurrentGroup", id);
      this.$store.commit("setGlobalLoader");
    }
  },
});
