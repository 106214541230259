
import { permissions } from "@/utils";
import Vue from "vue";
import VueTypes from "vue-types";
import Device from "./Device.vue";
import AddList from "./AddList.vue";
import Tooltip from "@/components/Tooltip.vue";
import Countries from "./Countries.vue";
import RequestHeaders from "./RequestHeaders.vue";
import Locale from "./Locale.vue";
import KnobBtn from "./buttons/KnobBtn.vue";
import { SelectItem } from "@/types";
// import FileListUpload from "./FileListUpload.vue";

export default Vue.extend({
  name: "EnvSettings",
  data() {
    return {
      selected: undefined,
    };
  },
  props: {
    value: VueTypes.any,
    isRoutine: VueTypes.bool.def(false),
  },
  computed: {
    obj: {
      get(): any {
        return this.value;
      },
      set(val: any) {
        this.changed(val);
      },
    },
    authMethods(): SelectItem[] {
      return this.$store.getters["Plans/getAuthMethods"];
    },
    hasHeaders(): boolean {
      return this.obj.headers && Object.keys(this.obj.headers).length > 0;
    },
    hasAuth(): boolean {
      return (
        this.obj.skipAuthMethod ||
        this.obj.isAuthMethod ||
        this.obj.authMethodId
      );
    },
    hasResources(): boolean {
      return this.obj.resources?.length > 0;
    },
    hasEnv(): boolean {
      return this.obj.env?.length > 0;
    },
    premissionList() {
      return permissions.filter((p) =>
        this.obj.permissions ? this.obj.permissions.indexOf(p) < 0 : true
      );
    },
  },
  methods: {
    changed(val = undefined) {
      this.$emit("input", val);
    },
  },
  components: {
    Device,
    AddList,
    Tooltip,
    Countries,
    RequestHeaders,
    Locale,
    KnobBtn,
  },
});
