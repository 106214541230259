import { GeneralState, Msg } from "@/types"
// import { v4 } from "uuid"

// const defaultPingr: any = {
//   id: v4(),
//   name: "",
//   device: {},
//   alerts: false,
//   env: [],
//   desc: "",
//   schedule: null,
//   scheduleActive: false,
//   active: true,
// }

// check pine3 extension div injection
// const setExtension = (state: GeneralState) => {
//   setTimeout(() => {
//     try {
//       const exist = document.querySelector("#p3_extension_exists")
//       if (exist) {
//         state.extension = true
//       } else {
//         state.extension = false
//       }
//     } catch (error) {
//       state.extension = false
//     }
//   }, 100)
// }

export default {
  // Display
  toggleExpand: (state: GeneralState, val: boolean | undefined): void => {
    if (typeof val === 'boolean') {
      state.display.expand = val
    } else {
      state.display.expand = !state.display.expand
    }
    localStorage.setItem('p3e', String(state.display.expand))
  },
  setExpandStatus: (state: GeneralState): void => {
    state.display.expand = !state.display.expand
    localStorage.setItem('p3e', String(state.display.expand))
  },
  setStatsLoader: (state: GeneralState, loading = false): void => {
    state.stats.loading = loading
  },
  resetStats: (state: GeneralState): void => {
    // test success ratio
    state.stats.testsRatio.series[0].data[0].y = 0
    state.stats.testsRatio.series[0].data[1].y = 0

    // tests execution chart
    state.stats.testsExecution.xAxis = { categories: [] }
    state.stats.testsExecution.series[0].data = []

    // tests overtime chart
    state.stats.testsOverTime.xAxis = { categories: [] }
    state.stats.testsOverTime.series[0].data = []
    state.stats.testsOverTime.series[1].data = []
    state.stats.testsOverTime.series[2].data = []

    // Initiators chart
    state.stats.initiators.series[0].data = []

    // Usage chart
    state.stats.usage.series[0].data = []
  },

  setExtension: (state: GeneralState): void => {
    setTimeout(() => {
      try {
        const exist = document.querySelector("#p3_extension_exists")
        if (exist) {
          state.extension = true
        } else {
          state.extension = false
        }
      } catch (error) {
        state.extension = false
      }
    }, 100)
  },

  setExpand: (state: GeneralState): void => {
    const expand = localStorage.getItem('p3e') === "true"
    state.display.expand = expand
  },
  closePop: (state: GeneralState): void => {
    state.display.pop = false
  },
  pop: (state: GeneralState): void => {
    state.display.pop = true
  },
  setGlobalLoader: (state: GeneralState, loader = false): void => {
    state.display.loader = loader
  },
  setTitle: (state: GeneralState, title = ""): void => {
    state.title = title
  },
  toggleLoader: (state: GeneralState): void => {
    state.display.loader = !state.display.loader
  },
  setMsg: (state: GeneralState, msg: Msg): void => {
    state.messages.push(msg)
  },
  rmMsg: (state: GeneralState, id: string): void => {
    state.messages = state.messages.filter((msg: Msg) => msg.id !== id)
  },
  setNotification: (state: GeneralState, msg: Msg): void => {
    state.notifications.unshift(msg)
    localStorage.setItem('p3n', JSON.stringify(state.notifications))
  },
  rmNotification: (state: GeneralState, id: string): void => {
    state.notifications = state.notifications.filter((msg: Msg) => msg.id !== id)
    localStorage.setItem('p3n', JSON.stringify(state.notifications))
  },
  rmAllNotification: (state: GeneralState): void => {
    state.notifications = []
    localStorage.setItem('p3n', JSON.stringify(state.notifications))
  },
  setSlides: (state: GeneralState, slides: string[] = []): void => {
    state.slides.list = [...slides]
  },
  setSlidesOptions: (state: GeneralState, options = {
    height: '80vh',
    delimiters: false,
    continuous: true,
    cycle: false
  }): void => {
    state.slides.options = { ...state.slides.options, ...options }
  },
  pushSlide: (state: GeneralState, img: string): void => {
    state.slides.list.push(img)
  },
  setResponse: (state: GeneralState, response: any = ''): void => {
    state.response.data = response
  },
  setResponseOptions: (state: GeneralState, options = {
    type: 'html',
  }): void => {
    state.response.options = { ...state.response.options, ...options }
  },
  setLastRoute: (state: GeneralState, route: string): void => {
    state.lastRoute = route
  },
  setImportDialogActive: (state: GeneralState, active = false): void => {
    state.importDialog.active = active
  },
  setImportDialog: (state: GeneralState, dialog = { active: false, items: [], action: null, title: 'Selete Item to import' }): void => {
    state.importDialog = dialog
  },
  setExitDialog: (state: GeneralState, active = false): void => {
    state.exitDialog.active = active
  },
  setExitDialogTo: (state: GeneralState, to = ''): void => {
    state.exitDialog.to = to
  },
  setDeleteDialogActive: (state: GeneralState, active = false): void => {
    state.deleteDialog.active = active
  },
  setDeleteDialog: (state: GeneralState, dialog = { active: false, content: '', title: '', action: null, }): void => {
    state.deleteDialog = dialog
  },
}
