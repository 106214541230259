import { DefaultFilterObject } from "@/defaults"
import { DefaultFilter, Msg, Scheduler, SchedulerContext } from "@/types"
import { Fetcher } from "@/utils"
import { v4 } from "uuid"

const api = new Fetcher()

export default {
  // Scheduler
  fetchAllSchedules: async (context: SchedulerContext, filter: DefaultFilter = DefaultFilterObject): Promise<void> => {
    try {
      context.state.schedule.list = []
      context.state.schedule.total = 0
      const { data } = await api.post(`api/schedule/search`, filter)
      const { schedule, total } = data
      context.state.schedule.list = schedule
      context.state.schedule.total = total
    } catch (error) {
      console.error(error);
    }
    // check extension existence
    context.commit('setExtension', undefined, { root: true })
  },
  fetchCurrentSchedule: async (context: SchedulerContext, id: string): Promise<void> => {
    try {
      const schedule = await api.get(`api/schedule/${id}`)
      schedule.plans = schedule?.plans?.map((p: any) => {
        if (p) {
          return { text: p.name, value: p._id, icon: 'flask' }
        }
      }) || []
      schedule.suites = schedule?.suites?.map((s: any) => {
        if (s) {
          return { text: s.name, value: s._id, icon: 'package-variant' }
        }
      }) || []
      context.commit("setCurrentSchedule", schedule)
    } catch (error) {
      console.error(error);
    }
  },
  saveSchedule: async (context: SchedulerContext, schedule: Scheduler): Promise<Scheduler | unknown> => {
    try {
      const { _id } = schedule
      const { data, error } = (await api.post(`api/schedule/${_id || ''}`, schedule)).data
      let msg: Msg
      if (error) {
        msg = { id: v4(), type: 'error', content: error.msg, dismissible: true, timeout: 3000 }
        return { error }
      } else {
        msg = { id: v4(), type: 'success', color: "teal", ts: new Date().getTime(), icon: "history", content: `Scheduler <strong>"${data.name}"</strong> was saved`, dismissible: true, timeout: 3000, notify: true }
        context.commit("setCurrentSchedule");
      }
      await context.dispatch('fetchAllSchedules')
      context.dispatch('popMsg', msg, { root: true })
      return data
    } catch (error) {
      console.error(error);
    }
  },
  deleteById: async (context: SchedulerContext, id: string): Promise<Scheduler> => {
    try {
      const data = await api.delete(`api/schedule/${id}`)
      await context.dispatch('fetchAllSchedules')
      return data
    } catch (error) {
      console.error(error);
    }
  },
  duplicateById: async (context: SchedulerContext, id: string): Promise<Scheduler> => {
    try {
      const current = await api.get(`api/schedule/${id}`)
      const schedule: Scheduler = { ...current }
      const oldName = schedule.name
      delete schedule._id
      delete schedule.lastRun
      delete schedule.nextRun
      delete schedule.created
      schedule.name += ' - COPY'
      const data = await api.post(`api/schedule/`, schedule)
      await context.dispatch('fetchAllSchedules')
      const msg: Msg = { id: v4(), type: 'info', content: `Scheduled task: <strong>"${oldName}"</strong> was duplicated successfully`, dismissible: true, timeout: 5000 }
      context.dispatch('popMsg', msg, { root: true })
      return data
    } catch (error) {
      console.error(error);
    }
  },
}
