
import Vue from "vue";
import { any } from "vue-types";

export default Vue.extend({
  name: "TopicConetnt",

  data() {
    return {};
  },
  props: {
    content: any()
  },
  computed: {
    contentData() {
      return this.content.data;
    },
    contentType() {
      return this.content.type;
    },
    contentTitle() {
      return this.content.title;
    },
  },
});
