
import Vue from "vue";
import VueTypes from "vue-types";
import ActionsLoader from "./actions/ActionsLoader.vue";
import ActionsBtn from "./actions/ActionsBtn.vue";
import ActionsIcon from "./actions/ActionsIcon.vue";
import ActionsProgress from "./actions/ActionsProgress.vue";

export default Vue.extend({
  name: "ActionsColumn",
  data() {
    return {};
  },
  props: {
    action: VueTypes.any.def({}),
    item: VueTypes.any.def({}),
    scopeName: VueTypes.string.def(""),
  },
  computed: {
    running(): boolean {
      const valid = this.action.type === "run";
      const live = this.item.live || false;
      return valid && live;
    },
    selectedComp() {
      let comp;
      switch (this.action.type) {
        case "progress":
          comp = "ActionsProgress";
          break;
        case "icon":
          comp = "ActionsIcon";
          break;

        default:
          comp = "ActionsBtn";
          break;
      }
      return comp;
    },
  },
  components: { ActionsLoader, ActionsBtn, ActionsIcon, ActionsProgress },
});
