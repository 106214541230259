import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { SuitesState } from '@/types'

export default {
  namespaced: true,
  state: (): SuitesState => {
    return {
      suites: {
        list: [],
        currentSuite: {},
        total: 0,
      },
    }
  },
  actions,
  mutations,
  getters
}
