
import Vue from "vue";
import Slogen from "@/components/Slogen.vue";
import { validations } from "@/utils";
import { RoleType } from "@/types";

export default Vue.extend({
  components: { Slogen },
  name: "InviteView",
  data() {
    return {
      step: 1,
      user: {
        name: "",
        email: "",
        password: "",
        group: "",
        groupName: "",
        role: RoleType.USER,
      },
      rules: {
        text: [validations.empty],
        email: [validations.email],
        pass: [validations.password.len, validations.password.strength],
      },
      roles: [
        { text: "Admin", value: 2 },
        { text: "User", value: 3 },
      ],
      changes: false,
      fresh: true,
      password2: "",
      passShow: false,
      error: { type: "", msg: "" },
    };
  },
  methods: {
    async save() {
      console.log(this.selectedGroup, this.user);

      const req = await this.$store.dispatch("Users/saveUser", this.user);
      if (req.error) {
        return (this.error = req.error);
      }
      await this.$store.dispatch("Users/sendConfirmation", this.user);
      this.$router.push("/");
    },
  },
  async created() {
    const { token } = this.$route.params;

    this.user = await this.$store.dispatch("Users/checkInvite", token);
  },
  computed: {
    valid() {
      return this.password2 === this.user.password;
    },
  },
});
