
import { updateObj } from "@/utils";
import Vue from "vue";
import FilterBar from "../components/Filter/FilterBar.vue";
import ListTable from "../components/listTable/ListTable.vue";
import {
  DefaultActionPlayVideo,
  DefaultActionView,
  DefaultFilterObject,
} from "../defaults";
import { Pagination, Result } from "../types";

export default Vue.extend({
  name: "ResultsView",
  components: {
    ListTable,
    FilterBar,
  },
  data() {
    return {
      headers: [
        {
          text: "Plan Name",
          align: "start",
          filterable: false,
          value: "name",
          copy: true,
          to: (item: Result): string => {
            return `${this.$route.path}/view/${item._id}`;
          },
          tooltip: (item: Result): string => {
            return `Inspect '${item}'`;
          },
        },
        { text: "Type", value: "type", type: "type" },
        { text: "Version", value: "version", type: "text" },
        { text: "Execution Time", value: "date", type: "date" },
        { text: "Initiator", value: "initiator", type: "initiator" },
        { text: "Duration", value: "duration", type: "duration" },
        { text: "Status", value: "success", type: "test" },
      ],
      actions: [
        DefaultActionPlayVideo,
        DefaultActionView,
      ],
      filter: {
        ...DefaultFilterObject,
      },
      sortOptions: [
        { text: "Date", value: "date" },
        { text: "Name", value: "name" },
        { text: "Success", value: "success" },
        { text: "Duration", value: "duration" },
      ],
      searchOptions: [
        { text: "Name", value: "name" },
        { text: "Initiator", value: "initiator.name" },
      ],
      dateOptions: {
        dateField: "date",
      },
      options: {},
      loading: false,
    };
  },
  methods: {
    async fetchList() {
      this.loading = true;
      this.$store.commit("Results/setFilter", this.filter);
      await this.$store.dispatch("Results/fetchAllResults", true);
      this.loading = false;
    },
    pageChange(val: number): void {
      const { limit } = this.filter;
      const skip = (val - 1) * limit;
      this.filter.skip = skip;
      this.changed();
    },
    changed(data: unknown | null) {
      if (data) {
        this.filter = updateObj(this.filter, data);
      }
      this.fetchList();
    },
  },
  computed: {
    items() {
      return this.$store.getters["Results/getResults"];
    },
    total() {
      return this.$store.getters["Results/getResultsTotal"];
    },
    pagination(): Pagination {
      const { limit } = this.filter;
      const count = this.total;
      const total = Math.ceil(count / limit);
      return { total, current: 1, visible: 7, count, limit };
    },
  },
  created() {
    this.filter = this.$store.getters["Results/getFilter"];
    this.fetchList();
  },
});
