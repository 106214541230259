
import Vue from "vue";

import BackBtn from "../components/buttons/BackBtn.vue";
// import Title from "../components/Title.vue";
import DragList from "../components/DragList.vue";
import CopyToClip from "@/components/CopyToClip.vue";
import { Plan, SelectItem, Suite } from "@/types";
import { cloneObj, permissions } from "@/utils";
import EnvSettings from "@/components/EnvSettings.vue";

export default Vue.extend({
  name: "SuiteView",
  components: {
    BackBtn,
    DragList,
    CopyToClip,
    EnvSettings,
  },
  data() {
    return {
      rules: {
        text: [
          (val: string) => (val && val.length) || "This field is required",
        ],
      },
      selected: "plans",
      // selectMethods: [
      //   {
      //     text: "Test Case",
      //     value: "plans",
      //     icon: "flask",
      //   },
      //   {
      //     text: "Test Routine",
      //     value: "routines",
      //     icon: "cog-clockwise",
      //   },
      //   {
      //     text: "Test Suite",
      //     value: "suites",
      //     icon: "package-variant",
      //   },
      // ],
    };
  },
  computed: {
    valid(): boolean {
      return (
        this.suite?.name?.length > 0 &&
        (this.suite?.plans?.length > 0 || this.suite?.suites?.length > 0)
      );
    },
    suite: {
      get(): Suite {
        const suite: Suite = this.$store.getters["Suites/getCurrentSuite"];
        this.$store.commit(
          "setTitle",
          suite._id ? `Edit '${suite.name}'` : "Add New Suite"
        );
        // this.fixObj(suite, "Suite");
        return suite;
      },
      set(val: Suite) {
        this.suiteChanged(val);
      },
    },
    // plans(): SelectItem[] {
    //   // TODO
    //   const plans = this.$store.getters["Plans/getPlans"];

    //   return plans.map((p: Plan): SelectItem => {
    //     return { text: p.name, value: p._id, icon: "flask" };
    //   });
    // },
    selectedList: {
      get(): SelectItem[] {
        return this.suite[this.selected];
      },
      set(items: SelectItem[]) {
        this.suite[this.selected] = items;
      },
    },
    selectedIcon(): string {
      let icon = "package-variant";
      switch (this.selected) {
        case "plans":
          icon = "flask";
          break;
        case "routines":
          icon = "cog-clockwise";
          break;
        case "suites":
          icon = "package-variant";
          break;

        default:
          break;
      }
      return icon;
    },

    items(): SelectItem {
      const funcText = { suites: "Suites/getSuites", plans: "Plans/getPlans" };
      const items = this.$store.getters[funcText[this.selected]];

      return items.map((item: Plan | Suite) => {
        if (item._id !== this.suite._id) {
          return {
            text: item.name,
            value: item._id,
            icon: this.selectedIcon,
          };
        }
      });
    },
    hasHeaders(): boolean {
      return this.suite.headers && Object.keys(this.suite.headers).length > 0;
    },
    hasEnv(): boolean {
      return this.suite.env?.length > 0;
    },
    premissionList() {
      return permissions.filter((p) =>
        this.suite.permissions ? this.suite.permissions.indexOf(p) < 0 : true
      );
    },
  },
  methods: {
    removePlan(index: number) {
      this.suite.plans.splice(index, 1);
    },
    updateList(list: SelectItem) {
      this.suite[this.selected] = list;
      this.suiteChanged({ [this.selected]: list });
    },
    suiteChanged(newData: Suite | undefined = undefined) {
      if (newData) {
        const suite: Suite = this.suite;
        for (const key in newData) {
          suite[key] = cloneObj(newData[key]);
        }
      }
      this.$store.commit("Suites/setCurrentSuite", this.suite);
    },
    save() {
      // modify plans list to string[]
      // const plans = this.suite.plans.map((s: SelectItem) => s.value);
      const items = [
        ...this.suite[this.selected].map((s: SelectItem) => s.value),
      ];
      this.suite[this.selected] = items;
      this.selected == "plans"
        ? (this.suite.suites = [])
        : (this.suite.plans = []);
      this.$store.dispatch("Suites/saveSuite", this.suite);
      this.$store.commit("Suites/setCurrentSuite", {});
      this.$router.push("/suites");
    },
  },

  async mounted() {
    this.$store.commit("setGlobalLoader", true);
    this.$store.commit("Suites/setCurrentSuite");
    // TODO filter
    // const filter = {};
    this.$store.dispatch("Plans/fetchAllPlans", {
      sort: { name: 1 },
      type: { '$lte': 1 },
    });
    this.$store.dispatch("Suites/fetchSuites");
    const { id } = this.$route.params;
    if (id) {
      await this.$store.dispatch("Suites/fetchCurrentSuite", id);
      this.suite.plans.length
        ? (this.selected = "plans")
        : (this.selected = "suites");
    }
    this.$store.commit("setGlobalLoader");
  },
  destroyed() {
    this.$store.commit("Suites/setCurrentSuite");
  },
});
