
import Vue from "vue";
import ListTable from "../components/listTable/ListTable.vue";
import AddBtn from "../components/buttons/AddBtn.vue";
import FilterBar from "../components/Filter/FilterBar.vue";
import {
  DefaultActionDelete,
  DefaultActionEdit,
  DefaultFilterObject,
} from "../defaults";
import { Pagination, User } from "../types";
import { updateObj } from "@/utils";
import InviteBtn from "@/components/buttons/InviteBtn.vue";

export default Vue.extend({
  name: "UsersView",
  components: {
    ListTable,
    AddBtn,
    FilterBar,
    InviteBtn,
  },
  data() {
    return {
      headers: [
        { text: "", value: "avatar", type: "avatar" },
        {
          text: "Full Name",
          align: "start",
          filterable: false,
          value: "name",
          to: (item: User): string => {
            return `${this.$route.path}/edit/${item._id}`;
          },
        },
        { text: "Group Name", value: "groupName", type: "text" },
        { text: "Username", value: "email", type: "text" },
        { text: "Last Login", value: "lastLogin", type: "date" },
        { text: "", value: "role", type: "role" },
        { text: "Active", value: "active", type: "active" },
      ],
      actions: [
        DefaultActionEdit,
        {
          ...DefaultActionDelete,
          disabled: (item: User) => {
            return (
              this.$store.getters["Users/getId"] === item._id ||
              this.$store.getters["Users/getRole"] >= item.role
            );
          },
        },
      ],
      filter: { ...DefaultFilterObject, sort: { role: 1 } },
      sortOptions: [
        { text: "Full Name", value: "name" },
        { text: "Last Login", value: "lastLogin" },
        { text: "Group", value: "groupName" },
        { text: "Username/E-mail", value: "email" },
        { text: "Role", value: "role" },
        { text: "Active", value: "active" },
      ],
      searchOptions: [
        { text: "Name", value: "name" },
        { text: "Username/E-mail", value: "email" },
        { text: "Group", value: "groupName" },
      ],
      options: {},
      loading: false,
    };
  },
  methods: {
    async fetchList() {
      this.loading = true;
      await this.$store.dispatch("Users/fetchUsers", this.filter);
      this.loading = false;
    },
    pageChange(val: number): void {
      const { limit } = this.filter;
      const skip = (val - 1) * limit;
      this.filter.skip = skip;
      this.changed();
    },
    changed(data: unknown | null) {
      if (data) {
        this.filter = updateObj(this.filter, data);
      }
      this.fetchList();
    },
  },
  computed: {
    items() {
      return this.$store.getters["Users/getUsers"];
    },
    role() {
      return this.$store.getters["Users/getRole"];
    },
    total() {
      return this.$store.getters["Users/getUsersTotal"];
    },
    pagination(): Pagination {
      const { limit } = this.filter;
      const count = this.total;
      const total = Math.ceil(count / limit);
      return { total, current: 1, visible: 7, count, limit };
    },
  },
  created() {
    this.changed();
  },
});
