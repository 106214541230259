
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "SlogenComp",
  data() {
    return {};
  },
  props: {
    negative: VueTypes.bool.def(false),
    logoSize: VueTypes.string.def("150px"),
    textSize: VueTypes.string.def("20px"),
    logoActive: VueTypes.bool.def(true),
    textActive: VueTypes.bool.def(true),
  },
  computed: {
    src() {
      return require(`@/assets/pine3_logo${this.negative ? "_white" : ""}.svg`);
    },
    text_active() {
      return this.textActive
    },
    logo_active() {
      return this.logoActive
    },
  },
});
