
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "LoaderComp",

  data() {
    return {};
  },
  props: {
    absolute: VueTypes.bool.def(true),
    size: VueTypes.number.def(30),
    width: VueTypes.number.def(3),
    color: VueTypes.string.def("blue"),
    overlayColor: VueTypes.string.def("#fff"),
    indeterminate: VueTypes.bool.def(true),
    active: VueTypes.bool.def(true),
  },
});
