
import Vue from "vue";
import { any, bool, number, oneOfType, string } from "vue-types";

export default Vue.extend({
  name: "TooltipComp",
  data() {
    return {};
  },
  props: {
    content: string().def(""),
    altContent: string().def(""),
    color: string().def("light-blue"),
    transition: string().def("slide-y-transition"),
    top: bool().def(false),
    bottom: bool().def(false),
    left: bool().def(false),
    right: bool().def(false),
    absolute: bool().def(false),
    active: oneOfType([bool(), number()]).def(true),
    maxWidth: oneOfType([string(), number()]).def('60%'),
    delay: number().def(200),
    icon: any().def(""),
    attach: any().def(false),
  },
  computed: {
    // small(): boolean {
    //   return this.icon.size === "small";
    // },
    // xSmall(): boolean {
    //   return this.icon.size === "x-small";
    // },
    text(): string {
      return this.active ? this.content : this.altContent;
    },
    disabled(): boolean {
      return !this.active && !this.text.length;
    },
  },
});
