
import Vue from "vue";

import BackBtn from "../components/buttons/BackBtn.vue";
import CopyToClip from "../components/CopyToClip.vue";
// import Title from "../components/Title.vue";
import { validations, regexes } from "../utils";
import KeysGeneratorDialog from "@/components/dialogs/KeysGeneratorDialog.vue";
import { Key, SelectItem } from "@/types";

export default Vue.extend({
  name: "KeyView",
  components: {
    BackBtn,
    CopyToClip,
    KeysGeneratorDialog,
  },
  data() {
    return {
      rules: {
        text: [
          (val: string) => (val && val.length) || "This field is required",
        ],
        host: [validations.host],
        domain: [validations.domain],
        ip: [validations.ip],
        // empty: [validations.empty],
      },
      currentSource: "",
      currentSourceType: "domain",
      fresh: true,
      snippetDialog: false,
    };
  },
  computed: {
    valid(): boolean {
      return (
        this.key?.name?.length > 0 &&
        this.key?.actions?.length > 0 &&
        this.key?.source?.length > 0
      );
    },
    key(): Key {
      const key: Key = this.$store.getters["Keys/getCurrentKey"];
      this.$store.commit(
        "setTitle",
        key._id ? `Edit '${key.name}'` : "Add New API Key"
      );
      return key;
    },
    actions(): SelectItem[] {
      const actions = this.$store.getters["Keys/getActions"];
      return actions;
    },
    validateSource() {
      if (this.currentSourceType && this.currentSource) {
        const { ip, domain } = regexes.host;
        return this.currentSourceType === "ip"
          ? ip.test(this.currentSource)
          : this.currentSource === "localhost" ||
              domain.test(this.currentSource);
      }
      return false;
    },
  },
  methods: {
    addSource() {
      this.key.source.push(this.currentSource);
      this.currentSource = "";
    },
    deleteSource(index: number) {
      this.key.source.splice(index, 1);
    },
    // updateList(list: any) {
    //   this.key.actions = list;
    // },
    // keyChanged() {
    //   this.$store.commit("Keys/setCurrentKey", this.key);
    // },
    save() {
      // modify actions list to string[]
      this.$store.dispatch("Keys/saveKey", this.key);
      this.$store.commit("Keys/setCurrentKey", {});
      this.$router.push("/keys");
    },
  },

  async mounted() {
    this.$store.commit("Keys/setCurrentKey");
    this.$store.commit("setGlobalLoader", true);
    const { id } = this.$route.params;
    if (id) {
      this.fresh = false;
      await this.$store.dispatch("Keys/fetchCurrentKey", id);
    }
    this.$store.commit("setGlobalLoader");
  },
  destroyed() {
    this.$store.commit("Keys/setCurrentKey");
  },
});
