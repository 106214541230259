import { DefaultFilterObject } from "@/defaults"
import { DefaultFilter, Key, KeysContext, Msg, RoleType } from "@/types"
import { Fetcher } from "@/utils"
import { v4 } from "uuid"

const api = new Fetcher()

export default {
  init: async (context: KeysContext) => {
    await context.dispatch('fetchKeys')
  },
  fetchKeys: async (context: KeysContext, filter: DefaultFilter = DefaultFilterObject): Promise<void> => {
    try {
      context.state.keys.list = []
      context.state.keys.total = 0
      const { data } = await api.post(`api/keys/search`, filter)
      const { keys, total } = data
      context.state.keys.list = keys
      context.state.keys.total = total
    } catch (error) {
      console.error(error);
    }
    // check extension existence
    context.commit('setExtension', undefined, { root: true })
  },
  fetchCurrentKey: async (context: KeysContext, id: string): Promise<void> => {
    try {
      const group = await api.get(`api/keys/${id}`)
      context.commit("setCurrentKey", group)
    } catch (error) {
      console.error(error);
    }
  },
  saveKey: async (context: KeysContext, key: Key): Promise<any> => {
    try {
      const { _id } = key
      const { role } = await api.get(`api/users/role`).then(r => r).catch(e => e)
      if (role > RoleType.ADMIN) {
        const msg = { id: v4(), icon: "key-chain-variant", type: 'error', content: '<strong>Permission issues</strong>', dismissible: true, timeout: 3000 }
        context.dispatch('popMsg', msg, { root: true })
        return
      }
      const { data, error } = (await api.post(`api/keys/${_id || ''}`, key)).data
      await context.dispatch("fetchKeys")

      let msg: Msg
      if (error) {
        msg = { id: v4(), type: 'error', content: error.msg, dismissible: true, timeout: 3000 }
        return { error }
      } else {
        msg = { id: v4(), type: 'success', color: "indigo", ts: new Date().getTime(), icon: "key-chain-variant", content: `Key <strong>"${data.name}" was saved`, dismissible: true, timeout: 3000, notify: true }
        context.commit("setCurrentKey");
      }
      context.dispatch('popMsg', msg, { root: true })
      return { data }
    } catch (error) {
      console.error(error);
    }
  },
  deleteById: async (context: KeysContext, id: string): Promise<any> => {
    try {
      const { data } = await api.delete(`api/keys/${id}`)
      await context.dispatch("fetchKeys")
      const msg: Msg = { id: v4(), type: 'info', color: "indigo", ts: new Date().getTime(), icon: "key-chain-variant", content: `Key was successfully deleted`, dismissible: true, timeout: 3000, notify: true }
      context.dispatch('popMsg', msg, { root: true })
      return data
    } catch (error) {
      console.error(error);
    }
  },
}
