
import Vue from "vue";
import VueTypes from "vue-types";
import Search from "./Search.vue";
import Limit from "./Limit.vue";
import Sort from "./Sort.vue";
import DatePicker from "./DatePicker.vue";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  components: { Sort, Limit, Search, DatePicker, Tooltip },
  name: "FilterBar",

  data() {
    return {
      hidden: true,
    };
  },
  props: {
    filter: VueTypes.any.def({}),
    sortOptions: VueTypes.any.def([]),
    searchOptions: VueTypes.any.def([]),
    dateOptions: VueTypes.any.def({}),
    elements: VueTypes.any.def(["search", "limit", "sort"]),
    defaultVisible: VueTypes.bool.def(true),
  },
  computed: {
    filterObj(): unknown {
      return this.filter;
    },
    isSearch(): boolean {
      return this.exists("search") && this.searchOptions.length > 0;
    },
    isSort(): boolean {
      return this.exists("sort") && this.sortOptions.length > 0;
    },
    isLimit(): boolean {
      return this.exists("limit");
    },
    isDate(): boolean {
      return this.exists("date");
    },
  },
  methods: {
    updateFilter(obj: { key: string; val: any }) {
      const data: any = {};
      const { key, val } = obj;
      data[key] = val;
      // console.log(this.filter);
      this.$emit("changed", data);
    },
    exists(el: string): boolean {
      return this.elements.indexOf(el) >= 0;
    },
  },
  mounted() {
    // console.log(this.filter);
    this.hidden = !this.defaultVisible;
  },
});
