
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "TitleComp",
  data() {
    return {
      edit: false,
    };
  },
  props: {
    value: VueTypes.string.def(""),
    empty: VueTypes.string.def("Insert a new title..."),
    icon: VueTypes.string.def(""),
    editable: VueTypes.bool.def(false),
  },
  computed: {
    text: {
      get() {
        return this.value;
      },
      set(val: string) {
        this.$emit("input", val);
      },
    },
    titleIcon() {
      return this.text.length ? this.icon : "cancel";
    },
  },
  methods: {
    toggleEdit() {
      this.edit = !this.edit;
    },
  },
});
