import config from '../config'
import axios, { CancelToken } from 'axios'
// import router from '@/router'

const setToken = (headers: Headers = new Headers()) => {
  const token = localStorage.getItem('p3t')
  if (!token) {
    return headers
  }
  return {
    ...headers,
    Authorization: `Bearer ${token}`
  }
}

export const genCancelToken = () => {
  return axios.CancelToken.source();
}

const defaultHeaders: Headers = setToken();

export default class Fetcher {
  private api: string
  private headers: Headers

  constructor(api: string = config.API, headers: Headers = defaultHeaders) {
    this.api = api
    this.headers = headers
  }

  async get(route = '', headers: Headers | undefined = undefined, cancelToken: CancelToken | undefined = undefined) {
    headers = setToken(headers || this.headers)
    try {
      const { data } = await axios.get(`${this.api}/${route}`, { headers, cancelToken }).catch(e => errorHandle(e))
      return data?.data
    } catch (error) {
      return error
    }
  }

  async post(route = '', payload: any, headers: Headers | undefined = undefined, cancelToken: CancelToken | undefined = undefined) {
    headers = setToken(headers || this.headers)
    const data = await axios.post(`${this.api}/${route}`, payload, { headers, cancelToken }).catch(e => errorHandle(e))
    return data
  }

  async put(route = '', payload: any, headers: Headers | undefined = undefined, cancelToken: CancelToken | undefined = undefined) {
    headers = setToken(headers || this.headers)
    const data = await axios.put(`${this.api}/${route}`, payload, { headers, cancelToken }).catch(e => errorHandle(e))
    return data
  }

  async delete(route = '', headers: Headers | undefined = undefined, cancelToken: CancelToken | undefined = undefined) {
    headers = setToken(headers || this.headers)
    const data = await axios.delete(`${this.api}/${route}`, { headers, cancelToken }).catch(e => errorHandle(e))
    return data
  }

  async save(route: string, payload: any) {
    try {
      const { _id } = payload
      return await this.post(`api/${route}/${_id || ''}`, payload)
    } catch (error) {
      return error
    }
  }
  async fetchAll(route: string, filter: any) {
    try {
      const { data } = await this.post(`api/${route}/search`, filter)
      return data
    } catch (error) {
      return error
    }
  }
  async fetchByID(route: string, id: any) {
    try {
      return await this.get(`api/${route}/${id}`)
    } catch (error) {
      return error
    }
  }

}

const errorHandle = (e: any) => {
  const { status } = e.response
  switch (status) {
    case 401:
      console.error('Auth issue - redirect to login');
      setTimeout(() => {
        window.location.pathname = '/auth';
        // router.push('/auth');
      }, 0)
      break;
    case 500:
      console.error('API issue');
      setTimeout(() => {
        window.location.pathname = '/auth';
        // router.push('/auth');
      }, 0)
      break;

    default:
      break;
  }
  return e
}
