import { Fetcher } from "@/utils"
import { ResultsContext } from "@/types"

const api = new Fetcher()

export default {
  // Results
  fetchAllResults: async (context: ResultsContext, reset = false): Promise<void> => {
    try {
      const filter = context.getters.getFilter
      if (reset) {
        context.state.result.list = []
        context.state.result.total = 0
      }
      const { data } = await api.post(`api/results/search`, filter)
      const { results, total } = data
      context.state.result.list = results
      context.state.result.total = total
    } catch (error) {
      console.error(error);
    }
    // check extension existence
    context.commit('setExtension', undefined, { root: true })
  },
  fetchCurrentResult: async (context: ResultsContext, id: string): Promise<void> => {
    try {
      const data = await api.get(`api/results/${id}`)
      context.commit("setCurrentResult", data)
    } catch (error) {
      console.error(error);
    }
  },
}
