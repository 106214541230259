
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "ExitModal",

  data() {
    return {};
  },
  props: {
    msg: VueTypes.string.def("Discard Changes?"),
  },
  computed: {
    active: {
      get(): boolean {
        return this.$store.getters.getExitDialog;
      },
      set(val: boolean) {
        this.$store.commit("setExitDialog", val);
      },
    },
    to: {
      get(): string {
        return this.$store.getters.getExitDialogTo;
      },
      set(val: string) {
        this.$store.commit("setExitDialogTo", val);
      },
    },
  },
  methods: {
    routeOut() {
      this.$router.push(this.to);
      this.active = false;
    },
  },
});
