// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version } = require('../package.json')
console.log(`Env: ${process.env.NODE_ENV}`);
console.log(`Pine3 Version: ${version}`);

export default {
  SOCKET: process.env.VUE_APP_SOCKET || 'ws://localhost:3003',
  API: process.env.VUE_APP_API || 'http://localhost:3000',
  DOMAIN: process.env.VUE_APP_DOMAIN || 'http://localhost:6060',
  VERSION: version || '0.0.0',
}
