import { Country, DefaultFilter, Device, Plan, PlansState, Result, ResultData, SelectItem, Step } from "@/types"
import { flatRecursive } from "@/utils"

export default {
  // Plans & Tests
  getPlans: (state: PlansState): Plan[] => {
    return state.plan.list
  },
  getPlansTotal: (state: PlansState): number => {
    return state.plan.total
  },
  // getPlanDisplayTest: (state: PlansState): boolean => {
  //   return state.plan.displayStep
  // },
  // getPlanCurrentTest: (state: PlansState): string => {
  //   return state.plan.currentStep
  // },
  getPlanDisplayDebugger: (state: PlansState): boolean => {
    return state.plan.displayDebugger
  },
  getPlanCurrentDebugger: (state: PlansState): Result => {
    return state.plan.currentDebugger
  },
  getPlanCurrentDebuggerResult: (state: PlansState) => (id: string): ResultData | undefined => {
    const results = state.plan.currentDebugger
    let result;
    if (Object.keys(results).length && results.results.length) {
      const flat = flatRecursive(results.results);
      result = flat.find((res) => res.testId == id)
    }
    return result
  },
  getDebuggerRunning(_: PlansState, getters): boolean {
    return Object.keys(getters.getPlanCurrentDebugger).length > 0 && !getters.getPlanCurrentDebugger.complete;
  },
  getCurrentPlan: (state: PlansState): Plan => {
    return state.plan.currentPlan
  },
  getCurrentPlanChange: (state: PlansState): boolean => {
    return state.plan.changed
  },
  getCurrentPlanGlobalEnv: (state: PlansState): Plan => {
    return state.plan.currentPlan.env
  },
  getCurrentDragStep: (state: PlansState): string | null => {
    return state.plan.currentDragStep
  },
  getCurrentDragOverStep: (state: PlansState): string | null => {
    return state.plan.currentDragOverStep
  },
  getActiveStep: (state: PlansState) => (id: string): boolean => {
    return state.plan.currentStep === id
  },
  getLastResult: (state: PlansState): string | undefined => {
    const results = state.plan.currentDebugger
    let lastId
    if (Object.keys(results).length && results.results.length) {
      const flat = flatRecursive(results.results);
      lastId = (flat[flat.length - 1]).id;
    }
    return lastId
  },
  getSteps: (state: PlansState): (Step | any)[] => {
    return state.plan.steps
  },
  getDeviceList: (state: PlansState): Device[] => {
    return state.devices
  },
  getCountryList: (state: PlansState): Country[] => {
    return state.countries
  },
  getFilter: (state: PlansState): DefaultFilter => {
    return state.filter
  },
  getRoutines: (state: PlansState): SelectItem[] => {
    return state.routines
  },
  getAuthMethods: (state: PlansState): SelectItem[] => {
    return state.authMethods
  },
}
