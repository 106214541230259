import { DefaultSuite } from "@/defaults"
import { Suite, SuitesState } from "@/types"
import { filterRecursive } from "@/utils"
import { v4 } from "uuid"

export default {
  setCurrentSuite: (state: SuitesState, suite: Suite = DefaultSuite): void => {
    if (!suite._id) {
      suite.id = v4()
    }
    state.suites.currentSuite = { ...suite }
  },
  deleteSuite: (state: SuitesState, id: string): void => {
    state.suites.list = filterRecursive(state.suites.list, id)
  },
  changeSuiteProgress: (state: SuitesState, obj: { id: string, live: boolean }): void => {
    state.suites.list = state.suites.list.map((suite: Suite): Suite => {
      if (suite._id && suite._id === obj.id) {
        suite.live = obj.live || false
      }
      return suite
    })
  },
}
