
import Vue from "vue";
import ChartWrap from "../components/ChartWrap.vue";
import StatsCard from "../components/StatsCard.vue";

export default Vue.extend({
  name: "DashboardView",
  data() {
    return {
      filterToggle: false,
      filter: {
        limit: 30,
        date: {},
      },
      dateOptions: {
        dateField: "date",
      },
    };
  },
  components: {
    ChartWrap,
    StatsCard,
  },
  methods: {
    async fetchStats() {
      await this.$store.dispatch("fetchStats", this.filter);
    },
    changed() {
      this.fetchStats();
    },
  },
  computed: {
    stats() {
      return this.$store.getters.getStats;
    },
    labels() {
      return this.$store.getters.getLabels || [];
    },
    loading() {
      return this.$store.getters.getStatsLoader;
    },
    locations() {
      return this.$store.getters.getLocations || [];
    },
    testsRatio() {
      return this.$store.getters.getTestsRatio;
    },
    initiators() {
      return this.$store.getters.getInitiators;
    },
    testsOverTime() {
      return this.$store.getters.getTestsOverTime;
    },
    testsExecution() {
      return this.$store.getters.getTestsExecution;
    },
    usage() {
      return this.$store.getters.getUsage;
    },
  },
  async mounted() {
    this.$store.commit("setStatsLoader", true);
    await this.fetchStats();
    this.$store.commit("setStatsLoader");
  },
});
