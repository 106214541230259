
import { Msg } from "@/types";
import config from "@/config";
import { v4 } from "uuid";
import Vue from "vue";
import VueTypes from "vue-types";
import { capitalize, timeFormatter } from "@/utils";
import { DefaultFilterObject } from "@/defaults";
import Tooltip from "@/components/Tooltip.vue";
// import DeletePop from "../pop/DeletePop.vue";

export default Vue.extend({
  name: "ActionsBtn",
  data() {
    return {};
  },
  props: {
    action: VueTypes.any.def({}),
    item: VueTypes.any.def({}),
    scopeName: VueTypes.string.def(""),
  },
  methods: {
    capitalize,
    async deleteByID() {
      this.$store.commit("setGlobalLoader", true);
      const name = `${this.capitalize(this.scopeName)}/deleteById`;
      await this.$store.dispatch(name, this.item._id);
      this.$store.commit("setGlobalLoader");
    },
    setToDelete() {
      const title = `Delete "${this.item.name}" ${this.capitalize(
        this.scopeName
      )}`;
      const content = `Are you sure you with to delete <strong>"${this.item.name}"</strong>?`;
      const deleteDialog = {
        active: true,
        content,
        action: this.deleteByID,
        title,
      };
      this.$store.commit("setDeleteDialog", deleteDialog);
    },
    actionItem(type: string, id: string) {
      switch (type) {
        case "edit":
          this.$router.push({ path: `${this.$route.path}/edit/${id}` });
          break;
        case "view":
          this.$router.push({ path: `${this.$route.path}/view/${id}` });
          break;
        case "run":
          this.run(id);
          break;
        case "duplicate":
          this.duplicateByID(id);
          break;
        case "delete":
          this.setToDelete();
          break;
        default:
          break;
      }
    },
    async duplicateByID(id: string) {
      this.$store.commit("setGlobalLoader", true);
      const name = `${this.capitalize(this.scopeName)}/duplicateById`;
      await this.$store.dispatch(name, id);
      this.$store.commit("setGlobalLoader");
    },
    async run(id: string) {
      const data = await this.$store.dispatch(
        `${this.capitalize(this.scopeName)}/run`,
        {
          id,
        }
      );
      const msg: Msg = {
        id: v4(),
        icon: "flask",
        type: "success",
        color: "light-blue",
        ts: new Date().getTime(),
        content: `Test Case: <strong>"${
          data.name
        }"</strong> was running for <strong>${timeFormatter(
          data.duration
        )}</strong> was successfully completed.`,
        dismissible: true,
        timeout: 5000,
        notify: true,
      };
      if (!data.success) {
        msg.type = "error";
        msg.content = `Test Case: "${
          data.name
        }" was running for ${timeFormatter(
          data.duration
        )} and failed to be completed.`;
      }
      msg.content += `<strong> <a class="link" href="${
        config.DOMAIN
      }/results/view/${data._id || data._id}"> View results</a></strong>`;
      this.$store.dispatch("popMsg", msg, { root: true });
      this.$store.dispatch(
        "Results/fetchAllResults",
        { ...DefaultFilterObject, date: {} },
        { root: true }
      );
    },
  },
  computed: {
    tooltipContent(): string {
      return this.action
        ? (typeof this.action?.tooltip == "function"
            ? this.action.tooltip(this.item)
            : this.action.tooltip) || this.action.name
        : "";
    },
    medium(): boolean {
      return this.action.medium || false;
    },
  },
  components: { Tooltip },
});
