import { DefaultFilterObject } from "@/defaults"
import { DefaultFilter, Result, ResultsState } from "@/types"

export default {
  // Results
  setCurrentResult: (state: ResultsState, result: Result | any = {}): void => {
    state.result.currentResult = result
  },
  setCurrentResultView: (state: ResultsState, result: Result | any = {}): void => {
    state.result.currentResultView = {}
    state.result.currentResultView = result
  },
  setFilter: (state: ResultsState, filter: DefaultFilter = DefaultFilterObject): void => {
    state.filter = filter
  },
}
