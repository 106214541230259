import { DefaultGroup } from "@/defaults"
import { Group, GroupsState } from "@/types"
import { filterRecursive } from "@/utils"
import { v4 } from "uuid"

export default {
  setCurrentGroup: (state: GroupsState, group: Group = DefaultGroup): void => {
    if (!group._id) {
      group.id = v4()
    }
    state.groups.currentGroup = { ...group }
  },
  setGroupContacts: (state: GroupsState, contacts: any[]): void => {
    state.contacts = contacts
  },
  deleteGroup: (state: GroupsState, id: string): void => {
    state.groups.list = filterRecursive(state.groups.list, id)
  },
}
