import { render, staticRenderFns } from "./Slogen.vue?vue&type=template&id=2528fd26&scoped=true&"
import script from "./Slogen.vue?vue&type=script&lang=ts&"
export * from "./Slogen.vue?vue&type=script&lang=ts&"
import style0 from "./Slogen.vue?vue&type=style&index=0&id=2528fd26&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2528fd26",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VRow})
