
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "SearchComp",

  data() {
    return {
      timer: 0,
      // value: "",
      // field: "",
    };
  },
  props: {
    value: VueTypes.any.def({}),
    items: VueTypes.any.def([]),
  },
  methods: {
    changed() {
      const val: any = {};
      val[this.field] = this.phrase;
      this.$emit("changed", { key: "search", val });
      this.$emit("input", { [this.field]: this.phrase });
    },
    searchEvent(val: string) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = 0;
      }
      this.timer = setTimeout(() => {
        this.$emit("input", { [this.field]: val });
      }, 700);
    },
  },
  computed: {
    search: {
      get(): unknown {
        return this.value;
      },
      set(val: unknown) {
        this.$emit("input", val);
      },
    },
    field: {
      get(): string {
        const field = `${Object.keys(this.search)[0]}`;
        return field;
      },
      set(field: string) {
        this.$emit("input", { [field]: this.phrase });
      },
    },
    phrase: {
      get(): string {
        const val = `${Object.values(this.search)[0]}`;
        return val;
      },
      set(val: string) {
        this.searchEvent(val);
      },
    },
  },
});
