
import moment from "moment";
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "DatePicker",

  data() {
    return {
      dates: [] as string[],
      menu: false,
      field: "",
      fieldType: "All Data",
      custom: false,
    };
  },
  props: {
    current: VueTypes.bool.def(true),
    ranged: VueTypes.bool.def(true),
    scrollable: VueTypes.bool.def(false),
    type: VueTypes.string.def("date"),
    options: VueTypes.any.def({ dateField: "date" }),
  },
  computed: {
    dateRangeText(): string {
      if (!this.custom && this.fieldType) {
        return this.fieldType;
      }
      const dates: string[] = this.sort(this.dates)
        .filter((e: string | number, i: number, s: any[]) => i === s.indexOf(e))
        .map((d: string | number) => String(d))
        .map((d: string) => d.split("-").reverse().join("/"));
      return dates.length ? dates.join(" - ") : "Select Time-Range";
    },
  },
  methods: {
    headerFormat(): string {
      return this.dateRangeText;
    },
    sort(dates: (string | number)[]) {
      let sorted = [...dates];
      if (sorted.length == 1) {
        sorted[1] = sorted[0];
      } else {
        sorted = sorted.sort((a, b) => {
          if (moment(a).valueOf() < moment(b).valueOf()) {
            return -1;
          }
          if (moment(a).valueOf() > moment(b).valueOf()) {
            return 1;
          }
          return 0;
        });
      }
      // .filter((e: string, i: number, s: string[]) => i === s.indexOf(e))
      return [...sorted];
    },
    presetTime(type = "24") {
      let start, end;
      const dates = [];
      switch (type) {
        case "today":
          // epoch for today
          start = moment().startOf("day").valueOf();
          end = moment().valueOf();
          this.fieldType = "Today";
          break;
        case "yesterday":
          // set both 0,1 to same date string format YYYY-MM-DD
          start = moment().subtract(1, "days").format("YYYY-MM-DD");
          end = start;
          this.fieldType = "Yesterday";
          break;
        case "start_week":
          // set both 0,1 to same date string format YYYY-MM-DD
          start = moment().startOf("week").format("YYYY-MM-DD");
          end = moment().format("YYYY-MM-DD");
          this.fieldType = "Start of week";
          break;
        case "last_week":
          // set both 0,1 to same date string format YYYY-MM-DD
          start = moment()
            .subtract(1, "week")
            .startOf("week")
            .format("YYYY-MM-DD");
          end = moment().subtract(1, "week").endOf("week").format("YYYY-MM-DD");
          this.fieldType = "Last week";
          break;
        case "start_month":
          // set both 0,1 to same date string format YYYY-MM-DD
          start = moment().startOf("month").format("YYYY-MM-DD");
          end = moment().format("YYYY-MM-DD");
          this.fieldType = "Start of month";
          break;
        case "last_month":
          // set both 0,1 to same date string format YYYY-MM-DD
          start = moment()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD");
          end = moment()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD");
          this.fieldType = "Last month";
          break;

        default:
          start = moment("0").valueOf();
          end = moment().valueOf();
          this.fieldType = "All Data";
          break;
      }
      dates[0] = start || moment().subtract(1, "days").valueOf();
      dates[1] = end || moment().valueOf();
      this.changed("_", dates);
    },
    changed(_: any, givenDates?: (string | number)[]) {
      let dates: (string | number)[] = [...this.dates];
      if (givenDates && givenDates.length) {
        dates = [...givenDates];
        this.custom = false;
      } else {
        this.fieldType = "";
        this.custom = true;
      }
      // sorting method
      dates = this.sort(dates);
      const menu: any = this.$refs.menu;
      menu.save();
      this.$emit("changed", {
        key: "date",
        val: this.setTimeObject(dates, this.field),
      });
    },
    setTimeObject(dates: (string | number)[], field: string) {
      let start = dates[0],
        end = dates[1];
      if (typeof start === "string") {
        this.dates[0] = String(start);
        this.dates[1] = String(end);
        start = moment(start).startOf("day").valueOf();
        end = moment(end).endOf("day").valueOf();
      } else {
        this.dates[0] = moment(start).format("YYYY-MM-DD");
        this.dates[1] = moment(end).format("YYYY-MM-DD");
      }
      // this.dates = dates;
      const dateObj = {
        [field]: {
          $gte: start,
          $lte: end,
        },
      };
      return dateObj;
    },
  },
  mounted() {
    // initiate default field and type
    this.field = this.options.dateField;
  },
});
