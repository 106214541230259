
import Vue from "vue";
import VueTypes from "vue-types";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  name: "KnobBtnComp",

  data() {
    return {};
  },
  props: {
    value: VueTypes.any,
    left: VueTypes.bool.def(true),
    disabled: VueTypes.bool.def(false),
    icon: VueTypes.string.def(""),
    text: VueTypes.string.def(""),
    cols: VueTypes.string.def("auto"),
    color: VueTypes.string.def("success"),
    tooltip: VueTypes.string,
  },
  computed: {
    knob: {
      get() {
        return this.value;
      },
      set(val: boolean) {
        this.$emit("input", val);
      },
    },
  },
  components: { Tooltip },
});
