import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import { RoleType, UsersState } from '@/types'

export default {
  namespaced: true,
  state: (): UsersState => {
    return {
      role: null,
      user: {
        id: '',
        avatar: '',
        name: '',
        email: '',
        active: true,
        role: RoleType.USER,
        password: ''
      },
      users: {
        currentUser: {},
        list: [],
        total: 0
      },
      groups: [],
      labels: []
    }
  },
  actions,
  mutations,
  getters
}
