import { DefaultFilterObject, DefaultPlan } from "@/defaults"
import { Country, DefaultFilter, Device, ListObj, Plan, PlansState, Result, Step } from "@/types"
import { checkObjDiff, cloneObj, filterRecursive, pushInPos } from "@/utils"
import { v4 } from "uuid"

export default {
  // Plans
  // setPlanDisplayTest: (state: PlansState, value: boolean): void => {
  //   state.plan.displayStep = value
  // },
  setPlanCurrentStep: (state: PlansState, id = ''): void => {
    state.plan.currentStep = id
  },
  setCurrentDragStep: (state: PlansState, value: { step: Step, pos: number[], before?: boolean } | null = null): void => {
    state.plan.currentDragStep = value
  },
  setCurrentDragOverStep: (state: PlansState, value: { step: Step, pos: number[], before?: boolean, child?: boolean } | null = null): void => {
    state.plan.currentDragOverStep = value
  },
  setDragAndDrop: (state: PlansState): void => {
    const drag = { ...state.plan.currentDragStep }
    const drop = { ...state.plan.currentDragOverStep }
    // get root test from plan
    let current = state.plan.currentPlan.steps
    // create a copy of the step
    const step = cloneObj(drag.step)
    step.id = v4()
    // push test step behind the drag over step position
    const { pos, before, child } = drop

    current = pushInPos(current, pos, step, before, child)
    // remove test from old position
    current = filterRecursive(current, drag.step.id)
    // null drag & over steps
    state.plan.currentDragStep = null
    state.plan.currentDragOverStep = null
    state.plan.currentPlan.steps = current
  },
  setPlanDisplayDebugger: (state: PlansState, value: boolean): void => {
    state.plan.displayDebugger = value
  },
  setPlanCurrentDebugger: (state: PlansState, result: Result | any = {}): void => {
    state.plan.currentDebugger = {}
    if (Object.keys(result).length) {
      state.plan.currentDebugger = result
    }
  },
  // updatePlanCurrentTest: (state: PlansState, step: Step | any = {}): void => {
  //   state.plan.currentPlan.steps = updateRecursive(state.plan.currentPlan.steps, test)
  // },
  setCurrentPlanGlobalEnv: (state: PlansState, env: ListObj[]): void => {
    state.plan.currentPlan.env = env
  },
  setCurrentPlan: (state: PlansState, plan: Plan = DefaultPlan): void => {
    if (!plan._id) {
      plan.id = v4()
    }
    state.plan.currentPlan = {}
    state.plan.currentPlan = { ...plan }
    state.plan.currentPlanSnapshot = ''
    state.plan.currentPlanSnapshot = JSON.stringify(plan)
  },
  setCurrentPlanChange: (state: PlansState, val = true): void => {
    state.plan.changed = val
  },
  updatePlanSnapshot: (state: PlansState): void => {
    state.plan.currentPlanSnapshot = ''
    state.plan.currentPlanSnapshot = JSON.stringify(state.plan.currentPlan)
  },
  checkCurrentPlanChange: (state: PlansState): void => {
    const same = checkObjDiff(state.plan.currentPlan, state.plan.currentPlanSnapshot)
    state.plan.changed = !same
  },
  deleteStep: (state: PlansState, id: string): void => {
    state.plan.currentPlan.steps = filterRecursive(state.plan.currentPlan.steps, id)
  },
  deletePlan: (state: PlansState, id: string): void => {
    state.plan.list = filterRecursive(state.plan.list, id)
  },
  changePlanProgress: (state: PlansState, obj: { id: string, live: boolean }): void => {
    state.plan.list = state.plan.list.map((plan: Plan): Plan => {
      if (plan._id && plan._id === obj.id) {
        plan.live = obj.live || false
      }
      return plan
    })
  },
  setDeviceList: (state: PlansState, devices: Device[]): void => {
    state.devices = devices
  },
  setCountryList: (state: PlansState, countries: Country[]): void => {
    state.countries = countries
  },
  setFilter: (state: PlansState, filter: DefaultFilter = DefaultFilterObject): void => {
    state.filter = filter
  },
}
