import { Group, User, UsersState } from "@/types"

export default {
  getRole(state: UsersState): number | null {
    return state.role
  },
  getId(state: UsersState): string | undefined {
    return state.user.id
  },
  getUser(state: UsersState): User {
    return state.user
  },
  getUserLabels(state: UsersState): string[] {
    return state.labels
  },
  getUserGroup(state: UsersState): string | undefined {
    return state.user.groupName
  },
  getUserGroupId(state: UsersState): string | undefined {
    return state.user.group
  },
  isSelf(state: UsersState): boolean {
    const self = state.users.currentUser._id === state.user.id
    return self
  },
  getUsers: (state: UsersState): User[] => {
    return state.users.list
  },
  getGroups: (state: UsersState): Group[] => {
    return state.groups
  },
  getUsersTotal: (state: UsersState): number => {
    return state.users.total
  },
  getCurrentUser: (state: UsersState): User => {
    return state.users.currentUser
  },
  getCurrentUserId(state: UsersState): string {
    return state.users.currentUser._id
  },
}
