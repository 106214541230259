
import Vue from "vue";
import VueTypes from "vue-types";
import { Chart } from "highcharts-vue";

export default Vue.extend({
  name: "ChartWrap",
  props: {
    chartData: VueTypes.any.def({}),
    col: [Number, String, Boolean],
    elevation: VueTypes.number.def(3),
    loading: VueTypes.bool.def(false),
    title: VueTypes.string.def(""),
    type: VueTypes.string.def(""),
  },
  data() {
    return {};
  },
  components: { Chart },
  computed: {
    chartOptions() {
      const {
        type,
        title,
        series,
        xAxis,
        yAxis,
        colors,
        height,
        plotOptions,
        tooltip,
        legend,
        pane,
      } = this.chartData;
      // const theme: string = this.$vuetify.theme.isDark ? "dark" : "light";
      // console.log(this.$vuetify.theme);
      // const colors = [];
      // for (const c of this.colors) {
      //   colors.push(this.$vuetify.theme?.defaults[theme][c])
      // }

      const obj = {
        chart: { type: this.type || type, height: height || "auto" },
        colors,
        plotOptions: plotOptions || {},
        credits: { enabled: false },
        series,
        xAxis,
        yAxis,
        pane,
        tooltip: tooltip || {},
        legend: legend || {
          align: "right",
          itemStyle: {
            position: "absolute",
            top: -200,
          },
        },
        title: title || {
          style: {
            // color: primaryText,
            align: "left",
          },
          align: "left",
          text: this.title || "",
        },
      };
      // console.log(this.chartData, obj);
      return obj;
    },
  },
});
