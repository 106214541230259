
import { WaitFor } from "@/types";
import Vue from "vue";
import VueTypes from "vue-types";
import Tooltip from "./Tooltip.vue";

export default Vue.extend({
  name: "WaitFor",
  data() {
    return {
      waitMethods: [
        { divider: true, header: "Wait For:" },
        { text: "Sleep", value: "sleep", icon: "sleep" },
        { text: "Element Appearance", value: "element", icon: "eye" },
        { text: "Event Triggered", value: "event", icon: "calendar-clock" },
        { text: "Function to execute", value: "function", icon: "function" },
        { divider: true, header: "Load Events:" },
        {
          text: "Document Content",
          value: "content",
          icon: "code-tags",
        },
        { text: "Navigation To End", value: "nav", icon: "cloud-tags" },
        {
          text: "Network To End",
          value: "network",
          icon: "access-point-check",
        },
      ],
      elementStatus: [
        { text: "Visible", value: "visible", icon: "eye" },
        { text: "Hidden", value: "hidden", icon: "eye-off" },
        { text: "Exists", value: "attached", icon: "table" },
        { text: "Not Exists", value: "detached", icon: "table-off" },
      ],
    };
  },
  props: {
    value: VueTypes.any.def({}),
    disabled: VueTypes.bool.def(false),
  },
  methods: {
    changed() {
      this.$emit("input", this.obj);
    },
  },
  computed: {
    obj: {
      get(): WaitFor {
        console.log(this.value);

        return this.value;
      },
      set(val: WaitFor | undefined) {
        this.changed(val);
      },
    },
    deactivated() {
      return this.disabled;
    },
    selectedIcon(): string {
      const val = this.obj.method;
      const method = this.waitMethods.find((m) => m.value == val);
      return `mdi-${method.icon}` || "mdi-sleep";
    },
  },
  updated() {
    this.changed();
  },
  components: { Tooltip },
});
