
import { Result } from "@/types";
import { genCancelToken } from "@/utils/fetcher";
import { CancelTokenSource } from "axios";
import Vue from "vue";
import VueTypes from "vue-types";
import ResultLayout from "./ResultLayout.vue";

export default Vue.extend({
  components: {  ResultLayout },
  name: "DebuggerView",

  data() {
    return {
      ct: this.cancelTokenSource as CancelTokenSource,
    };
  },
  props: {
    plan: VueTypes.any,
    cancelTokenSource: VueTypes.any,
    isolated: VueTypes.bool.def(false),
    loading: VueTypes.bool.def(false),
  },
  computed: {
    dialog(): boolean {
      return this.$store.getters["Plans/getPlanDisplayDebugger"];
    },
    result(): Result {
      return this.$store.getters["Plans/getPlanCurrentDebugger"];
    },
    cancelToken: {
      get(): CancelTokenSource {
        return this.ct;
      },
      set(val: CancelTokenSource) {
        this.ct = val;
      },
    },
  },
  methods: {
    closeView(): void {
      this.$store.commit("Results/setCurrentResultView");
    },
    close() {
      this.closeView();
      this.$store.commit("Plans/setPlanDisplayDebugger", false);
      this.$store.commit("Results/setCurrentResultView");
      this.$store.commit("Plans/setPlanCurrentDebugger");
    },
    async runPlan() {
      this.$store.commit("Results/setCurrentResultView");
      this.$store.commit("Plans/setPlanCurrentDebugger");
      this.cancelToken = genCancelToken();
      const results = await this.$store.dispatch("Plans/run", {
        id: this.plan._id,
        debug: true,
        cancelToken: this.cancelToken.token,
      });
      this.$store.commit("Plans/setPlanCurrentDebugger", results);
    },
  },
  destroyed() {
    this.cancelToken.cancel();
  },
});
