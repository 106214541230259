
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "ActionsIcon",
  data() {
    return {};
  },
  props: {
    action: VueTypes.any.def({}),
  },
});
