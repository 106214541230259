
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "DrillLink",

  data() {
    return {};
  },
  props: {
    to: VueTypes.string.def("/"),
    icon: VueTypes.string.def(""),
    text: VueTypes.string.def(""),
    fab: VueTypes.bool.def(false),
  },
});
