
import Vue from "vue";
import Slogen from "@/components/Slogen.vue";

export default Vue.extend({
  name: "DevView",
  data() {
    return {};
  },
  computed: {
    title() {
      return this.$route.name || "";
    },
  },
  components: { Slogen },
});
