
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "DeletePop",

  data() {
    return {
      selected: [],
    };
  },
  props: {
    persist: VueTypes.bool.def(false),
  },
  computed: {
    modal(): any {
      return this.$store.getters.getDeleteDialog;
    },
    active: {
      get(): boolean {
        return this.modal.active;
      },
      set(val: boolean) {
        this.$store.commit("setDeleteDialogActive", val);
      },
    },
    content(): string {
      return this.modal.content;
    },
    title(): string {
      return this.modal.title;
    },
    action(): any {
      return this.modal.action;
    },
    buttons(): any[] {
      return [
        {
          text: "Cancel",
          color: "primary",
          outlined: true,
          action: this.close,
          icon: "cancel",
        },
        {
          text: "Delete",
          color: "error",
          icon: "delete-empty",
          action: this.runAction,
        },
      ];
    },
  },
  methods: {
    runAction() {
      this.action();
      this.close();
    },
    close() {
      this.active = false;
    },
  },
});
