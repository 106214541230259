import { ChartObj, GeneralState, Msg, SelectItem } from "@/types"
import { objNotEmpty } from "@/utils"

export default {
  // Stats
  getStats: (state: GeneralState): any => {
    return state.stats
  },
  getStatsLoader: (state: GeneralState): boolean => {
    return state.stats.loading
  },
  getTestsRatio: (state: GeneralState): ChartObj => {
    return state.stats.testsRatio
  },
  getInitiators: (state: GeneralState): ChartObj => {
    return state.stats.initiators
  },
  getTestsExecution: (state: GeneralState): ChartObj => {
    return state.stats.testsExecution
  },
  getTestsOverTime: (state: GeneralState): ChartObj => {
    return state.stats.testsOverTime
  },
  getUsage: (state: GeneralState): ChartObj => {
    return state.stats.usage
  },
  getPackages: (state: GeneralState): SelectItem[] => {
    return state.packages
  },
  // General
  getDisplayExpand: (state: GeneralState): boolean => {
    return state.display.expand
  },
  getGlobalLoader: (state: GeneralState): boolean => {
    return state.display.loader
  },
  getMessages: (state: GeneralState): Msg[] => {
    return state.messages
  },
  getMessagesActive: (state: GeneralState): boolean => {
    return objNotEmpty(state.messages);
  },
  getNotifications: (state: GeneralState): Msg[] => {
    if (!state.notifications.length) {
      state.notifications = JSON.parse(localStorage.getItem('p3n') || '[]')
    }
    return state.notifications
  },
  getExtension: (state: GeneralState): boolean => {
    return state.extension
  },
  getSlides: (state: GeneralState): string[] => {
    return state.slides.list
  },
  getSlidesOptions: (state: GeneralState): any => {
    return state.slides.options
  },
  getResponse: (state: GeneralState): any => {
    return state.response.data
  },
  getResponseOptions: (state: GeneralState): any => {
    return state.response.options
  },
  getLastRoute: (state: GeneralState): string => {
    return state.lastRoute
  },
  getTitle: (state: GeneralState): string => {
    return state.title
  },
  getRoles: (state: GeneralState): SelectItem[] => {
    return state.roles
  },
  // import dialog
  getImportDialog: (state: GeneralState): any => {
    return state.importDialog
  },
  // exit dialog
  getExitDialog: (state: GeneralState): boolean => {
    return state.exitDialog.active
  },
  getExitDialogTo: (state: GeneralState): string => {
    return state.exitDialog.to
  },
  // delete dialog
  getDeleteDialog: (state: GeneralState): any => {
    return state.deleteDialog
  },
}
