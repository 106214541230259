
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "NextBtn",

  data() {
    return {};
  },
  props: {
    tab: VueTypes.oneOfType([String, Number]).def(1),
    color: VueTypes.string.def("success"),
    notPlan: VueTypes.bool.def(false),
    nextBtn: VueTypes.bool.def(true),
    plan: VueTypes.any.def({}),
    next: VueTypes.func,
  },
  computed: {
    disabled(): boolean {
      let disabled = false;
      if (!Object.keys(this.plan).length) {
        return disabled;
      }
      switch (this.step) {
        case 1:
          if (!this.plan.name) {
            disabled = true;
          }
          break;
        case 2:
          if (!this.plan.name || !this.plan.steps?.length) {
            disabled = true;
          }
          break;

        default:
          disabled = false;
          break;
      }
      return disabled;
    },
    label(): string {
      let label = "";
      switch (this.step) {
        case 1:
          label = "Start Planning";
          break;
        case 2:
          label = this.notPlan ? "Save" : "Hooks";
          break;
        case 3:
          label = "Save";
          break;

        default:
          label = "";
          break;
      }
      return label;
    },
    icon(): string {
      let icon = "";
      switch (this.step) {
        case 1:
          icon = "flask";
          break;
        case 2:
          icon = this.notPlan ? "content-save-move" : "webhook";
          break;
        case 3:
          icon = "content-save-move";
          break;

        default:
          icon = "";
          break;
      }
      return icon;
    },
    step: {
      get(): number {
        return +this.tab;
      },
      set(val: number): void {
        this.$emit("changed", +val);
      },
    },
  },
  methods: {
    defNext() {
      if (this.next) {
        this.next();
      } else {
        this.step++;
      }
    },
  },
});
