
import Vue from "vue";
import VueTypes from "vue-types";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  name: "AddList",
  data() {
    return {
      line: {},
      dragged: null as unknown,
      overCurrent: null as unknown,
    };
  },
  props: {
    value: VueTypes.any.def([]),
    fields: VueTypes.any.def([
      {
        type: "text",
        name: "field",
        label: "Field",
      },
    ]),
    changedRef: VueTypes.string.def(""),
    title: VueTypes.string.def(""),
    icon: VueTypes.string.def(""),
    iconActive: VueTypes.string.def("info"),
    addIcon: VueTypes.string.def("plus-circle"),
    itemIcon: VueTypes.string.def("drag-vertical"),
    deleteIcon: VueTypes.string.def("delete"),
    deleteMsg: VueTypes.string.def("Delete"),
    emptyMsg: VueTypes.string.def(""),
    disabled: VueTypes.bool.def(false),
    draggable: VueTypes.bool.def(true),
    outlined: VueTypes.bool.def(false),
    objList: VueTypes.bool.def(false),
    chips: VueTypes.bool.def(false),
  },
  computed: {
    list: {
      get() {
        return this.value;
      },
      set(val: unknown[]) {
        this.$emit("input", val);
      },
    },
    valid() {
      for (const field of this.fields) {
        // console.log(this.line[field.name].length);

        if (!this.line[field.name]?.length && !this.line[field.name]?.size) return false;
      }
      return true;
    },
    objMod(): boolean {
      return this.fields?.length > 1 || this.objList;
    },
    chipsMod(): boolean {
      return !this.objMod && this.chips;
    },
    deactivated(): boolean {
      return this.disabled;
    },
  },
  methods: {
    add() {
      if (this.valid) {
        console.log(this.line);

        if (this.objMod) {
          this.list.push({ ...this.line });
        } else {
          this.list.push(...Object.values(this.line));
        }
        // clean the line values
        for (const key of Object.keys(this.line)) {
          this.line[key] = "";
        }
        this.changed();
      }
    },
    log() {
      console.log(this.line);
    },
    remove(i: number) {
      if (this.list.length >= i + 1) {
        this.list.splice(i, 1);
      }
      this.changed();
    },
    drag(i: number) {
      if (this.draggable) {
        this.dragged = i;
      }
    },
    over(i: number) {
      if (i !== this.overCurrent) {
        this.overCurrent = i;
      }
    },
    drop(item: unknown) {
      if (this.draggable) {
        this.list.splice(this.dragged, 1);
        this.list.splice(this.overCurrent, 0, item);
        this.overCurrent = null;
        this.dragged = null;
        this.changed();
      }
    },
    changed() {
      if (this.changedRef) {
        this.$emit("changed", { [this.changedRef]: this.list });
      } else {
        this.$emit("changed", this.list);
      }
      this.$emit("input", this.list);
    },
  },
  components: { Tooltip },
});
