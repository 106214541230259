
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "ActionsProgress",
  data() {
    return {};
  },
  props: {
    item: VueTypes.any.def({}),
  },
  methods: {},
  computed: {
    progress(): number {
      return this.item.progress || 0;
    },
    inactive(): boolean {
      return this.item.progress === 0;
    },
  },
  components: {},
});
