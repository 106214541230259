
import Vue from "vue";
import VueTypes from "vue-types";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  components: { Tooltip },
  name: "SortComp",

  data() {
    return {
      // value: "",
      // order: 1,
    };
  },
  props: {
    value: VueTypes.any.def({}),
    items: VueTypes.any.def([]),
  },
  methods: {
    changed() {
      const val: any = {};
      val[this.field] = this.order;
      this.$emit("changed", { key: "sort", val });
      this.$emit("input", { [this.field]: this.order });
    },
    titled(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  computed: {
    sort: {
      get(): unknown {
        return this.value;
      },
      set(val: unknown) {
        this.$emit("input", val);
      },
    },
    field: {
      get(): string {
        const field = `${Object.keys(this.sort)[0]}`;
        return field;
      },
      set(field: string) {
        this.$emit("input", { [field]: this.order });
      },
    },
    order: {
      get(): number {
        const val = +Object.values(this.sort)[0];
        return val;
      },
      set(val: number) {
        console.log(val);
        this.$emit("input", { [this.field]: val });
      },
    },
  },
});
