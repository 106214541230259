
import Vue from "vue";
import ListTable from "../components/listTable/ListTable.vue";
import AddBtn from "../components/buttons/AddBtn.vue";
import FilterBar from "../components/Filter/FilterBar.vue";
import {
  DefaultActionDelete,
  DefaultActionEdit,
  DefaultFilterObject,
} from "../defaults";
import { Group, Pagination } from "../types";
import { updateObj } from "@/utils";

export default Vue.extend({
  name: "GroupsView",
  components: {
    ListTable,
    AddBtn,
    FilterBar,
  },
  data() {
    return {
      headers: [
        // { text: "", value: "avatar", type: "avatar" },
        {
          text: "Group Name",
          value: "name",
          to: (item: Group): string => {
            return `${this.$route.path}/edit/${item._id}`;
          },
        },
        { text: "Package", value: "package", type: "text" },
        { text: "Created At", value: "date", type: "date" },
        { text: "Active", value: "active", type: "active" },
      ],
      actions: [DefaultActionEdit, DefaultActionDelete],
      filter: DefaultFilterObject,
      sortOptions: [
        { text: "Group Name", value: "name" },
        { text: "Created At", value: "date" },
        { text: "Active", value: "active" },
      ],
      searchOptions: [{ text: "Name", value: "name" }],
      options: {},
      loading: true,
    };
  },
  methods: {
    async fetchList() {
      this.loading = true;
      await this.$store.dispatch("Groups/fetchGroups", this.filter);
      this.loading = false;
    },
    pageChange(val: number): void {
      const { limit } = this.filter;
      const skip = (val - 1) * limit;
      this.filter.skip = skip;
      this.changed();
    },
    changed(data: unknown | null) {
      if (data) {
        this.filter = updateObj(this.filter, data);
      }
      this.fetchList();
    },
  },
  computed: {
    items() {
      return this.$store.getters["Groups/getGroups"];
    },
    total() {
      return this.$store.getters["Groups/getGroupsTotal"];
    },
    pagination(): Pagination {
      const { limit } = this.filter;
      const count = this.total;
      const total = Math.ceil(count / limit);
      return { total, current: 1, visible: 7, count, limit };
    },
  },
  created() {
    this.changed();
  },
});
