
import { Msg } from "@/types";
import { v4 } from "uuid";
import Vue from "vue";
import VueTypes from "vue-types";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  components: { Tooltip },
  name: "CopyToClip",

  data() {
    return {};
  },
  props: {
    fab: VueTypes.bool.def(true),
    text: VueTypes.string.def(""),
    color: VueTypes.string.def("accent"),
    icon: VueTypes.string.def("content-copy"),
    tooltip: VueTypes.string.def("Copy to Clipboard"),
    pos: VueTypes.string.def("top"),
    size: VueTypes.string.def(""),
  },
  methods: {
    copy() {
      const el = document.createElement("textarea");
      el.value = this.text;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const msg: Msg = {
        id: v4(),
        type: "info",
        icon: "clone",
        content: `Data was copied to clipboard successfully.`,
        dismissible: true,
        timeout: 3000,
      };
      const getSelection = document.getSelection();
      if (getSelection) {
        const selected =
          getSelection.rangeCount > 0 ? getSelection.getRangeAt(0) : false;
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
        if (selected && getSelection) {
          getSelection.removeAllRanges();
          getSelection.addRange(selected);
        }

        this.$store.dispatch("popMsg", msg);
      } else {
        msg.type = "error";
        msg.content = "Unable to copy to clipboard.";
        this.$store.dispatch("popMsg", msg);
      }
    },
  },
});
