
import { TopicItem } from "@/types";
import Vue from "vue";
import VideoPlayer from "../components/VideoPlayer.vue";
import TopicConetnt from "@/components/TopicConetnt.vue";
import { v4 } from "uuid";
import { scrollToID } from "@/utils";

export default Vue.extend({
  name: "KnowledgeCenter",
  data() {
    return {
      items: [
        {
          id: v4(),
          name: "Test Cases",
          children: [
            {
              id: "new_plan",
              name: "Start a new Test Case",
              icon: "flask-plus",
              video: "new_test_plan.mp4",
              selected: true,
              content: [
                {
                  type: "text",
                  data: `Test Cases are the core of Pine3 and your automation environment.`,
                },
                {
                  type: "list",
                  title: "Instructions:",
                  data: [
                    `1. Click 'Test Cases' on the side-bar to enter the Test Case's section`,
                    `2. Click on the "Add" button`,
                    `3. Name your Test Case (mandatory)`,
                    `4. Set Test Case's version (optional - good for tracking your product)`,
                    `5. Now you can start building your by adding the first step or importing from existing plan`,
                  ],
                },
              ],
            },
            {
              id: "first_ui_step",
              icon: "test-tube",
              name: "First UI Step",
              video: "first_step.mp4",
              content: [
                {
                  type: "text",
                  data: `When writing UI Test Cases - There are several key rules to be aware of.`,
                },
                {
                  type: "list",
                  title: "Rules:",
                  data: [
                    `1. The first step in a UI test flow must always be "Go To" step - that's your UI Test Case starting point.`,
                    `2. When we add a "Go To" step - the next steps must be children of it in order to inherit the right context`,
                  ],
                },
                {
                  type: "text",
                  data: `<br>When writing UI Test Cases - There are several key rules to be aware of.`,
                },
              ],
            },
            {
              id: "debugger",
              icon: "bug",
              name: "The Debugger",
              video: "test_plan_debugger.mp4",
              content: [
                {
                  type: "text",
                  data: `Once the Test Case was created, the debugger will appear on "Edit" mode <br>
                  This will help you write your Test Cases faster and change them in real time.`,
                },
                {
                  type: "list",
                  title: "Instructions:",
                  data: [
                    `1. While editing your Test Case - go the the "Build" section.`,
                    `2. After adding/editing your Test Case's flow - save your changes by clicking on the "Update" button.`,
                    `3. Once the Test Case is updated - the "Run Test Case" button will be available to run your test.`,
                    `4. Running the debugger will start your updated Test Case in real time and will show your Plan's progress.`,
                    `5. Once the debugger your equivalent results are align with your Test Case's steps`,
                  ],
                },
              ],
            },
            // {
            //   id: "first_api_step",
            //   icon: "server",
            //   name: "First API Step",
            //   video: "first_step.mp4",
            //   content: [
            //     {
            //       type: "text",
            //       data: `O`,
            //     },
            //     {
            //       type: "text",
            //       data: `Test Cases are the core of Pine3 and your automation environment.`,
            //     },
            //     {
            //       type: "list",
            //       title: "Instructions:",
            //       data: [
            //         `Test Cases are the core of Pine3 and your automation environment.`,
            //       ],
            //     },
            //   ],
            // },
          ],
        },
        {
          id: 2,
          name: "Test Suites",
          children: [],
        },
      ],
      panel: 0,
    };
  },
  methods: {
    fetchVideo(video: string) {
      return require(`@/assets/video/${video}`);
    },
    openTopic(id: string[]) {
      this.panel = this.topics.map((t: TopicItem) => t.id).indexOf(id[0]);
      scrollToID(`topic_${id[0]}`);
    },
  },
  computed: {
    topics() {
      let children: TopicItem[] = [];
      this.items.forEach((i: TopicItem) => {
        children.push(...i.children);
      });
      return children;
    },
  },
  components: { VideoPlayer, TopicConetnt },
});
