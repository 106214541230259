
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "HealthComp",
  props: {
    health: VueTypes.number.def(0),
    small: VueTypes.bool.def(false),
  },
  computed: {
    color(): string {
      let color = "error";
      switch (this.health) {
        case 0:
          color = "orange";
          break;
        case 1:
          color = "light-green";
          break;
        case 2:
          color = "success";
          break;

        default:
          color = "error";
          break;
      }
      return color;
    },
    icon(): string {
      let color = "mdi-emoticon-sick";
      switch (this.health) {
        case 0:
          color = "mdi-emoticon-neutral";
          break;
        case 1:
          color = "mdi-emoticon-happy";
          break;
        case 2:
          color = "mdi-emoticon-excited";
          break;

        default:
          color = "mdi-emoticon-sick";
          break;
      }
      return color;
    },
  },
  // components: { Tooltip },
});
