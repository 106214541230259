import { Key, KeysState, TreeItem } from "@/types"

export default {
  getKeyById: (state: KeysState) => (id: string) => {
    return state.keys.list.find((key: Key) => key.id === id)
  },
  getKeys: (state: KeysState): Key[] => {
    return state.keys.list
  },
  getActions: (state: KeysState): TreeItem[] => {
    return state.actions
  },
  getKeysTotal: (state: KeysState): number => {
    return state.keys.total
  },
  getCurrentKey: (state: KeysState): Key => {
    return state.keys.currentKey
  },
  getCurrentKeyId(state: KeysState) {
    return state.keys.currentKey._id
  },
}
