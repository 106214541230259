import { DefaultFilterObject, DefaultUser } from "@/defaults"
import router from "@/router"
import { DefaultFilter, Group, Msg, User, UsersContext } from "@/types"
import { Fetcher } from "@/utils"
import { v4 } from "uuid"

const api = new Fetcher()

export default {
  getRole: async (context: UsersContext,): Promise<void> => {
    try {
      const { role } = await api.get(`api/users/role`).then(r => r).catch(e => e)
      context.state.role = role
      return role
    } catch (error) {
      console.error(error);
    }
    // check extension existence
    context.commit('setExtension', undefined, { root: true })
  },

  login: async (context: UsersContext, obj: { username: string, password: string }): Promise<any> => {
    try {
      const { data } = await api.post(`api/users/login/`, obj)
      const { success, token, user, error } = data
      if (success) {
        const hash = token.split(' ')[1]
        localStorage.setItem('p3t', hash)
        context.commit('setUser', user)
        // localStorage.setItem('p3u', user)
        return user
      } else {
        localStorage.setItem('p3t', '')
        return error
      }
    } catch (error) {
      console.error(error);
      return error
    }
  },

  contactPartner: async (context: UsersContext, obj: { name: string, email: string }): Promise<any> => {
    try {
      const { data } = await api.post(`api/users/contactPartner`, obj)
      const { success } = data
      if (success) {
        return `Thank you for contacting Pine3.io`
      } else {
        return 'Something went wrong..'
      }
    } catch (error) {
      console.error(error);
      return error
    }
  },

  sendInvite: async (context: UsersContext, obj: { name: string, email: string, group?: string }): Promise<any> => {
    try {
      const { data, error } = await api.post(`api/users/invite`, obj)
      if (error) {
        return error
      }
      return data
    } catch (error) {
      console.error(error);
      return error
    }
  },

  checkInvite: async (_: UsersContext, hash: string): Promise<any> => {
    try {
      localStorage.setItem('p3t', '')
      const { data } = await api.get(`api/users/checkInvite/${hash}`)

      if (data && Object.keys(data).length) {
        localStorage.setItem('p3t', hash)
      }
      return data
    } catch (error) {
      localStorage.setItem('p3t', '')
      return
    }
  },
  checkEmail: async (context: UsersContext, email: string): Promise<any> => {
    try {
      const { data } = await api.post(`api/users/checkEmail/`, { email })
      return data
    } catch (error) {
      console.error(error);
      localStorage.setItem('p3t', '')
      return false
    }
  },
  sendConfirmation: async (context: UsersContext, obj: { name: string, email: string, group?: string }): Promise<any> => {
    try {
      const { data, error } = await api.post(`api/users/confirmation`, obj)
      if (error) {
        return error
      }
      return data
    } catch (error) {
      console.error(error);
      return error
    }
  },
  refreshToken: async (context: UsersContext): Promise<void> => {
    const data = await api.get(`api/users/refresh/`).catch((e: any) => e)
    if (!data) {
      return localStorage.setItem('p3t', '')
    }
    const { success, token, user } = data
    if (success) {
      const hash = token.split(' ')[1]
      localStorage.setItem('p3t', hash)
      context.commit('setUser', user)
      context.dispatch('fetchCurrentUserLabels', user.group)
      context.dispatch('getRole')
    } else {
      localStorage.setItem('p3t', '')
    }
  },
  refreshUser: async (context: UsersContext, id = ''): Promise<void> => {
    try {
      const data = await api.get(`api/users/reload/${id}`)
      const { success, token, user } = data
      if (success) {
        const hash = token.split(' ')[1]
        localStorage.setItem('p3t', hash)
        context.commit('setUser', user)
        context.dispatch('fetchCurrentUserLabels', user.group)
        context.dispatch('getRole')
      } else {
        localStorage.setItem('p3t', '')
      }
      return success
    } catch (error) {
      console.error(error);
      localStorage.setItem('p3t', '')
    }
  },

  logout: async (): Promise<void> => {
    localStorage.setItem('p3t', '')
  },
  fetchAllUsers: async (context: UsersContext): Promise<void> => {
    try {
      context.state.users.list = []
      context.state.users.total = 0
      const { users, total } = await api.get(`api/users/allUsers`)
      context.state.users.list = users
      context.state.users.total = total
    } catch (error) {
      console.error(error);
    }
    // check extension existence
    context.commit('setExtension', undefined, { root: true })
  },
  fetchGroups: async (context: UsersContext, filter: DefaultFilter = { ...DefaultFilterObject, limit: 5 }): Promise<void> => {
    try {
      const { data } = await api.post(`api/groups/search`, filter)
      const { groups } = data
      context.state.groups = groups
    } catch (error) {
      console.error(error);
    }
  },
  fetchUsers: async (context: UsersContext, filter: DefaultFilter = { ...DefaultFilterObject, sort: { role: 1 } }): Promise<void> => {
    try {
      const { data } = await api.post(`api/users/search`, filter)
      const { users, total } = data
      context.state.users.list = users
      context.state.users.total = total
    } catch (error) {
      console.error(error);
    }
    // check extension existence
    context.commit('setExtension', undefined, { root: true })
  },
  fetchCurrentUser: async (context: UsersContext, id: string): Promise<void> => {
    try {
      const data = await api.get(`api/users/${id}`)
      let user = DefaultUser
      if (!data) {
        const msg = { id: v4(), type: 'error', content: "Something went wrong", dismissible: true, timeout: 3000 }
        context.dispatch('popMsg', msg, { root: true })
        router.push("/");
      } else {
        user = data
      }

      context.commit("setCurrentUser", user)
    } catch (error) {
      console.error(error);
    }
  },
  fetchCurrentUserLabels: async (context: UsersContext, id: string | undefined): Promise<void> => {
    try {
      if (!id) {
        id = context.getters.getUserGroupId
      }
      const data = await api.get(`api/groups/${id}`)
      const { labels } = data
      context.commit("setUserLabels", labels)
    } catch (error) {
      console.error(error);
    }
  },
  saveUser: async (context: UsersContext, user: User): Promise<any> => {
    try {
      const { _id } = user
      const req = await api.post(`api/users/${_id || ''}`, user)
      const { data, error } = req.data
      await context.dispatch("fetchUsers")
      let msg: Msg
      if (error) {
        msg = { id: v4(), type: 'error', content: error.msg, dismissible: true, timeout: 3000 }
        return { error }
      } else {
        msg = { id: v4(), type: 'success', color: "cyan", ts: new Date().getTime(), icon: "account", content: `User <strong>"${data.name}"</strong> was saved!`, dismissible: true, timeout: 3000, notify: true }
        context.commit("setCurrentUser");
      }
      context.dispatch('popMsg', msg, { root: true })
      return { data }
    } catch (error) {
      console.error(error);
    }
  },
  saveUserPreferences: async (context: UsersContext, prefrences: { user: User, group: Group }): Promise<any> => {
    try {
      const { user, group } = prefrences
      const { _id, id } = user
      const req = await api.put(`api/users/update/${id || _id}`, user)
      const { data, error } = req.data
      await context.dispatch("fetchGroups")
      // await context.dispatch("fetchUsers")
      await api.put(`api/groups/${group._id}`, group)
      await context.dispatch("refreshUser")
      let msg: Msg
      if (error) {
        msg = { id: v4(), type: 'error', content: error.msg, dismissible: true, timeout: 3000 }
        return { error }
      } else {
        context.commit('setUser', data)
        msg = { id: v4(), type: 'success', color: "cyan", ts: new Date().getTime(), icon: "account", content: `User Preferences were successfully updated.`, dismissible: true, timeout: 3000, notify: true }
      }
      context.dispatch('popMsg', msg, { root: true })
      return { data }
    } catch (error) {
      console.error(error);
    }
  },
  updatePassword: async (context: UsersContext, user: User): Promise<any> => {
    try {
      const { id, _id } = user
      delete user.created
      const { data, error } = (await api.put(`api/users/password/${id || _id}`, user)).data
      // console.log(user, data, error);

      let msg: Msg
      if (error) {
        msg = { id: v4(), type: 'error', content: error.msg, dismissible: true, timeout: 3000 }
        return { error }
      } else {
        msg = { id: v4(), type: 'success', icon: "account", content: `Password for <strong>"${data.name}"</strong> was updated`, dismissible: true, timeout: 3000 }
      }
      context.dispatch('popMsg', msg, { root: true })
      return data
    } catch (error) {
      console.error(error);
    }
  },
  deleteById: async (context: UsersContext, id: string): Promise<any> => {
    try {
      const { data } = await api.delete(`api/users/${id}`)
      await context.dispatch("fetchUsers")
      const msg: Msg = { id: v4(), type: 'info', color: "cyan", ts: new Date().getTime(), icon: "account", content: `User was successfully deleted`, dismissible: true, timeout: 3000, notify: true }
      context.dispatch('popMsg', msg, { root: true })
      return data
    } catch (error) {
      console.error(error);
    }
  },
}
