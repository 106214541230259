
import { SelectItem } from "@/types";
import Vue from "vue";
import VueTypes from "vue-types";
import Tooltip from "@/components/Tooltip.vue";

export default Vue.extend({
  name: "DragList",
  data() {
    return {
      dragged: null as SelectItem,
      overCurrent: null as SelectItem,
    };
  },
  props: {
    list: VueTypes.any.def([]),
    values: VueTypes.bool.def(false),
    removable: VueTypes.bool.def(false),
    icon: VueTypes.string.def("drag-horizontal"),
    callback: VueTypes.any,
  },
  methods: {
    updateList() {
      this.$emit("changed", this.tmpList);
    },
    drag(i: number) {
      this.dragged = i;
    },
    remove(i: number) {
      this.tmpList.splice(i, 1);
      this.updateList();
    },
    over(i: number) {
      if (i !== this.overCurrent) {
        this.overCurrent = i;
      }
    },
    drop(item: never) {
      this.tmpList.splice(this.dragged, 1);
      this.tmpList.splice(this.overCurrent, 0, item);
      this.overCurrent = null;
      this.dragged = null;
      this.updateList();
    },
  },
  computed: {
    tmpList: {
      get():SelectItem[] {
        return this.list;
      },
      set(list: SelectItem[]) {
        this.$emit("changed", list);
      },
    },
  },
  components: { Tooltip },
});
