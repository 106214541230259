
import { v4 } from "uuid";
import Vue from "vue";
import Tooltip from "@/components/Tooltip.vue";
import VideoPlayer from "../VideoPlayer.vue";

export default Vue.extend({
  name: "SliderDialog",
  data() {
    return {
      videoFormats: {
        ogg: 'video/ogg; codecs="theora"',
        h264: 'video/mp4; codecs="avc1.42E01E"',
        webm: 'video/webm; codecs="vp8, vorbis"',
        vp9: 'video/webm; codecs="vp9"',
        hls: 'application/x-mpegURL; codecs="avc1.42E01E"',
      },
      current: "",
    };
  },
  components: { Tooltip, VideoPlayer },
  computed: {
    slides(): string[] {
      return this.$store.getters.getSlides;
    },
    currentSlide(): string {
      return this.slides[this.current];
    },
    active: {
      get(): boolean {
        return this.slides.length > 0;
      },
      set(val: boolean) {
        if (!val) {
          this.$store.commit("setSlides");
        }
      },
    },
    options(): any {
      return this.$store.getters.getSlidesOptions;
    },
  },
  methods: {
    isVideo(src: string) {
      const re = /(?:\.([^.]+))?$/;
      const fileExt = re.exec(src);
      const isVideo = Object.keys(this.videoFormats).includes(fileExt[1]); // this.supportsVideoType(fileExt[1]);
      return isVideo;
    },
    // active check for video support
    supportsVideoType(type) {
      let video;
      if (!video) {
        video = document.createElement("video");
      }
      const probebly =
        video.canPlayType(this.videoFormats[type] || type) === "probably";
      video.remove();
      return probebly;
    },
    download() {
      const urlObj = window.URL.createObjectURL(new Blob([this.currentSlide]));
      const link = document.createElement("a");
      link.href = this.currentSlide;
      link.setAttribute("download", `${v4()}.jpg`); //or any other extension
      document.body.appendChild(link);
      link.click();
      console.log("downloading", urlObj);
    },
  },
});
