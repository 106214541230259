import { Group, GroupsState } from "@/types"

export default {
  getGroups: (state: GroupsState): Group[] => {
    return state.groups.list
  },
  getContacts: (state: GroupsState): any[] => {
    return state.contacts
  },
  getGroupsTotal: (state: GroupsState): number => {
    return state.groups.total
  },
  getCurrentGroup: (state: GroupsState): Group => {
    return state.groups.currentGroup
  },
}
