
/* eslint-disable */
import Vue from "vue";
import { v4 } from "uuid";
import {
  capitalize,
  cloneObj,
  objNotEmpty,
  regexes,
  reId,
  scrollToID,
} from "../utils";

import BackBtn from "../components/buttons/BackBtn.vue";
import Device from "../components/Device.vue";
import Tooltip from "../components/Tooltip.vue";
import DragList from "../components/DragList.vue";
import {
  ContactItem,
  Msg,
  Plan,
  PlanType,
  RoleType,
  SelectItem,
  Step,
  StepType,
} from "../types";
import { parseEnv, flatRecursive } from "../utils";
import { DefaultStep } from "@/defaults";
import Debugger from "../components/DebuggerView.vue";
import CopyToClip from "../components/CopyToClip.vue";
import Countries from "@/components/Countries.vue";
import Locale from "@/components/Locale.vue";
import { genCancelToken } from "../utils/fetcher";
import { CancelTokenSource } from "axios";
import NextBtn from "@/components/buttons/NextBtn.vue";

import { io } from "socket.io-client";
import config from "@/config";
import RequestHeaders from "@/components/RequestHeaders.vue";
import AddList from "@/components/AddList.vue";
import ExitModal from "@/components/dialogs/ExitModal.vue";
import ImportModal from "@/components/dialogs/ImportModal.vue";
import EnvSettings from "@/components/EnvSettings.vue";
import ResultStats from "@/components/ResultStats.vue";
import KnobBtn from "@/components/buttons/KnobBtn.vue";

export default Vue.extend({
  name: "PlanView",
  components: {
    BackBtn,
    Device,
    Locale,
    Tooltip,
    DragList,
    Debugger,
    CopyToClip,
    Countries,
    NextBtn,
    RequestHeaders,
    AddList,
    ExitModal,
    ImportModal,
    EnvSettings,
    ResultStats,
    KnobBtn,
  },
  data() {
    return {
      loading: false,
      updating: false,
      device: false,
      editTitle: false,
      moduleType: 0,
      PlanType,
      identity: "",
      routeRoot: "",
      testType: PlanType.PLAN,
      rules: {
        text: [(val: string) => val.length || "This field is required"],
      },
      helper: false,
      // importDialog: false,
      recordDialog: false,
      hooksDialog: false,
      recordingURL: "",
      newPlan: true,
      selecteds: [],
      // selected: "",
      tab: 1,
      hooksMethod: "mail",
      hooksCondition: "fail",
      selectedHooks: [],
      hooksList: [
        { text: "Test Case", value: "plan" },
        { text: "E-Mail", value: "mail" },
      ],
      cancelToken: {} as CancelTokenSource,
      socket: io(config.SOCKET, {
        auth: {
          token: localStorage.getItem("p3t"),
        },
        transports: ["websocket"],
      }),
    };
  },
  computed: {
    debuggerOpen(): boolean {
      return this.$store.getters["Plans/getPlanDisplayDebugger"];
    },
    // debuggerViewOpen(): boolean {
    //   const currentResult = this.$store.getters["Results/getCurrentResultView"];
    //   return objNotEmpty(currentResult);
    // },
    progressText(): string {
      let text = "";
      const bar = Math.floor(this.compareTestResults / 20);

      switch (bar) {
        case 0:
          text = "Warming up!";
          break;
        case 1:
          text = "Lets Go!";
          break;
        case 2:
          text = "Yup! it's alive!";
          break;
        case 3:
          text = "Almost there...";
          break;
        case 4:
          text = "Wrapping up!";
          break;

        default:
          text = "Wrapping up!";
          break;
      }
      return text;
    },
    deleteStepSelected(): Step {
      return this.$store.getters["Plans/getDeleteStep"];
    },
    valid(): boolean {
      return this.plan?.name?.length > 0 && this.plan?.steps?.length > 0;
    },
    extension(): boolean {
      return this.$store.getters["getExtension"];
    },
    isRoutine(): boolean {
      return this.moduleType == PlanType.ROUTINE;
    },
    isSuper(): boolean {
      const { role } = this.$store.getters["Users/getUser"];
      return role <= RoleType.SUPER_ADMIN;
    },
    plan: {
      get(): Plan {
        const plan: Plan = this.$store.getters["Plans/getCurrentPlan"];
        // migrate from plan.test
        // if (!plan.steps?.length) {
        //   console.log("inside");
        //   plan.steps = [...plan.test];
        // }
        if (plan._id) {
          this.$store.commit("setTitle", `Edit '${plan.name}'`);
        }
        return plan;
      },
      set(val: Plan) {
        this.planChanged(val);
      },
    },
    debuggerRunning(): boolean {
      return (
        this.loading ||
        (Object.keys(this.results).length > 0 && !this.results.complete)
      );
    },
    compareTestResults(): number {
      if (!this.plan?.steps || !this.results?.results) {
        return 0;
      }
      return Math.floor(
        (this.flatRecursive(this.results.results).length /
          this.flatRecursive(this.plan.steps).length) *
          100
      );
    },
    results(): any {
      return this.$store.getters["Plans/getPlanCurrentDebugger"];
    },
    importItems(): SelectItem[] {
      const items = this.isRoutine ? this.routines : this.plans;
      return items;
    },
    importDialog: {
      get(): boolean {
        return this.$store.getters.getImportDialogActive;
      },
      set(val: boolean) {
        this.$store.commit("setImportDialogActive", val);
      },
    },
    exitDialog: {
      get(): boolean {
        return this.$store.getters.getExitDialog;
      },
      set(val: boolean) {
        this.$store.commit("setExitDialog", val);
      },
    },
    exitDialogTo: {
      get(): string {
        return this.$store.getters.getExitDialogTo;
      },
      set(val: string) {
        this.$store.commit("setExitDialogTo", val);
      },
    },
    labels(): string[] {
      const labels = this.$store.getters["Users/getUserLabels"] || [];
      return labels.map((label: string) => {
        return { text: capitalize(label), value: label };
      });
    },
    routines(): SelectItem[] {
      return this.$store.getters["Plans/getRoutines"];
    },
    plans(): SelectItem[] {
      // TODO add filter and exclude option for current
      let plans = this.$store.getters["Plans/getPlans"];
      if (this.plan._id) {
        plans = plans.filter((p: Plan) => p._id !== this.plan._id);
      }
      return plans.map((p: Plan) => {
        return {
          text: p.name,
          value: p._id,
          icon: "flask",
          type: "plan",
        };
      });
    },
    changed(): boolean {
      return this.$store.getters["Plans/getCurrentPlanChange"];
    },
    hooksItems() {
      this.selectedHooks = [];
      let items = [];

      switch (this.hooksMethod) {
        case "plan":
          items = this.plans;
          break;
        case "mail":
          items = this.contacts;
          break;

        default:
          break;
      }
      return items;
    },
    hooks() {
      return this.plan.callback || [];
    },
    passHooks() {
      return this.hooks.filter((h: any) => h.condition === "pass");
    },
    failHooks() {
      return this.hooks.filter((h: any) => h.condition === "fail");
    },
    contacts(): any {
      const contacts = this.$store.getters["Groups/getContacts"];
      return contacts.map((p: ContactItem) => {
        return {
          text: p.name,
          value: p.val,
          icon: "card-account-mail",
          type: "mail",
        };
      });
    },
    // activeEdit(): boolean {
    //   return this.$store.getters["Plans/getPlanCurrentTest"];
    // },
    validateURL(): boolean {
      return regexes.host.url.test(this.recordingURL);
    },
  },
  methods: {
    addNew(type = StepType.UI) {
      const step: Step = cloneObj(DefaultStep);
      step.type = type;
      step.id = v4();
      step.name = "Go To ";
      step.action = {
        method: type ? "url" : "get",
        url: "",
        type: type ? "misc" : "api",
      };
      this.addChild(step);
      // this.$store.dispatch("Plans/setPlanDisplayTest", test);
      // this.$store.commit("Plans/setPlanCurrentStep", step.id);
      // setTimeout(() => {
      //   scrollToID(step.id);
      // }, 100);
      // this.$nextTick(() => scrollToEnd(this.$el, "#planning"));
    },
    addChild(step: Step, selectStep = true) {
      this.plan.steps.push(step);
      if (selectStep) {
        this.$store.commit("Plans/setPlanCurrentStep", step.id);
      }
      setTimeout(() => {
        scrollToID(step.id);
      }, 100);
    },
    openPlanImportDialog() {
      this.$store.commit("setImportDialog", {
        active: true,
        items: this.plans,
        action: this.planImport,
        title: "Select Test Case to import",
      });
    },
    async addRoutine(item: SelectItem) {
      this.resetDebugger();
      const routine: Plan = await this.$store.dispatch(
        "Plans/fetchPlan",
        item.value
      );
      const step: Step = cloneObj(DefaultStep);
      step.id = v4();
      step.type = StepType.ROUTINE;
      step.name = routine.name;
      step.ref = routine._id;
      step.children = routine.steps;
      // step.action = {};
      this.addChild(step, false);
    },
    openRoutineImportDialog() {
      this.$store.commit("setImportDialog", {
        active: true,
        items: this.routines,
        action: this.addRoutine,
        title: "Select Test Routine to import",
      });
    },
    async planImport(selected: SelectItem) {
      this.resetDebugger();
      this.importDialog = false;
      if (selected) {
        const planMeta: any = selected;
        const planId: string = planMeta.value;
        const plan = await this.$store.dispatch("Plans/fetchPlan", planId);
        // parse env and steps into current plan
        const { steps, env } = plan;
        if (steps.length) {
          let clone = [];
          for (const step of steps) {
            clone.push(reId({ ...step }));
          }
          this.plan.steps = [...this.plan.steps, ...clone];
        }
        if (env.length) {
          for (const i in env) {
            const { name } = env[i];
            if (!this.plan.env.filter((e: any) => e.name === name).length) {
              this.plan.env.push(env[i]);
            }
          }
        }
        this.$store.dispatch("popMsg", {
          type: "info",
          content: `<strong>${plan.name}'s</strong> steps were imported successfully :)`,
          dismissible: true,
          timeout: 2000,
        });
      } else {
        this.$store.dispatch("popMsg", {
          type: "error",
          content: `Something went wrong... steps were not imported`,
          dismissible: true,
          timeout: 2000,
        });
      }
    },
    planChanged(newData: any | undefined = undefined) {
      if (newData) {
        const plan: any = this.plan;
        for (const key in newData) {
          plan[key] = cloneObj(newData[key]);
        }
        // console.log(plan);
      }
      // this.$store.commit("Plans/setCurrentPlan", this.plan);
      this.$store.commit("Plans/checkCurrentPlanChange");
    },
    // getDisplay() {
    //   this.displayStep = this.$store.getters["Plans/getPlanDisplayTest"];
    // },
    // setDisplay() {
    //   this.$store.dispatch("Plans/setPlanDisplayTest", this.displayStep);
    // },
    capitalize,
    async save() {
      await this.update();
      this.$router.push(this.routeRoot);
    },
    // resetDelete(): void {
    //   this.$store.commit("Plans/setDeleteStep");
    // },
    updateFailHooksList(list: any[]) {
      this.updateHooksList(list, "fail");
    },
    updatePassHooksList(list: any[]) {
      this.updateHooksList(list, "pass");
    },
    updateHooksList(list: any[], condition: string) {
      if (this.plan.callback?.length) {
        const filtered = this.plan.callback.filter(
          (h: any) => h.condition != condition
        );
        this.plan.callback = cloneObj([...filtered, ...list]);
      }
    },
    addHook() {
      if (this.plan.callback && this.selectedHooks.length) {
        const list: any[] = [];
        this.selectedHooks.forEach((hook: any) => {
          const newHook = cloneObj(hook);
          newHook.condition = this.hooksCondition;
          list.push(newHook);
        });
        this.plan.callback?.push(...list);
        this.selectedHooks = [];
      }
      this.hooksDialog = false;
    },
    openHooksDialog(conditions = "pass") {
      this.selectedHooks = [];
      this.hooksCondition = conditions;
      this.hooksDialog = true;
    },
    async update() {
      this.updating = true;
      this.plan.type = this.moduleType;
      this.$store.commit("Plans/updatePlanSnapshot");
      this.$store.commit("Plans/setCurrentPlanChange", false);
      await this.$store.dispatch("Plans/savePlan", this.plan);
      this.$store.dispatch("popMsg", {
        type: "info",
        content: `Test Case was updated successfully`,
        dismissible: true,
        timeout: 2000,
      });
      this.updating = false;
    },
    async genTemplate() {
      this.loading = true;
      this.$router.push("/templates");
      const plan = { ...this.plan };
      plan.type = PlanType.TEMPLATE;
      await this.$store.dispatch("Plans/generateTemplate", plan);
      this.$store.commit("Plans/updatePlanSnapshot");
      this.$store.commit("Plans/setCurrentPlanChange", false);

      this.loading = false;
    },
    changeTab(val: any) {
      const { hash } = this.$route;
      this.tab = val;
      switch (val) {
        case 2:
          if (hash != "#plan") {
            this.$router.push({ hash: "#plan" });
          }
          break;
        case 3:
          if (hash != "#hooks") {
            this.$router.push({ hash: "#hooks" });
          }
          break;
        default:
          if (hash != "#general") {
            this.$router.push({ hash: "#general" });
          }
          break;
      }
      this.$store.commit(
        "setTitle",
        this.plan?._id ? `Edit '${this.plan.name}'` : `Add New ${this.identity}`
      );
    },
    // fixObject() {
    //   if (!("callback" in this.plan)) {
    //     this.plan.callback = [];
    //   }
    //   if (
    //     this.plan.callback &&
    //     this.plan.callback?.length &&
    //     !this.selectedPassHooks.length &&
    //     !this.selectedFailHooks.length
    //   ) {
    //     this.selectedPassHooks = [
    //       ...this.plan.callback.filter((c: any) => c.condition === "pass"),
    //     ];
    //     this.selectedFailHooks = [
    //       ...this.plan.callback.filter((c: any) => c.condition === "fail"),
    //     ];
    //   }
    // },
    routeTo(method: string) {
      const { group } = this.$store.getters["Users/getUser"];
      window.location.pathname =
        method === "plan" ? "/plans/add" : "/group/settings/" + group;
    },
    fetchUrl(): string | undefined {
      const { test } = this.plan;
      if (!test?.length) {
        return;
      }
      const { url } = test.find((t: Step) => t.action.method === "url").action;
      return this.parseEnv(url, this.plan.env || []);
    },
    parseEnv,
    preperRecorder() {
      const url = this.fetchUrl();
      if (!url) {
        return (this.recordDialog = true);
      }
      this.startRecording(url);
    },
    startRecording(url: string) {
      const { steps } = this.plan;
      const data = {
        url,
        steps,
      };
      const event = new CustomEvent("startRecording", { detail: data });
      document.dispatchEvent(event);
    },
    stopRecording(data: any) {
      const event = new CustomEvent("stopRecording", { detail: data });
      document.dispatchEvent(event);
    },
    // pauseRecording(data: any) {
    //   const event = new CustomEvent("pauseRecording", { detail: data });
    //   document.dispatchEvent(event);
    // },
    flatRecursive,
    resetDebugger() {
      this.$store.commit("Plans/setPlanCurrentStep");
      this.$store.commit("Plans/setPlanCurrentDebugger");
      this.$store.commit("Results/setCurrentResultView");
    },
    async runPlan() {
      this.tab = 2;
      this.loading = true;
      // this.$store.commit("Plans/setPlanDisplayTest", false);
      this.$store.commit("toggleExpand", true);
      this.resetDebugger();
      this.$store.commit("Plans/setPlanDisplayDebugger", true);
      this.cancelToken = genCancelToken();
      const { resultId } = await this.$store.dispatch("Plans/run", {
        id: this.plan._id,
        debug: true,
        cancelToken: this.cancelToken.token,
      });
      this.socket.emit("result_view", resultId);
      // this.$store.commit("Plans/setPlanCurrentDebugger", results);
    },
    next() {
      this.tab++;
    },
    tabUpdate(tab: number) {
      this.tab = tab;
    },
  },
  // watch: {
  //   hooksMethod() {
  //     this.callbacks = [];
  //   }
  // },

  created() {
    this.$store.commit("Plans/setCurrentPlan");
  },
  async mounted() {
    this.$store.commit("setGlobalLoader", true);
    const { meta, params, hash } = this.$route;
    this.moduleType = meta.type || PlanType.PLAN;
    this.routeRoot = meta.routeRoot || "/plans";
    switch (this.moduleType) {
      case PlanType.ROUTINE:
        this.identity = "Test Routine";
        break;
      case PlanType.TEMPLATE:
        this.identity = "Template";
        break;

      default:
        this.identity = "Test Case";
        break;
    }
    this.$store.dispatch("Groups/fetchGroupContacts");
    this.$store.dispatch(
      "Plans/fetchAllPlans",
      { type: PlanType.PLAN },
      false,
      false
    );
    this.$store.dispatch(
      "Plans/fetchAllRoutines",
      { type: PlanType.ROUTINE },
      false,
      false
    );
    this.$store.dispatch(
      "Plans/fetchAllAuthMethods",
      { type: PlanType.ROUTINE, isAuthMethod: true , limit:10},
      false,
      false
    );
    // this.$store.commit("Plans/setPlanDisplayTest", false);
    // this.$store.commit("Plans/setPlanCurrentDebugger");
    this.resetDebugger();
    this.$store.commit("Plans/setPlanDisplayDebugger", false);
    const { id } = params;
    if (id) {
      this.newPlan = false;
      const plan = await this.$store.dispatch("Plans/fetchPlan", id);
      if (!plan) {
        const msg: Msg = {
          id: v4(),
          type: "error",
          content: `Test Case ID - not valid`,
          dismissible: true,
          timeout: 2000,
          notify: true,
        };
        this.$store.dispatch("popMsg", msg);
        this.$router.push("/");
      }
      this.$store.commit("Plans/setCurrentPlan", plan);
      switch (hash) {
        case "#routine":
          this.tab = 2;
          break;
        case "#plan":
          this.tab = 2;
          break;
        case "#hooks":
          this.tab = 3;
          break;

        default:
          this.tab = 1;
          break;
      }
    }
    this.$store.commit("setGlobalLoader");
    this.socket.on("resultsObj", (resultsObj: any) => {
      this.$store.commit("Plans/setPlanCurrentDebugger", resultsObj);
      this.loading = false;
    });
    this.socket.on("resultsData", (data: any) => {
      let results = { ...this.results };
      results = { ...results, ...data };
      this.$store.commit("Plans/setPlanCurrentDebugger", results);
    });
    console.log(this.plan);
  },
  updated() {
    this.$store.commit("Plans/checkCurrentPlanChange");
  },
  beforeRouteLeave(to, _, next) {
    if (this.changed && !this.exitDialog) {
      this.exitDialogTo = to.fullPath;
      this.exitDialog = true;
      return;
    }
    next();
  },
  destroyed() {
    if (this.cancelToken.cancel) this.cancelToken.cancel();
    this.exitDialog = false;
    // this.$store.commit("Plans/setPlanDisplayTest", false);
    this.$store.commit("Plans/setCurrentPlanChange", false);
    this.$store.commit("Plans/setCurrentPlan");
  },
});
