
import Vue from "vue";
import VueTypes from "vue-types";

// import Contacts from "@/components/Contacts.vue";
import AddList from "@/components/AddList.vue";
import { Group, RoleType, SelectItem, User } from "../types";
import { validations, updateObj, cloneObj, locales } from "../utils";

export default Vue.extend({
  name: "PreferencesView",
  components: { AddList },
  data() {
    return {
      tab: "user",
      rules: {
        text: [validations.empty],
        email: [validations.email],
        pass: [validations.password.len, validations.password.strength],
      },
      changes: false,
      loading: false,
      password2: "",
      passShow: false,
      passwordToggle: false,
      error: { type: "", msg: "" },
      roleType: RoleType,
    };
  },
  props: {
    outerRoute: VueTypes.string,
  },
  computed: {
    valid(): boolean {
      const valid: boolean =
        (this.user?.name?.length > 0 &&
          this.user?.email?.length > 0 &&
          this.changes) ||
        false;
      return valid;
    },
    self(): boolean {
      return this.$store.getters["Users/isSelf"];
    },
    role(): number {
      return this.$store.getters["Users/getRole"];
    },
    user(): User {
      const user: User = this.$store.getters["Users/getUser"];
      this.$store.commit("setTitle", `User Preferences`);
      return user;
    },
    packages(): SelectItem[] {
      return this.$store.getters.getPackages;
    },
    locales() {
      return locales || [];
    },
    group: {
      get(): Group {
        const group = this.$store.getters["Groups/getCurrentGroup"];
        if (!Object.keys(group).length) {
          this.$store.dispatch("Groups/fetchCurrentGroup", this.user.group);
        }
        return group;
      },
      set(group: Group) {
        this.$store.commit("Groups/setCurrentGroup", group);
      },
    },
  },
  methods: {
    async save() {
      this.loading = true;
      const req = await this.$store.dispatch("Users/saveUserPreferences", {
        user: this.user,
        group: this.group,
      });
      if (req.error) {
        this.error = req.error;
      }
      this.loading = false;
      this.changes = false;
    },
    async fetchGroups() {
      await this.$store.dispatch("Users/fetchGroups", this.groupsFilter);
    },
    updatePass() {
      this.$store.dispatch("Users/updatePassword", {
        id: this.user.id || this.user._id,
        password: this.user.password,
      });
      this.passwordToggle = false;
      this.password2 = "";
      this.user.password = "";
    },
    // changeGroup(option: { text: string; value: string }) {
    //   if (option) {
    //     const { text, value } = option;
    //     this.user.groupName = text;
    //     this.user.group = value;
    //     this.changes = true;
    //     // this.$store.commit("Users/setCurrentUserGroup", {
    //     //   groupName: text,
    //     //   group: value,
    //     // });
    //   }
    // },
    // search(e: Event) {
    //   const target =  e.target as HTMLTextAreaElement
    //   const text = target.value;
    //   if (text) {
    //     this.groupsFilter.search.name = text;
    //     this.fetchGroups();
    //   }
    // },
    // async save() {
    //   const req = await this.$store.dispatch("Groups/saveGroup", this.group);
    //   if (req.error) {
    //     this.error = req.error;
    //   }
    //   this.$router.push(this.outerRoute || "/groups");
    // },
    // addLabel() {
    //   if (this.searchLabel) {
    //     if (!this.group.labels || !this.group.labels.length) {
    //       this.group.labels = [];
    //     }
    //     this.group.labels.push(this.searchLabel);
    //     this.searchLabel = "";
    //     this.changes = true;
    //   }
    // },
    // rmLabel(i: number) {
    //   if (this.group.labels.length >= i + 1) {
    //     this.group.labels.splice(i, 1);
    //   }
    //   this.changes = true;
    // },
    changed(data: unknown) {
      if (data) {
        this.group = cloneObj(updateObj(this.group, data));
      }
      this.changes = true;
    },
  },
  watch: {
    passwordToggle(on: boolean) {
      if (on) {
        this.user.password = "";
      } else {
        delete this.user.password;
      }
    },
  },
  async mounted() {
    this.$store.commit("setGlobalLoader", true);
    await this.fetchGroups();
    this.$store.commit("setGlobalLoader");
  },
});
