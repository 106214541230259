
import moment from "moment";
import Vue from "vue";

export default Vue.extend({
  name: "NotificationsComp",
  data() {
    return { open: false };
  },
  methods: {
    dismiss(id: string) {
      return this.$store.commit("rmNotification", id);
    },
    clear() {
      return this.$store.commit("rmAllNotification");
    },
    toggle() {
      this.open = !this.open;
      console.log("toggle", this.open);
    },
    close() {
      this.$emit("close");
    },
    relativeTime(ts: number): string {
      return moment(ts).fromNow();
    },
  },
  computed: {
    active() {
      return this.messages.length > 0;
    },
    messages() {
      return this.$store.getters.getNotifications;
    },
  },
});
