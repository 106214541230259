
import { Group, RoleType } from "@/types";
import { validations } from "@/utils";
import Vue from "vue";
import VueTypes from "vue-types";

export default Vue.extend({
  name: "InviteBtn",

  data() {
    return {
      dialog: false,
      loading: false,
      email: "",
      timeLimit: 5,
      counter: 1,
      name: "",
      userRole: 3,
      roleType: RoleType,
      msg: "",
      err: "",
      selectedGroup: "",
      rules: {
        text: [validations.empty],
        email: [validations.email],
      },
      roles: [
        { text: "Admin", value: 2 },
        { text: "User", value: 3 },
      ],
      groupsFilter: { limit: 10, search: { name: "" } },
    };
  },
  methods: {
    async sendInvite() {
      this.loading = true;
      const { name, email, selectedGroup, userRole } = this;
      const resp = await this.$store.dispatch("Users/sendInvite", {
        name,
        email,
        userRole,
        group: selectedGroup.value,
        groupName: selectedGroup.text,
      });
      if (resp?.success) {
        this.msg = "invitation was sent successfully!";
      } else {
        this.msg = "Something went wrong..";
      }
      this.loading = false;
      this.timer();
    },
    async checkEmail() {
      const { email } = this;
      if (validations.email(email) === true) {
        const resp = await this.$store.dispatch("Users/checkEmail", email);
        if (resp?.success) {
          this.err = "";
        } else {
          this.err = "Username/e-mail is already in use";
        }
      } else {
        this.err = "";
      }
    },
    timer() {
      const timer = setInterval(() => {
        this.counter++;
        if (this.counter > this.timeLimit) {
          this.clear();
          clearInterval(timer);
        }
      }, 1000);
    },
    clear() {
      this.dialog = false;
      this.name = "";
      this.email = "";
      this.msg = "";
      this.counter = 1;
    },
    search(e: Event) {
      const target = e.target as HTMLTextAreaElement;
      const text = target.value;
      if (text) {
        this.groupsFilter.search.name = text;
        this.fetchGroups();
      }
    },
    fetchGroups() {
      this.$store.dispatch("Users/fetchGroups", this.groupsFilter);
    },
  },
  computed: {
    valid() {
      return (
        this.name.length > 0 &&
        validations.email(this.email) === true &&
        this.err.length == 0
      );
    },
    role(): number {
      return this.$store.getters["Users/getRole"];
    },
    groups() {
      if (this.role > RoleType.SUPER_ADMIN) {
        return [];
      }
      const groups = this.$store.getters["Users/getGroups"];
      return groups.map((group: Group) => {
        return { text: group.name, value: group._id || group.id };
      });
    },
  },
  created() {
    this.fetchGroups();
  },
  props: {
    size: VueTypes.string.def("small"),
    color: VueTypes.string.def("amber darken-2"),
    icon: VueTypes.string.def("mdi-email-send"),
    btnText: VueTypes.string.def("Invite"),
    title: VueTypes.string.def("Pine3 Invitation"),
    dark: VueTypes.bool.def(true),
  },
});
