/* eslint-disable */
import { ListObj, SelectItem, StepType, TimeFrameType } from '@/types';
import moment from 'moment';
import { v4 } from 'uuid';
import fetcher from './fetcher';

export const Fetcher = fetcher

// scroll to the end of element by selector
export const scrollToEnd = (target: any, selector: string): void => {
  const element = target.querySelector(selector);
  if (element) {
    element.scrollTop = element.scrollHeight;
  }
}

// scroll to an element by ID
export const scrollToID = (id: string): void => {
  document.getElementById(id)?.scrollIntoView();
}
// scroll to an element by selector
export const scrollToEl = (selector: string): void => {
  document.querySelector(selector)?.scrollIntoView();
}

export const flatRecursive = (list: any[]): any[] => {
  const newList: any = [];
  list.forEach((l: any) => {
    const { children } = l;
    const lCopy = { ...l };
    delete lCopy.children;
    newList.push(lCopy);
    if (children?.length) {
      newList.push(...flatRecursive(children));
    }
  });
  return newList;
}

// filter a step step from the step object recursivally
export const filterRecursive = (step: any[], id: string): any => {
  // const filtered: any = []
  // step.forEach((t: any) => {
  //   if (t.id !== id) {
  //     if (t.children.length) {
  //       t.children = filterRecursive(t.children, id)
  //     }
  //     filtered.push({ ...t })
  //   }
  // });
  // return filtered
  return step.filter((s) => {
    if (s.id !== id) {
      if (s.children.length) {
        s.children = filterRecursive(s.children, id)
      }
      return s
    }
  })
}
// create deep clone of an object
export const cloneObj = (obj: any) => {
  return JSON.parse(JSON.stringify(obj))
}
// push a step into a step object recursivally
export const pushInPos = (obj: any, pos: number[], step: any, before = true, push = false): any => {
  const position = [...pos]//, '_']
  if (push) {
    position.push(0)
  }
  let current = obj
  for (const [i, p] of position.entries()) {
    let slot = +p
    if (!Array.isArray(current) && current?.children) {
      current = current.children
    }
    if (i === (position.length - 1)) {
      if (!before) {
        //   slot = Math.max(0, slot - 1)
        // } else {
        slot = Math.min(current.length, slot + 1)
      }
      if (push) {
        current.push(step)
      } else {
        current.splice(slot, 0, step)
      }
    } else {
      current = current[slot]
    }
  }
  return obj
}
// push a step into a step object recursivally
export const updateInPos = (obj: any, pos: number[], step: any): any => {
  const position = [...pos]//, '_']
  let current = obj
  for (const [i, p] of position.entries()) {
    let slot = +p
    if (!Array.isArray(current) && current?.children) {
      current = current.children
    }
    if (i === (position.length - 1)) {
      current = cloneObj(step)
    } else {
      current = current[slot]
    }
  }
  return obj
}
// generate timeframe array of epoch numbers
export const genTimeFrame = (start: number, end: number, frame: TimeFrameType = 'days'): number[] => {
  const timeframe = []
  let current: number = +start
  while (current < end) {
    current = +moment(current).add(1, frame)
    timeframe.push(current)
  }
  return timeframe
}
// recreate new ID for every step in a step object recursivally
export const reId = (obj: any): any => {
  const newObj = { ...obj }
  newObj.id = v4()
  if (newObj.children?.length) {
    const children: any[] = []
    newObj.children.forEach((child: any) => {
      children.push(reId(child))
    });
    newObj.children = children
  }
  return newObj
}
// update step step in a step object recursivally
export const updateRecursive = (step: any[], data: any): any => {
  const filtered: any = step.map((t: any) => {
    if (t.id === data.id) {
      return data
    } else if (t.children.length) {
      t.children = updateRecursive(t.children, data)
    }
    return t
  });
  return filtered
}
export const format = (date: Date, style = 'HH:mm DD/MM/YYYY'): any => {
  return moment(date).format(style)
}

export const truncate = (text: string, length = 50, ending = "..."): string => {
  return text.substring(0, length) + ending;
}
// update object fields delta's
export const updateObj = (origObj: any, data: any, detach = true): any => {
  const obj = detach ? { ...origObj } : origObj
  const fields = Object.keys(data)
  if (fields.length) {
    fields.forEach(field => {
      obj[field] = data[field]
    });
  }
  return obj
}

// get MDI icon text for an action
export const actionIcon = {
  // API
  "api": "api",
  "post": "api",
  "get": "api",
  "put": "api",
  "delete": "api",
  "patch": "api",
  "structure": "file-tree",
  // UI
  // misc:
  "clearCookies": "cookie-off",
  "clearPermissions": "shield-lock-open",
  "reload": "reload",
  "back": "backburger",
  "screenshot": "monitor-screenshot",
  "pdf": "file-image",
  // user:
  "click": "gesture-tap",
  "dblClick": "gesture-double-tap",
  "hover": "hand-back-right",
  "playVideo": "movie-open-play",
  "url": "link-variant",
  "fill": "form-textbox",
  "typeText": "form-textbox",
  "focus": "image-filter-center-focus",
  "check": "checkbox-marked-outline",
  "uncheck": "checkbox-blank-outline",
  "dragDrop": "drag-variant",
  "scroll": "mouse-move-vertical",
  "tap": "gesture-tap-button",
  "keyPress": "keyboard",
  "keyDown": "keyboard",
  "selectOption": "form-select",
  // assertions:
  "getContent": "text-box-check",
  "getContentText": "format-textbox",
  "countElements": "counter",
  "textContains": "text-search",
  "compareText": "text-recognition",
  "TextEmpty": "text-search-variant",
  "compareNumber": "text-recognition",
  "isVisible": "eye",
  "isDisabled": "play-box-lock-outline",
  "isEnabled": "play-box-lock-open-outline",
  "isHidden": "eye-off",
  "isVideoPlayed": "movie-open-check",
  "isChecked": "check-all",
  "compareLink": "link-box-variant",
}

export const actionColor = {
  "assert": "orange",
  "misc": "blue",
  "user": "green",
  "api": "purple",
}
export const stepColor = {
  0: "purple",
  1: "blue",
  2: "indigo",
}
export const statusFailColor = "#F44336"
export const statusSuccessColor = "#4caf50"

// get MDI icon text for a view
export const viewIcon = {
  "dashboard": "monitor-dashboard",
  "plans": "flask-outline",
  "routines": "cog-clockwise",
  "templates": "file-document-multiple",
  "results": "clipboard-list-outline",
  "pingr": "pulse",
  "suites": "package-variant",
  "scheduler": "history",
  "keys": "key-chain-variant",
  "group": "account-group",
  "settings": "cogs",
  "users": "account-multiple",
  "user": "account",
  "preferences": "account-cog",
  "learn": "school",
}

export const stepIcon = {
  1: viewIcon.plans,
  0: "server",
  2: viewIcon.routines,
}
export const logsIcon = {
  "console": "console",
  "network": "server-network",
}

// get MDI icon text for a device by type
export const deviceIcon = {
  0: "monitor",
  1: "tablet",
  2: "cellphone"
} // "help-circle-outline";

// get MDI icon text for a role
export const roleIcon = {
  0: "alien-outline",
  1: "account-tie-hat",
  2: "account-tie",
  3: viewIcon.user
}

// get MDI icon text for a role
export const initiatorIcon = {
  0: viewIcon.user,
  1: viewIcon.scheduler,
  2: "webhook",
  3: viewIcon.keys
}
// get MDI icon text for a role
export const roleColor = {
  0: "teal",
  1: "blue",
  2: "light-blue",
  3: "light-green"
}

export const actionTypes: SelectItem[] = [
  {
    text: "Misc Actions",
    value: "misc",
    disabled: false,
    icon: "monitor-screenshot",
    color: "info",
  },
  {
    text: "User Actions",
    value: "user",
    disabled: false,
    icon: "account-sync",
    color: "success",
  },
  {
    text: "Requests",
    value: "request",
    disabled: false,
    icon: "api",
    color: "purple",
  },
  {
    text: "Assertions",
    value: "assert",
    disabled: false,
    icon: "code-tags-check",
    color: "warning",
  },
]
export const actionTabList: SelectItem[] = [
  {
    text: "Action",
    value: "action",
    disabled: false,
    icon: "flash",
    color: "info",
  },
  {
    text: "Wait For",
    value: "wait",
    disabled: false,
    icon: "sleep",
    color: "success",
  },
  {
    text: "Headers",
    value: "headers",
    disabled: false,
    icon: "view-list",
    color: "purple",
  },
  {
    text: "General",
    value: "general",
    disabled: false,
    icon: "text-box-plus-outline",
    color: "warning",
  },
]

export const actionsList = {
  [StepType.API]: {
    request: [
      { text: "GET", value: "get" },
      { text: "POST", value: "post" },
      { text: "PUT", value: "put" },
      { text: "DELETE", value: "delete" },
      { text: "PATCH", value: "patch" },
    ],
    assert: [
      { text: "Structure Validation", value: "structure" },
    ]
  },
  [StepType.UI]: {
    misc: [
      { divider: true, header: "Routing Actions:" },
      { text: "Go To", value: "url", disabled: false },
      { divider: true, header: "Browser Actions:" },
      {
        text: "Take a screenshot",
        value: "screenshot",
        disabled: false,
      },
      { text: "Clear Cookies", value: "clearCookies", disabled: false },
      { text: "Clear Permissions", value: "clearPermissions", disabled: false },
      { text: "Go Back", value: "back", disabled: false },
      { text: "Reload", value: "reload", disabled: false },
      { text: "PDF screenshot", value: "pdf", disabled: false },
    ],
    user: [
      { divider: true, header: "Mouse Actions:" },
      { text: "Click on", value: "click", disabled: false },
      { text: "Focus on", value: "focus", disabled: false },
      { text: "Hover over", value: "hover", disabled: false },
      { text: "Scroll To", value: "scroll", disabled: false },
      { text: "Select Option", value: "selectOption", disabled: false },
      { text: "Double Click", value: "dblClick", disabled: true },
      { text: "Drag & Drop", value: "dragDrop", disabled: true },
      { text: "Check checkbox", value: "check", disabled: false },
      { text: "Uncheck checkbox", value: "uncheck", disabled: false },
      { text: "Get Content", value: "getContent", disabled: false },
      {
        text: "Get Content's Text",
        value: "getContentText",
        disabled: false,
      },
      {
        text: "Play a video",
        value: "playVideo",
        disabled: false,
      },
      { divider: true, header: "Keyboard Actions:" },
      { text: "Fill elements", value: "fill", disabled: false },
      { text: "Type Text", value: "typeText", disabled: false },
      // { text: "Tap on", value: "tap", disabled: false },
      {
        text: "Keyboard Press",
        value: "keyPress",
        disabled: false,
      },
      {
        text: "Keyboard Hold",
        value: "keyDown",
        disabled: false,
      },
    ],
    assert: [
      { divider: true, header: "Content Assertions:" },
      {
        text: "Text Contains",
        value: "textContains",
        disabled: false,
      },
      {
        text: "Text Match",
        value: "compareText",
        disabled: false,
      },
      {
        text: "Text Is Empty",
        value: "TextEmpty",
        disabled: false,
      },
      {
        text: "Compare Number Value",
        value: "compareNumber",
        disabled: false,
      },
      {
        text: "Compare Link",
        value: "compareLink",
        disabled: false,
      },
      { divider: true, header: "Visual Assertions:" },
      {
        text: "Count Elements",
        value: "countElements",
        disabled: false,
      },
      {
        text: "Check if is Checked",
        value: "isChecked",
        disabled: false,
      },
      {
        text: "Check if visible",
        value: "isVisible",
        disabled: false,
      },
      {
        text: "Check if disabled",
        value: "isDisabled",
        disabled: false,
      },
      {
        text: "Check if enabled",
        value: "isEnabled",
        disabled: false,
      },
      {
        text: "Check if not visible",
        value: "isHidden",
        disabled: false,
      },
      {
        text: "Check if video has started",
        value: "isVideoPlayed",
        disabled: false,
      },
    ]
  },
} as SelectItem
export const keyboardKeys = [
  { text: "Enter", value: "Enter" },
  { text: "Shift", value: "Shift" },
  { text: "Tab", value: "Tab" },
  { text: "Backspace", value: "Backspace" },
  { text: "Alt", value: "Alt" },
  { text: "Print", value: "Print" },
  // arrows
  { text: "Arrow Left", value: "ArrowLeft" },
  { text: "Arrow Right", value: "ArrowRight" },
  { text: "Arrow Up", value: "ArrowUp" },
  { text: "Arrow Down", value: "ArrowDown" },
  { text: "Shift Left", value: "ShiftLeft" },
  { text: "End", value: "End" },
  { text: "Home", value: "Home" },
  { text: "Shift Right", value: "ShiftRight" },
  { text: "Control Left", value: "ControlLeft" },
  { text: "Control Right", value: "ControlRight" },
  { text: "Alt Left", value: "AltLeft" },
  { text: "Alt Right", value: "AltRight" },
  { text: "Pause", value: "Pause" },
  { text: "CapsLock", value: "CapsLock" },
  { text: "Escape", value: "Escape" },
  { text: "Page Up", value: "PageUp" },
  { text: "Page Down", value: "PageDown" },
  { text: "Insert", value: "Insert" },
  { text: "Delete", value: "Delete" },
  { text: "F1", value: "F1" },
  { text: "F2", value: "F2" },
  { text: "F3", value: "F3" },
  { text: "F4", value: "F4" },
  { text: "F5", value: "F5" },
  { text: "F6", value: "F6" },
  { text: "F7", value: "F7" },
  { text: "F8", value: "F8" },
  { text: "F9", value: "F9" },
  { text: "F10", value: "F10" },
  { text: "F11", value: "F11" },
  { text: "F12", value: "F12" },
  { text: "F13", value: "F13" },
  { text: "F14", value: "F14" },
  { text: "F15", value: "F15" },
  { text: "F16", value: "F16" },
  { text: "F17", value: "F17" },
  { text: "F18", value: "F18" },
  { text: "F19", value: "F19" },
  { text: "F20", value: "F20" },
  { text: "F21", value: "F21" },
  { text: "F22", value: "F22" },
  { text: "F23", value: "F23" },
  { text: "F24", value: "F24" },
  { text: "NumLock", value: "NumLock" },
  { text: "ScrollLock", value: "ScrollLock" },
  { text: "Audio Volume Mute", value: "AudioVolumeMute" },
  { text: "Audio Volume Down", value: "AudioVolumeDown" },
  { text: "Audio Volume Up", value: "AudioVolumeUp" },
  { text: "Media Track Next", value: "MediaTrackNext" },
  { text: "Media Track Previous", value: "MediaTrackPrevious" },
  { text: "Media Stop", value: "MediaStop" },
  { text: "Media Play Pause", value: "MediaPlayPause" },
]
export const numberSigns = [
  { text: "Equal to (=)", value: "=" },
  { text: "Greater Than (>)", value: ">" },
  { text: "Less Than (<)", value: "<" },
  { text: "Greater Than or Equal to (>=)", value: ">=" },
  { text: "Less Than or Equal to (<=)", value: "<=" },
]
export const payloadTypes = [
  { text: "Json", value: "json" },
  { text: "Form", value: "form" },
  // { text: "Raw", value: "raw" },
]
export const fields = {
  selector: [
    "click",
    "focus",
    "hover",
    // "fill",
    "typeText",
    "check",
    "uncheck",
    "tap",
    "isEnabled",
    "isDisabled",
    "isHidden",
    "isVisible",
    "isChecked",
    "getContent",
    "getContentText",
    "textContains",
    "compareText",
    "TextEmpty",
    'countElements',
    "compareLink",
    "compareNumber",
    "dragDrop",
    "dblClick",
    "selectOption",
    "playVideo",
    "isVideoPlayed"
  ],
  posX: ["scroll"],
  posY: ["scroll"],
  target: ["dragDrop"],
  text: [
    "typeText",
    "textContains",
    "compareText",
    "compareLink",
    "selectOption",
  ],
  selectOption: ["selectOption"],
  number: ["compareNumber", 'countElements'],
  textarea: ["json", "raw", "structure"],
  form: ["fill", "form"],
  url: ["url", "get", "post", "put", "patch", "delete"],
  payload: ["post", "put", "patch"],
  env: [
    "get",
    "post",
    "put",
    "patch",
    "getContent",
    "getContentText",
    "pdf",
    "screenshot",
  ],
  keyboard: ["keyPress", "keyDown"],
  fullPage: ["screenshot"],
}
// Create a query line from object
export const genQueryLine = (object: any): string => {
  let query = '?'
  for (const key in object) {
    if (object[key]) {
      query += `${key}=${object[key]}&`
    }
  }
  query = query.slice(0, -1);
  return query
}

// capitalize a string
export const capitalize = (str: string, delimiter: string = ' '): string => {
  const splitted = str.split(delimiter)
  let newStr = []
  for (const key in splitted) {
    const cap = splitted[key].charAt(0).toUpperCase() + splitted[key].slice(1);
    newStr.push(cap)
  }
  return newStr.join(delimiter)
}

export const numFormatter = (number: any, digits: number = 0): string => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find((item: { value: number, symbol: string }) => number >= item.value);
  return item ? (number / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
export const timeFormatter = (number: any, digits: number = 2): string => {
  const lookup = [
    { value: 1, symbol: " ms" },
    { value: 1000, symbol: " sec" },
    { value: 60000, symbol: " min" },
    { value: 3600000, symbol: " hr" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find((item: { value: number, symbol: string }) => number >= item.value);
  return item ? (number / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "--";
}


// sum array of numbers (reduce)
export const checkObjDiff = (o1: any, o2: any) => {
  const a = typeof o1 == 'string' ? o1 : JSON.stringify(o1)
  const b = typeof o2 == 'string' ? o2 : JSON.stringify(o2)
  return a === b
}

// sum array of numbers (reduce)
export const sumArrayOfNumbers = (array: number[]) => {
  return array.reduce((a: number, b: number) => a + b, 0)
}

// check if object is not empty
export const objNotEmpty = (obj: any = {}): boolean => {
  try {
    return Object.keys(obj).length > 0 || false;
  } catch (error) {
    return false
  }
}
// check if object is not empty
export const validJSON = (obj: string): boolean => {
  try {
    JSON.parse(obj);
  } catch (e) {
    return false;
  }
  return true;
}

export const highlightJSON = (obj: any): string => {
  let json = JSON.stringify(obj, null, 3)
  json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
  return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
    var cls = 'number';
    if (/^"/.test(match)) {
      if (/:$/.test(match)) {
        cls = 'key';
      } else {
        cls = 'string';
      }
    } else if (/true|false/.test(match)) {
      cls = 'boolean';
    } else if (/null/.test(match)) {
      cls = 'null';
    }
    return '<span class="' + cls + '">' + match + '</span>';
  });
}

export const parseEnv = (str: string, envs: ListObj[] = []) => {
  let parsed = str;
  if (!envs?.length || !parsed) {
    return parsed || '';
  }
  envs.forEach((env: ListObj) => {
    const re = new RegExp("\\${{(" + env.name + ")}}", "g");
    parsed = parsed.replace(re, String(env.val));
  });
  return parsed;
}

// Used regex expressions
const emailReg = new RegExp(
  "^[a-z0-9][a-z0-9-_.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9]).[a-z0-9]{2,10}(?:.[a-z]{2,10})?$"
);
const hostReg = new RegExp(
  "^https?:\/\/\w+(\.\w+)*(:[0-9]+)?(\/.*)?$"
);
const passwordReg = {
  strong: new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  ),
  medium: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"),
  easy: new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})"),
};
const ipReg = new RegExp("^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$");
const domainReg = new RegExp("^(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,6}$");
const urlReg = new RegExp("((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)");
export const regexes = { email: emailReg, host: { ip: ipReg, domain: domainReg, url: urlReg }, password: passwordReg }

// validations object
export const validations = {
  empty: (val: string, msg: string = "Field is required") => (val && val.length) ? true : msg,
  email: (val: string, msg: string = "Email is not valid") => emailReg.test(val) ? true : msg,
  url: (val: string, msg: string = "URL is not valid") => urlReg.test(val) ? true : msg,
  host: (val: string, msg: string = "Source is not valid") => hostReg.test(val) ? true : msg,
  domain: (val: string, msg: string = "Source is not valid") => val === 'localhost' || domainReg.test(val) ? true : msg,
  ip: (val: string, msg: string = "Source is not valid") => ipReg.test(val) ? true : msg,
  password: {
    len: (val: string, len: number = 8, msg: string = "Password too short") => (val?.length >= len) ? true : msg,
    strength: (val: string, msg: string = "Password too weak") =>
      passwordReg.easy.test(val) ? true : msg
  },
}
export const permissions = [
  "geolocation",
  "midi",
  "midi-sysex",
  "notifications",
  // 'push',
  "camera",
  "microphone",
  "background-sync",
  "ambient-light-sensor",
  "accelerometer",
  "gyroscope",
  "magnetometer",
  "accessibility-events",
  "clipboard-read",
  "clipboard-write",
  "payment-handler",
]

export const locales = [
  'af-ZA',
  'am-ET',
  'ar-AE',
  'ar-BH',
  'ar-DZ',
  'ar-EG',
  'ar-IQ',
  'ar-JO',
  'ar-KW',
  'ar-LB',
  'ar-LY',
  'ar-MA',
  'arn-CL',
  'ar-OM',
  'ar-QA',
  'ar-SA',
  'ar-SD',
  'ar-SY',
  'ar-TN',
  'ar-YE',
  'as-IN',
  'az-az',
  'az-Cyrl-AZ',
  'az-Latn-AZ',
  'ba-RU',
  'be-BY',
  'bg-BG',
  'bn-BD',
  'bn-IN',
  'bo-CN',
  'br-FR',
  'bs-Cyrl-BA',
  'bs-Latn-BA',
  'ca-ES',
  'co-FR',
  'cs-CZ',
  'cy-GB',
  'da-DK',
  'de-AT',
  'de-CH',
  'de-DE',
  'de-LI',
  'de-LU',
  'dsb-DE',
  'dv-MV',
  'el-CY',
  'el-GR',
  'en-029',
  'en-AU',
  'en-BZ',
  'en-CA',
  'en-cb',
  'en-GB',
  'en-IE',
  'en-IN',
  'en-JM',
  'en-MT',
  'en-MY',
  'en-NZ',
  'en-PH',
  'en-SG',
  'en-TT',
  'en-US',
  'en-ZA',
  'en-ZW',
  'es-AR',
  'es-BO',
  'es-CL',
  'es-CO',
  'es-CR',
  'es-DO',
  'es-EC',
  'es-ES',
  'es-GT',
  'es-HN',
  'es-MX',
  'es-NI',
  'es-PA',
  'es-PE',
  'es-PR',
  'es-PY',
  'es-SV',
  'es-US',
  'es-UY',
  'es-VE',
  'et-EE',
  'eu-ES',
  'fa-IR',
  'fi-FI',
  'fil-PH',
  'fo-FO',
  'fr-BE',
  'fr-CA',
  'fr-CH',
  'fr-FR',
  'fr-LU',
  'fr-MC',
  'fy-NL',
  'ga-IE',
  'gd-GB',
  'gd-ie',
  'gl-ES',
  'gsw-FR',
  'gu-IN',
  'ha-Latn-NG',
  'he-IL',
  'hi-IN',
  'hr-BA',
  'hr-HR',
  'hsb-DE',
  'hu-HU',
  'hy-AM',
  'id-ID',
  'ig-NG',
  'ii-CN',
  'in-ID',
  'is-IS',
  'it-CH',
  'it-IT',
  'iu-Cans-CA',
  'iu-Latn-CA',
  'iw-IL',
  'ja-JP',
  'ka-GE',
  'kk-KZ',
  'kl-GL',
  'km-KH',
  'kn-IN',
  'kok-IN',
  'ko-KR',
  'ky-KG',
  'lb-LU',
  'lo-LA',
  'lt-LT',
  'lv-LV',
  'mi-NZ',
  'mk-MK',
  'ml-IN',
  'mn-MN',
  'mn-Mong-CN',
  'moh-CA',
  'mr-IN',
  'ms-BN',
  'ms-MY',
  'mt-MT',
  'nb-NO',
  'ne-NP',
  'nl-BE',
  'nl-NL',
  'nn-NO',
  'no-no',
  'nso-ZA',
  'oc-FR',
  'or-IN',
  'pa-IN',
  'pl-PL',
  'prs-AF',
  'ps-AF',
  'pt-BR',
  'pt-PT',
  'qut-GT',
  'quz-BO',
  'quz-EC',
  'quz-PE',
  'rm-CH',
  'ro-mo',
  'ro-RO',
  'ru-mo',
  'ru-RU',
  'rw-RW',
  'sah-RU',
  'sa-IN',
  'se-FI',
  'se-NO',
  'se-SE',
  'si-LK',
  'sk-SK',
  'sl-SI',
  'sma-NO',
  'sma-SE',
  'smj-NO',
  'smj-SE',
  'smn-FI',
  'sms-FI',
  'sq-AL',
  'sr-BA',
  'sr-CS',
  'sr-Cyrl-BA',
  'sr-Cyrl-CS',
  'sr-Cyrl-ME',
  'sr-Cyrl-RS',
  'sr-Latn-BA',
  'sr-Latn-CS',
  'sr-Latn-ME',
  'sr-Latn-RS',
  'sr-ME',
  'sr-RS',
  'sr-sp',
  'sv-FI',
  'sv-SE',
  'sw-KE',
  'syr-SY',
  'ta-IN',
  'te-IN',
  'tg-Cyrl-TJ',
  'th-TH',
  'tk-TM',
  'tlh-QS',
  'tn-ZA',
  'tr-TR',
  'tt-RU',
  'tzm-Latn-DZ',
  'ug-CN',
  'uk-UA',
  'ur-PK',
  'uz-Cyrl-UZ',
  'uz-Latn-UZ',
  'uz-uz',
  'vi-VN',
  'wo-SN',
  'xh-ZA',
  'yo-NG',
  'zh-CN',
  'zh-HK',
  'zh-MO',
  'zh-SG',
  'zh-TW',
  'zu-ZA']

export const headers = ["A-IM", "Accept", "Accept-Charset", "Accept-Encoding", "Accept-Language", "Accept-Datetime", "Access-Control-Request-Method", "Access-Control-Request-Headers", "Authorization", "Cache-Control", "Connection", "Content-Length", "Content-Type", "Cookie", "Date", "Expect", "Forwarded", "From", "Host", "If-Match", "If-Modified-Since", "If-None-Match", "If-Range", "If-Unmodified-Since", "Max-Forwards", "Origin", "Pragma", "Proxy-Authorization", "Range", "Referer", "TE", "User-Agent", "Upgrade", "Via", "Warning", "Dnt", "X-Requested-With", "X-CSRF-Token"]
